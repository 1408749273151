<template>
    <div class="relative flex flex-col h-full w-full px-4 overflow-y-auto">
        <LbrxLoaderBlur v-if="showLoader" />
        <div class="mb-4">
            <div class="flex gap-3">
                <!-- <span>{{ $t('Pos.Display') }} :</span>
                    <a-select ref="select" v-model:value="displayTable" style="width: 250px" @focus="focus"
                        @change="handleChange">
                        <a-select-option value="AllStock">{{ $t('Pos.Stock') }}</a-select-option>
                        <a-select-option value="Endofstock">{{ $t('Pos.Endofstockproducts') }}</a-select-option>
                    </a-select> -->
                <LbrxFilterBar :filters="filtersStock" :selectedFilter="displayTable" @selectFilter="handleChange"
                    :fitWidth="true" class="h-fit" :title="$t('Pos.Display')" />


                <div v-if="displayTable.id == 2 || displayTable.id == 1"
                    class="bg-[#fff] relative flex items-center flex-1 my-4 rounded-lg px-2 gap-2 border-2 border-b-gray-300">
                    <div class="flex px-2 py-3 w-full">
                        <div class="max-w-fit my-auto">
                            <iconSearchV2 :size="20" :color="'#000'" />
                        </div>
                        <input id="searchBarStock" @click="selectInput" @input="searchByInput" type="text"
                            class="mx-2 focus:outline-none text-md w-full" :placeholder="$t('Pos.SearchProducts')">
                        <iconCloseFill v-show="showClose" class="opacity-90 absolute right-0 mr-2" :size="24"
                            :color="'#555555'" @click="deleteFilter" />
                    </div>
                </div>

                <div v-else class="flex gap-3">
                    <LbrxFilterBarDate id="filter1" @selectFilterdate="selectFilterdate" :fitWidth="false" :type="'date'" 
                    :placeholderText="$t('Pos.CreationDate')" />
                    <LbrxFilterBar id="filterType" :filters="filtersType" :selectedFilter="selectedType" @selectFilter="handleChangeType"
                    :fitWidth="true" class="h-fit" :title="$t('Pos.Display')" />
                </div>

                <div>

                </div>

                <div>
                    <button
                        class="flex py-4 rounded-md items-center bg-primary min-w-48 my-4 justify-center border-2 text-sm  text-white whitespace-nowrap"
                        @click="showAddProductPopup()">
                        <span class="text-lg">{{ $t('Pos.AddProduct') }}</span>
                    </button>
                </div>

            </div>

            <a-row class="charts-container" :gutter="24">

                <a-col v-if="displayTable.id == 2" :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }"
                    :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
                    <a-card hoverable>

                        <a-row class="tbl-title" :gutter="16">

                            <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 16 }" :lg="{ span: 16 }"
                                :xl="{ span: 16 }" :xxl="{ span: 16 }" class="tbl-right">

                                <ExclamationCircleOutlined
                                    :class="[locale === 'ar' ? 'warning-icon-rtl' : 'warning-icon']" />
                                <span class="text-xl font-semibold text-red-700">{{ $t('Pos.Endofstockproducts')
                                    }}</span>

                            </a-col>


                            <!-- <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }">
                        <a-select v-model:value="warehouse_id" show-search :option-filter-prop="'label'" class="select-input" @change="handleWarehouseChange">
                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

<a-select-option v-for="item in state.warehouses" :label="item.name" :key="item.id" :value="item.id"
    :selected="item.id === warehouse_id ">{{ item.name }}</a-select-option>
</a-select>
</a-col> -->

                        </a-row>

                        <a-table :columns="columnsEndOfStock" :data-source="endOfStock" class="table-container">
                            <template #emptyText>
                                <a-empty :description="$t('Pos.NoData')" :image="simpleImage" />
                            </template>
                            <template #bodyCell="{ column, record, text }">
                                <div :class="record.total == 0 ? 'warning-txt' : ''">
                                    <template v-if="column.dataIndex === 'thumbnail'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'name'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'total'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.key === 'operation'">

                                        <button @click="openSendRequest(record)"
                                            class="bg-primary py-2 px-4 text-white text-nowrap rounded-md">{{
                                                $t('Pos.SendRequest') }}</button>
                                    </template>
                                </div>
                            </template>
                            <!-- <template
                                #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                                <div style="color: aqua;">
                                    <a-input ref="searchInput" :placeholder="'title'" :value="selectedKeys[0]"
                                        style="width: 188px; margin-bottom: 8px; display: block"
                                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

                                    <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                                        <template #icon>
                                            <SearchOutlined />
                                        </template>
                                        {{ $t('Pos.Search') }}
                                    </a-button>
                                    <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                                        {{ $t('Pos.Reset') }}
                                    </a-button>
                                </div>
                            </template> -->

                            <template #customFilterIcon="{ filtered }">
                                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                            </template>
                        </a-table>
                    </a-card>
                </a-col>

                <a-col v-else-if="displayTable.id == 1" :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }"
                    :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
                    <a-card hoverable>

                        <a-row class="tbl-title" :gutter="16">

                            <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 16 }" :lg="{ span: 16 }"
                                :xl="{ span: 16 }" :xxl="{ span: 16 }" class="tbl-right">

                                <ExclamationCircleOutlined
                                    :class="[locale === 'ar' ? 'warning-icon-rtl' : 'warning-icon']" />
                                <span class="text-xl font-semibold">{{ $t('Pos.Stock')
                                    }}</span>

                            </a-col>

                        </a-row>

                        <a-table :columns="columnsStock" :data-source="allStock" class="table-container">
                            <template #emptyText>
                                <a-empty :description="$t('Pos.NoData')" :image="simpleImage" />
                            </template>
                            <template #bodyCell="{ column, record, text }">
                                <div>
                                    <template v-if="column.dataIndex === 'ean'">

                                        <span>{{ record.stockable.code_ean ? record.stockable.code_ean : "--" }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'name'">

                                        <span>{{ record.stockable.name }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'quantity'">

                                        <span>{{ text }}</span>
                                    </template>
                                    <template v-if="column.key === 'operation'">
                                        <div class="flex gap-3">
                                            <button @click="showUpdateProductPopup(record)"
                                                class="bg-[#dd953e] py-2 px-2 text-white text-nowrap rounded-md">
                                                <EditOutlined :style="{ fontSize: '18px' }" />
                                            </button>
                                            <button @click="showQuantityPopup(record)"
                                                class="bg-primary py-2 px-2 text-white text-nowrap rounded-md">
                                                <iconPlus :size="20" :color="'#fff'" />
                                            </button>
                                        </div>
                                    </template>
                                </div>
                            </template>

                            <template #customFilterIcon="{ filtered }">
                                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                            </template>
                        </a-table>
                    </a-card>
                </a-col>

                <!-- requests -->
                <a-col v-else :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 24 }"
                    :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
                    <a-card hoverable>

                        <a-row class="tbl-title" :gutter="16">

                            <a-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 16 }" :lg="{ span: 16 }"
                                :xl="{ span: 16 }" :xxl="{ span: 16 }" class="tbl-right">

                                <ExclamationCircleOutlined
                                    :class="[locale === 'ar' ? 'warning-icon-rtl' : 'warning-icon']" />
                                <span class="text-xl font-semibold">{{ $t('Pos.InventoryTransactions')
                                    }}</span>

                            </a-col>

                        </a-row>

                        <a-table :columns="columnsTransactions" :data-source="transactions" class="table-container"
                            :pagination="false">
                            <template #emptyText>
                                <a-empty :description="$t('Pos.NoData')" :image="simpleImage" />
                            </template>
                            <template #bodyCell="{ column, record }">
                                <div>
                                    <template v-if="column.dataIndex === 'article'">

                                        <span>{{ record.product ? record.product.name : record.ingredient.name }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'type'">
                                        <span>{{ record.type == "sale" ? $t('Pos.Sale') : record.type == "received" ?
                                            $t('Pos.Received') : record.type == "transfered" ? $t('Pos.Transfered') :
                                                $t('Pos.Adjustment') }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'quantity'">
                                        <span :class="record.quantity < 0 ? 'text-red-600' : 'text-[#09aa29]'">{{
                                            record.quantity }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'typeElement'">
                                        <span>{{ record.product ? $t('Pos.Product') : $t('Pos.Ingredient') }}</span>
                                    </template>
                                    <template v-if="column.dataIndex === 'date'">
                                        <span>{{ record.transaction_date }}</span>
                                    </template>
                                    <template v-if="column.key === 'operation'">

                                        <button @click="openDetailPopup(record)"
                                            class="bg-primary py-2 px-2 text-white text-nowrap rounded-md">
                                            <iconEye :size="20" :color="'#fff'" class="w-5 h-5" />
                                        </button>
                                    </template>
                                </div>
                            </template>

                            <template #customFilterIcon="{ filtered }">
                                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                            </template>
                        </a-table>
                        <div class="flex justify-end mt-4">
                            <a-pagination v-model:current="currentPage" :total="totalItems" @change="handlePageChange"
                                :default-page-size="perPage" :showSizeChanger=false />
                        </div>
                    </a-card>
                </a-col>

            </a-row>
        </div>
        <LbrxInfoPopup :active="modalInfo.active" @closePopup="closePopup" :type="modalInfo.type"
            :message="modalInfo.message" />
        <LbrxDetailPopup :active="modalDetail.active" @closePopup="closePopup" :detail="selectedTransaction" />
        <LbrxRequestPopup :active="modalRequest.active" @closePopup="closePopup" :productName="selectedProduct.name" />
        <LbrxQuantityPopup :active="modalQuantity.active" @closePopup="closePopup" @confirmQty="confirmQty"
            :selectedItem="selectedItem.stockable" />
        <LbrxKeyboardPopup :active="modalKeyboard.active" @closePopupKeyboard="closePopupKeyboard" />
        <LbrxProductPopup ref="productPopup" :active="modalProduct.active" :type="modalProduct.type"
            @closePopup="closePopup" @confirmQty="confirmQty" :posConfig="posConfig"
            @toggleLoaderAnimation="toggleLoaderAnimation" :categories="categories" :manufacturers="manufacturers"
            :suppliers="suppliers" :vatrates="vatrates" />
    </div>
</template>

<script>
import moment from "moment/moment";
import dayjs, { Dayjs } from 'dayjs';
import LbrxFilterBar from "@/components/LbrxFilterBar.vue"
import LbrxInfoPopup from '@/components/LbrxInfoPopup.vue';
import LbrxRequestPopup from '@/components/LbrxRequestPopup.vue';
import { defineComponent, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { statisticService, stockItemsService, categoryService, manufacturerService, supplierService, vatrateService } from '@/_services';
import { currency } from '@/_helpers';
import webSocketService from '@/_services/webSocketService';
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue";
import iconPlus from "@/components/icons/iconPlus.vue";
import iconEye from "@/components/icons/iconEye.vue";
import LbrxQuantityPopup from "@/components/LbrxQuantityPopup.vue"
import LbrxDetailPopup from "@/components/LbrxDetailPopup.vue"
import LbrxProductPopup from "@/components/LbrxProductPopup.vue"
import iconSearchV2 from "@/components/icons/iconSearchV2.vue"
import iconCloseFill from "@/components/icons/iconCloseFill.vue"
import LbrxKeyboardPopup from "@/components/LbrxKeyboardPopup.vue";
import { EditOutlined, DollarCircleOutlined } from '@ant-design/icons-vue';
import LbrxFilterBarDate from "@/components/LbrxFilterBarDate.vue"

export default defineComponent({
    name: "stockIndex",
    components: {
        LbrxLoaderBlur,
        LbrxInfoPopup,
        LbrxRequestPopup,
        LbrxFilterBar,
        iconPlus,
        LbrxQuantityPopup,
        LbrxDetailPopup,
        iconEye,
        iconSearchV2,
        LbrxKeyboardPopup,
        iconCloseFill,
        EditOutlined,
        LbrxProductPopup,
        LbrxFilterBarDate
    },
    data() {
        return {
            data: {
                labels: ['January', 'February'],
                datasets: [
                    {
                        label: this.$t('Pos.ProfitMade'),
                        backgroundColor: '#f87979',
                        data: ["34", "78"]
                    }
                ],
                options: {
                    responsive: true,
                    maintainAspectRatio: false
                }
            },
            modalQuantity: {
                active: false
            },
            modalDetail: {
                active: false
            },
            modalProduct: {
                active: false,
                type: "false"
            },
            selectedFilter: {},
            filtersData: [{ name: this.$t('Pos.Cashier'), id: 1 }, { name: this.$t('Pos.User'), id: 2 }, { name: this.$t('Pos.All'), id: 3 }],
            filtersStock: [{ name: this.$t('Pos.Stock'), id: 1 }, { name: this.$t('Pos.Endofstock'), id: 2 }, { name: this.$t('Pos.Transactions'), id: 3 }],
            filtersType: [{ name: this.$t('Pos.All'), id: 1, type: "" }, { name: this.$t('Pos.Received'), id: 2, type: "received" }, { name: this.$t('Pos.Sale'), id: 3, type: "sale" }],
            selectedFilterPeriod: {},
            selectedFilterData: {},
            selectedFilterInterval: [],
            selectedFilterDate: moment().format('YYYY-MM-DD'),
            modalReserve: {
                active: false
            },
            modalInfo: {
                active: false,
                type: "",
                message: ""
            },
            modalRequest: {
                active: false
            },
            columnsEndOfStock: [
                {
                    title: this.$t('Pos.Photo'),
                    dataIndex: 'thumbnail'
                },
                {
                    title: this.$t('Pos.Product'),
                    dataIndex: 'name'
                },
                {
                    title: this.$t('Pos.Quantity'),
                    dataIndex: 'total',
                },
                {
                    title: this.$t('Pos.Action'),
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                },
            ],
            columnsStock: [
                {
                    title: this.$t('Pos.CodeEAN'),
                    dataIndex: 'ean'
                },
                {
                    title: this.$t('Pos.Product'),
                    dataIndex: 'name',
                },
                {
                    title: this.$t('Pos.Quantity'),
                    dataIndex: 'quantity',
                },
                {
                    title: this.$t('Pos.Action'),
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                },
            ],
            columnsTransactions: [
                {
                    title: this.$t('Pos.Article'),
                    dataIndex: 'article'
                },
                {
                    title: this.$t('Pos.Type'),
                    dataIndex: 'type',
                },
                {
                    title: this.$t('Pos.Quantity'),
                    dataIndex: 'quantity',
                },
                {
                    title: this.$t('Pos.TypeElement'),
                    dataIndex: 'typeElement',
                },
                {
                    title: this.$t('Pos.Date'),
                    dataIndex: 'date',
                },
                {
                    title: this.$t('Pos.Action'),
                    key: 'operation',
                    fixed: 'right',
                    width: 100,
                },
            ],
            tableLocations: [],
            statisticsSales: {},
            statisticsProducts: {},
            statisticsCashier: {},
            show: false,
            socket: null,
            showLoader: false,
            pos: {},
            posConfig: {},
            currency: {},
            currencyCode: {},
            drawerInfo: {},
            drawerOpened: false,
            loadingPrinting: false,
            NoOpenDrawer: false,
            isPortrait: window.matchMedia("(orientation: portrait)").matches,
            endOfStock: [],
            endOfStockNotFiltered: [],
            allStock: [],
            allStockNotFiltered: [],
            stockout: [],
            transactions: [],
            selectedProduct: {},
            selectedTransaction: {},
            displayTable: { name: this.$t('Pos.Stock'), id: 1 },
            selectedItem: {},
            selectedQty: null,
            currentPage: 1,
            totalItems: 1,
            perPage: 20,
            modalKeyboard: {
                active: false
            },
            timer: null,
            showClose: false,
            categories: [],
            manufacturers: [],
            suppliers: [],
            vatrates: [],
            param: "",
            keys: [],
            values: [],
            selectedDate: "",
            selectedType: { name: this.$t('Pos.All'), id: 1 }
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => { return el.is_default == 1 });
            if (this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
        this.getAllProducts();
    },
    mounted() {
        this.socket = webSocketService.getSocket();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    computed: {
        checkEfficiency() {
            return (this.statisticsSales.currentMonthEfficiency - this.statisticsSales.previousMonthEfficiency) < 0 ? false : true;
        },
        decimalPrecision() {
            return this.posConfig.posConfig.decimalPrecision ? this.posConfig.posConfig.decimalPrecision : 3;
        }
    },
    methods: {
        toggleLoaderAnimation() {
            this.showLoader = !this.showLoader;
        },
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        formatAmount(amount, currencyCode, decimalPrecision) {
            return currency.formatAmount(amount, currencyCode, decimalPrecision);
        },
        openPopupInfo(message) {
            this.modalInfo.active = true;
            this.modalInfo.type = "message";
            this.modalInfo.message = message;
        },
        openPopupRequest() {
            this.modalRequest.active = true;
        },
        getAllProducts() {
            this.showLoader = true;
            statisticService.getAllProducts("?warehouse_id=" + this.posConfig.warehouse_id).then((res) => {
                this.statisticsProducts = res;
                this.endOfStock = res.products_out_of_stock;
                this.endOfStockNotFiltered = res.products_out_of_stock;
                this.allStock = res.stock;
                this.allStockNotFiltered = res.stock;
                this.stockout = this.endOfStock.filter((el) => el.total == 0);
                // if (this.stockout.length != 0) {
                //     this.openPopupInfo(this.$t('Pos.ThereIs', { num: this.stockout.length }) + " " + this.$t('Pos.Endofstockproducts'));
                // }
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        getAllTransactions(currentPage, perPage) {
            let arrayValues = []
            if(this.selectedDate) {
                this.keys.push("created_at")
                arrayValues.push(this.selectedDate)
            }
            if(this.selectedType.id != 1) {
                this.keys.push("type")
                arrayValues.push(this.selectedType.type)
            }
                
            if(this.keys.length != 0) {
                this.param = `&keys=${this.keys.join()}&values=${arrayValues.join()}`
                this.keys = []
            } else {
                this.param = ""
            }

            this.showLoader = true;
            stockItemsService.getAllTransactions(`?per_page=${perPage}&page=${currentPage}`+this.param).then((res) => {
                this.transactions = res.data;
                this.totalItems = res.meta.total;
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        closePopup() {
            this.modalInfo.active = false;
            this.modalRequest.active = false;
            this.modalQuantity.active = false;
            this.modalDetail.active = false;
            this.modalProduct.active = false;
        },
        openSendRequest(product) {
            this.selectedProduct = product;
            this.openPopupRequest();
        },
        openDetailPopup(transaction) {
            this.selectedTransaction = transaction;
            this.modalDetail.active = true;
        },
        handleChange(value) {
            this.displayTable = value;
            if (this.displayTable.id == 3) {
                this.getAllTransactions(this.currentPage, this.perPage);
            }
        },
        handleChangeType(value) {
            this.selectedType = value;
            this.getAllTransactions(this.currentPage, this.perPage);
        },
        showQuantityPopup(item) {
            this.selectedItem = item;
            this.modalQuantity.active = true;
        },
        confirmQty(qty) {
            this.selectedQty = parseFloat(qty);
            this.updateStockQty();
            this.closePopup();
        },
        updateStockQty() {
            this.showLoader = true;
            let payload = {
                product_variant_id: this.selectedItem.product_variant_id ? this.selectedItem.product_variant_id : "",
                quantity: this.selectedQty,
                stockable_id: this.selectedItem.stockable_id,
                stockable_type: "product",
                unit: this.selectedItem.stockable.unit,
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                warehouse_id: this.selectedItem.warehouse_id
            }
            stockItemsService.create(payload).then((res) => {
                this.getAllProducts();
                this.showNotification(this.$t('Pos.StockUpdateSuccess'));
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.showLoader = false;
            });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.getAllTransactions(page, this.perPage)
        },
        selectInput() {
            if (this.posConfig.posConfig.showKeyboard == 1) {
                this.modalKeyboard.active = true;
            }
        },
        closePopupKeyboard(text) {
            const inputValue = document.getElementById("searchBarStock");
            inputValue.value = text;
            if (this.displayTable.id == 1)
                this.allStock = this.filterByNameOrEAN(inputValue.value);
            else if (this.displayTable.id == 2)
                this.endOfStock = this.filterByName(inputValue.value);
            this.modalKeyboard.active = false;
        },
        searchByInput(event) {
            clearTimeout(this.timer); // Clear previous timer
            this.timer = setTimeout(() => {
                const inputValue = event.target.value;
                if (this.displayTable.id == 1)
                    this.allStock = this.filterByNameOrEAN(inputValue);
                else if (this.displayTable.id == 2)
                    this.endOfStock = this.filterByName(inputValue);
            }, 500);
        },
        filterByNameOrEAN(text) {
            this.showClose = true;
            return this.allStockNotFiltered.filter(obj => obj.stockable.name.toLowerCase().includes(text.toLowerCase()) || (obj.stockable.code_ean?.toString() ?? "").includes(text));
        },
        filterByName(text) {
            this.showClose = true;
            return this.endOfStockNotFiltered.filter(obj => obj.name.toLowerCase().includes(text.toLowerCase()));
        },
        deleteFilter() {
            document.getElementById("searchBarStock").value = "";
            this.showClose = false;
            this.allStock = this.allStockNotFiltered;
        },
        showUpdateProductPopup(item) {
            this.$refs.productPopup.getSelectedProduct(item.stockable);
            this.modalProduct.active = true;
            this.modalProduct.type = "update";
        },
        showAddProductPopup() {
            this.modalProduct.type = "add";
            this.getDataForProduct();
        },
        getDataForProduct() {
            this.showLoader = true;
            categoryService.getAll("?pagination=off").then((response) => {
                console.log("getting products", response.data);
                this.categories = response.data;
                manufacturerService.getAll("?pagination=off").then((response) => {
                    console.log("getting products", response.data);
                    this.manufacturers = response.data;
                    supplierService.getAll("?pagination=off").then((response) => {
                        console.log("getting products", response.data);
                        this.suppliers = response.data;
                        vatrateService.getAll("?pagination=off").then((response) => {
                            console.log("getting products", response.data);
                            this.vatrates = response.data;
                            this.showLoader = false;
                            this.modalProduct.active = true;
                        }).catch((error) => {
                            console.log("error api : get products", error);
                            this.showLoader = false;
                        });
                    }).catch((error) => {
                        console.log("error api : get products", error);
                        this.showLoader = false;
                    });
                }).catch((error) => {
                    console.log("error api : get products", error);
                    this.showLoader = false;
                });
            }).catch((error) => {
                console.log("error api : get products", error);
                this.showLoader = false;
            });
        },
        selectFilterdate(date) {
            if(date) {
                console.log('dddddddddddddd')
                this.selectedDate = dayjs(date).format("YYYY-MM-DD")
            } else {
                console.log('bbbbb')
                this.selectedDate = null;
            }

            this.getAllTransactions(this.currentPage, this.perPage);
                
        }
    }
});
</script>

<style scoped>
.tbl-title {
    margin-bottom: 15px;
}

.warning-icon {
    color: #ea5455;
    font-size: 24px !important;
    padding-right: 10px;
}

.warning-icon-rtl {
    color: #ea5455;
    font-size: 24px !important;
    padding-left: 10px;
}

.warning-txt {
    color: #ea5455;
}

.tbl-right {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
}
</style>