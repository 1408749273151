<template>
  <div class="relative flex flex-col h-full w-full px-4 overflow-y-auto">
    <LbrxLoaderBlur v-if="showLoader" />
    <LbrxLoaderSpinner :isLoaded="loading" />

    <!--search card-->

    <div
      :class="
        isPortrait ? 'grid-cols-2 grid-rows-2' : 'flex justify-between gap-3'
      "
    >
      <div class="flex gap-2">
        <div class="">
          <!-- <LbrxFilterBar id="filter1" :filters="filterPeriod" :selectedFilter="selectedFilterPeriod"
                    @selectFilter="selectFilter" :fitWidth="false" /> -->
          <LbrxFilterBar
            :filters="filtersData"
            :selectedFilter="selectedFilterData"
            @selectFilter="selectFilterData"
            :fitWidth="true"
            :margin="'15px 0 15px auto'"
            class="mt-2"
            :title="$t('Pos.FilterBy')"
          />
        </div>
        <div>
          <!-- <LbrxFilterBarDate id="filter1" @selectFilterInterval="selectFilterInterval" :fitWidth="false" /> -->
          <LbrxFilterBarDate
            id="filter2"
            @selectFilterdate="selectFilterdate"
            @selectFilterInterval="selectFilterInterval"
            :fitWidth="true"
            :type="'range'"
          />
        </div>
      </div>
      <div>
        <div
          class="flex gap-3"
          :class="isPortrait ? 'col-span-2 row-start-2 mb-[18px]' : 'my-[18px]'"
        >
          <button
            class="flex px-2 py-3 w-full rounded-md bg-[#09aa29] items-center justify-center text-sm text-white whitespace-nowrap"
            @click="refreshByDate()"
          >
            <span class="text-lg">{{ $t("Pos.RefreshDate") }}</span>
          </button>
          <button
            class="flex py-4 rounded-md items-center min-w-48 justify-center text-sm text-white whitespace-nowrap"
            :class="!loadingPrinting ? 'bg-primary' : 'bg-[#bebebe]'"
            @click="selectFilterdate()"
          >
            <span v-if="!loadingPrinting" class="text-lg">{{
              $t("Pos.PrintReport")
            }}</span>
            <LoadingOutlined v-else :style="{ fontSize: '28px' }" />
          </button>
        </div>
      </div>
    </div>

    <!-- filter -->
    <div
      class="bg-[#fff] relative flex items-center my-4 rounded-lg px-2 py-3 gap-2 min-h-16 border-2 text-sm font-semibold border-b-gray-300 overflow-x-auto overflow-y-hidden"
    >
      <div :class="getDivStyle">
        <div
          @click="page = 1"
          :class="
            page == '1'
              ? 'bg-primary-light outline outline-primary outline-2 text-primary'
              : 'flex flex-row items-center text-nowrap text-sm font-semibold'
          "
          class="flex flex-row items-center text-nowrap text-sm font-semibold hover:bg-primary-light hover:outline outline-primary outline-2 hover:text-primary py-2 rounded-md cursor-pointer"
        >
          <span class="mx-3">{{ $t("Pos.salesReport") }}</span>
        </div>

        <div
          @click="page = 2"
          :class="
            page == '2'
              ? 'bg-primary-light outline outline-primary outline-2 text-primary'
              : 'flex flex-row items-center text-nowrap text-sm font-semibold'
          "
          class="flex flex-row items-center text-nowrap text-sm font-semibold hover:bg-primary-light hover:outline outline-primary outline-2 hover:text-primary py-2 rounded-md cursor-pointer"
        >
          <span class="mx-3">{{ $t("Pos.productsReport") }}</span>
        </div>
      </div>
    </div>

    <!-- <div class="flex justify-center mt-4 mb-8 gap-3 items-center">
      <div
        @click="page = 1"
        class="bg-primary rounded-full slider-page"
        :class="page == '1' ? 'w-4 h-4' : 'w-2 h-2'"
      >
      </div>
      <div
        @click="page = 2"
        class="bg-primary rounded-full slider-page"
        :class="page == '2' ? 'w-4 h-4' : 'w-2 h-2'"
      >
      </div>
    </div> -->

    <div
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      style="user-select: none; /* Prevent text selection */"
    >
      <div v-if="page == '1'" class="flex flex-row gap-4 w-full mb-5">
        <div :class="isPortrait ? 'flex w-full gap-4' : 'flex flex-col gap-5'">
          <div
            class="p-4 bg-white flex flex-col gap-5 text-left rounded-md"
            :class="isPortrait ? 'w-full' : 'w-[30vw]'"
          >
            <div>
              <span class="text-primary">{{ $t("Pos.TotalSales") }}</span>
              <DollarCircleOutlined class="mx-2" />
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex justify-between">
                <span class="flex-grow">{{ $t("Pos.Today") }}: </span>
                <span class="font-semibold">{{
                  formatAmount(
                    statisticsSales.today_total_sales,
                    currencyCode,
                    decimalPrecision
                  )
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="flex-grow">{{ $t("Pos.CurrentMonth") }}: </span>
                <span class="font-semibold">{{
                  formatAmount(
                    statisticsSales.current_month_sales,
                    currencyCode,
                    decimalPrecision
                  )
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="flex-grow">{{ $t("Pos.LastMonth") }}: </span>
                <span class="font-semibold">{{
                  formatAmount(
                    statisticsSales.previous_month_sales,
                    currencyCode,
                    decimalPrecision
                  )
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="p-4 bg-white flex flex-col gap-5 text-left rounded-md"
            :class="isPortrait ? 'w-full' : 'w-[30vw]'"
          >
            <div>
              <span class="text-primary">{{ $t("Pos.SalesEfficiency") }}</span>
              <RiseOutlined
                :style="{
                  fontSize: '22px',
                  color: '#09aa29',
                  margin: '0 10px',
                }"
                v-if="checkEfficiency"
              />
              <FallOutlined
                :style="{
                  fontSize: '22px',
                  color: '#E72929',
                  margin: '0 10px',
                }"
                v-else
              />
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex justify-between items-center">
                <span class="flex-grow">{{ $t("Pos.CurrentMonth") }}: </span>
                <span
                  class="font-semibold"
                  :class="checkEfficiency ? 'text-[#09aa29]' : 'text-[#E72929]'"
                >
                  <CaretUpOutlined
                    :style="{
                      fontSize: '22px',
                      color: '#09aa29',
                      marginRight: '5px',
                    }"
                    v-if="checkEfficiency"
                  />
                  <CaretDownOutlined
                    :style="{
                      fontSize: '22px',
                      color: '#E72929',
                      marginRight: '5px',
                    }"
                    v-else
                  />{{
                    statisticsSales.currentMonthEfficiency?.toFixed(
                      decimalPrecision
                    )
                  }} </span
                ><span class="ml-2 font-semibold text-confirmation">
                  <iconAnalytic :size="18" :color="'#141B34'" />
                </span>
              </div>
              <div class="flex justify-between items-center">
                <span class="flex-grow">{{ $t("Pos.LastMonth") }}: </span>
                <span class="font-semibold">{{
                  statisticsSales.previousMonthEfficiency?.toFixed(
                    decimalPrecision
                  )
                }}</span
                ><span class="ml-2 font-semibold text-confirmation">
                  <iconAnalytic :size="18" :color="'#141B34'" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isPortrait" class="w-full">
          <a-card class="w-full" v-if="show">
            <Line
              :data="data"
              style="height: 00px; width: 100%; margin: 0 auto"
              :options="options"
            />
          </a-card>
        </div>
      </div>
      <!-- <div class="my-6 grid  gap-4">
                <a-card class="w-full" v-if="show">
                    <Line :data="data" style="height: 00px; width: 100%; margin: 0 auto;" :options="options" />
                </a-card>

            </div> -->
      <div v-else>
        <div class="flex flex-row gap-3 mb-8">
          <a-card class="basis-6/12 text-left">
            <span class="text-xl font-semibold">{{
              $t("Pos.ProductsSold")
            }}</span>
            <a-table
              class="my-4"
              size="middle"
              :columns="columns"
              :data-source="statisticsCashier.products"
              :row-class-name="
                (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :pagination="false"
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'quantity'">
                  <span class="font-semibold mr-2">{{ text }}</span>
                  <span>{{ record.unit }}</span>
                </template>
                <template v-if="column.dataIndex === 'total'">
                  <span class="text-[#09aa29] font-semibold mr-2">{{
                    formatAmount(record.total, currencyCode, decimalPrecision)
                  }}</span>
                </template>
              </template>
            </a-table>
          </a-card>
          <a-card class="basis-6/12 text-left">
            <span class="text-xl font-semibold">{{
              $t("Pos.SalesCategory")
            }}</span>
            <a-table
              class="my-4"
              size="middle"
              :columns="columnsCategory"
              :data-source="statisticsCashier.categories"
              :row-class-name="
                (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :pagination="false"
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'quantity'">
                  <span class="font-semibold mr-2">{{ text }}</span>
                  <span>{{ record.unit }}</span>
                </template>
                <template v-if="column.dataIndex === 'total'">
                  <span class="text-[#09aa29] font-semibold mr-2">{{
                    formatAmount(record.total, currencyCode, decimalPrecision)
                  }}</span>
                </template>
              </template>
            </a-table>
          </a-card>
        </div>
        <div
          class="flex flex-row gap-3 mb-8"
          :class="isPortrait ? 'mb-20' : ''"
        >
          <a-card class="basis-6/12 text-left">
            <span class="text-xl font-semibold">{{
              $t("Pos.ProductsPopular10")
            }}</span>
            <a-table
              class="my-4"
              size="middle"
              :columns="columns"
              :data-source="statisticsProducts?.products_sold"
              :row-class-name="
                (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
              "
              :pagination="false"
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'quantity'">
                  <span class="font-semibold mr-2">{{ text }}</span>
                </template>
                <template v-if="column.dataIndex === 'total'">
                  <span class="text-[#09aa29] font-semibold mr-2">{{
                    formatAmount(record.total, currencyCode, decimalPrecision)
                  }}</span>
                </template>
              </template>
            </a-table>
          </a-card>
        </div>
      </div>
    </div>
    <LbrxInfoPopup
      :active="modalInfo.active"
      @closePopup="closePopup"
      :type="modalInfo.type"
      :message="modalInfo.message"
    />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
import {
  DollarCircleOutlined,
  FallOutlined,
  RiseOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
import moment from "moment/moment";
import LbrxFilterBar from "@/components/LbrxFilterBar.vue";
import LbrxFilterBarDate from "@/components/LbrxFilterBarDate.vue";
import LbrxInfoPopup from "@/components/LbrxInfoPopup.vue";
import { defineComponent, ref } from "vue";
import { notification } from "ant-design-vue";
import iconAnalytic from "@/components/icons/iconAnalytic.vue";
import { statisticService, cashDrawerService } from "@/_services";
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue";
import { currency } from "@/_helpers";
import dayjs, { Dayjs } from "dayjs";
import webSocketService from "@/_services/webSocketService";
import LbrxLoaderSpinner from "@/components/LbrxLoaderSpinner.vue";
export default defineComponent({
  name: "reportsIndex",
  components: {
    FallOutlined,
    RiseOutlined,
    LbrxFilterBar,
    LbrxFilterBarDate,
    DollarCircleOutlined,
    iconAnalytic,
    CaretUpOutlined,
    CaretDownOutlined,
    Line,
    LbrxLoaderBlur,
    LbrxInfoPopup,
    LoadingOutlined,
    LbrxLoaderSpinner,
  },
  data() {
    return {
      data: {
        labels: ["January", "February"],
        datasets: [
          {
            label: this.$t("Pos.ProfitMade"),
            backgroundColor: "#f87979",
            data: ["34", "78"],
          },
        ],
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      },
      selectedFilter: {},
      filtersData: [
        { name: this.$t("Pos.Cashier"), id: 1 },
        { name: this.$t("Pos.User"), id: 2 },
        { name: this.$t("Pos.All"), id: 3 },
      ],
      selectedFilterPeriod: {},
      selectedFilterData: {},
      selectedFilterInterval: [],
      selectedFilterDate: moment().format("YYYY-MM-DD"),
      modalReserve: {
        active: false,
      },
      modalInfo: {
        active: false,
        type: "",
        message: "",
      },
      filterPeriod: [
        {
          id: "day",
          name: this.$t("Pos.Day"),
        },
        {
          id: "week",
          name: this.$t("Pos.Week"),
        },
        {
          id: "month",
          name: this.$t("Pos.Month"),
        },
      ],
      columns: [
        {
          title: this.$t("Pos.Product"),
          dataIndex: "name",
        },
        {
          title: this.$t("Pos.QuantitySold"),
          dataIndex: "quantity",
        },
        {
          title: this.$t("Pos.AmountSold"),
          dataIndex: "total",
        },
      ],
      columnsCategory: [
        {
          title: this.$t("Pos.Category"),
          dataIndex: "name",
        },
        {
          title: this.$t("Pos.QuantitySold"),
          dataIndex: "quantity",
        },
        {
          title: this.$t("Pos.AmountSold"),
          dataIndex: "total",
        },
      ],
      tableLocations: [],
      statisticsSales: {},
      statisticsProducts: {},
      statisticsCashier: {},
      show: false,
      socket: null,
      showLoader: false,
      pos: {},
      posConfig: {},
      currency: {},
      currencyCode: {},
      drawerInfo: {},
      drawerOpened: false,
      loadingPrinting: false,
      NoOpenDrawer: false,
      isPortrait: window.matchMedia("(orientation: portrait)").matches,
      selectedParam: "",
      page: "1",
      touchStartX: 0,
      touchEndX: 0,
      loading: false,
    };
  },
  created() {
    if (localStorage.getItem("device")) {
      this.pos = JSON.parse(localStorage.getItem("device"));
      if (JSON.parse(localStorage.getItem("device")).config) {
        this.posConfig = JSON.parse(this.pos.config);
      }
    }
    if (localStorage.getItem("currencies")) {
      let currencies = JSON.parse(localStorage.getItem("currencies"));
      this.currency = currencies.find((el) => {
        return el.is_default == 1;
      });
      if (this.currency.currency) {
        this.currencyCode = this.currency.currency.code;
      }
    }
    this.getAllSales();
    this.getAllProducts();
    // this.getDrawerInfo();
    this.getCashierStatistics();
    this.selectedFilterData = this.filtersData[2];
    // initialize selectedParam
    this.selectedParam = "?device_id=" + this.pos.id;
  },
  mounted() {
    this.socket = webSocketService.getSocket();
    this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
    this.orientationMediaQuery.addEventListener(
      "change",
      this.handleOrientationChange
    );
  },
  beforeUnmount() {
    this.orientationMediaQuery.removeEventListener(
      "change",
      this.handleOrientationChange
    );
  },
  computed: {
    checkEfficiency() {
      return this.statisticsSales.currentMonthEfficiency -
        this.statisticsSales.previousMonthEfficiency <
        0
        ? false
        : true;
    },
    decimalPrecision() {
      return this.posConfig.posConfig.decimalPrecision
        ? this.posConfig.posConfig.decimalPrecision
        : 3;
    },
    getDynamicStyle() {
      return {
        width: this.fitWidth ? "fit-content" : "",
        margin: this.margin ? this.margin : "",
      };
    },
    getDivStyle() {
      return this.fitWidth ? "flex gap-2" : "absolute flex gap-2";
    },
  },
  methods: {
    handleOrientationChange(event) {
      this.isPortrait = event.matches;
    },
    formatAmount(amount, currencyCode, decimalPrecision) {
      return currency.formatAmount(amount, currencyCode, decimalPrecision);
    },
    openPopupInfo(message) {
      this.modalInfo.active = true;
      this.modalInfo.message = message;
    },
    getAllSales() {
      this.showLoader = true;
      statisticService
        .getAllSales()
        .then((res) => {
          console.log(
            "fff, ",
            res.dailySalesPrevious30Days.map((el) => {
              return el.date;
            })
          );
          this.statisticsSales = res;
          this.data.labels = res.dailySalesPrevious30Days.map((el) => {
            return el.date;
          });
          this.data.datasets[0].data = res.dailySalesPrevious30Days.map(
            (el) => {
              return el.total.toString();
            }
          );
        })
        .catch((error) => {
          console.log("error api : get products", error);
        })
        .finally(() => {
          this.show = true;
          this.showLoader = false;
        });
    },
    async getAllProducts() {
      try {
        const res = await statisticService.getAllProducts(
          "?warehouse_id=" + this.posConfig.warehouse_id
        );
        this.statisticsProducts = res;
      } catch (error) {
        console.log("error api : get products", error);
      }
      //   statisticService
      //     .getAllProducts("?warehouse_id=" + this.posConfig.warehouse_id)
      //     .then((res) => {
      //       this.statisticsProducts = res;
      //     })
      //     .catch((error) => {
      //       console.log("error api : get products", error);
      //     });
    },
    showErrorNotification(message) {
      notification.error({
        message: message,
        duration: 3, // 3s
        top: "80px",
        style: {
          backgroundColor: "#FFBFC98A",
          color: "white",
        },
      });
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: "80px",
        style: {
          color: "white",
        },
      });
    },
    getCashierStatistics(param = "?device_id=" + this.pos.id) {
      //   let param2 =
      //     this.selectedFilterInterval.length != 0
      //       ? "&start_datetime=" +
      //         this.selectedFilterInterval[0] +
      //         "&end_datetime=" +
      //         this.selectedFilterInterval[1]
      //       : "&start_datetime=" +
      //         moment().format("YYYY-MM-DD 00:00:00") +
      //         "&end_datetime=" +
      //         moment().format("YYYY-MM-DD 23:59:00");
      //   statisticService
      //     .getCashierReport(param + param2)
      //     .then((res) => {
      //       this.statisticsCashier = res;
      //     })
      //     .catch((error) => {
      //       console.log("error api : get products", error);
      //     });

      try {
        let param2 =
          this.selectedFilterInterval.length != 0
            ? "&start_datetime=" +
              this.selectedFilterInterval[0] +
              "&end_datetime=" +
              this.selectedFilterInterval[1]
            : "&start_datetime=" +
              moment().format("YYYY-MM-DD 00:00:00") +
              "&end_datetime=" +
              moment().format("YYYY-MM-DD 23:59:00");
        const res = statisticService.getCashierReport(param + param2);
        this.statisticsCashier = res;
      } catch (error) {
        console.log("error api : get products", error);
      }
    },
    getCashierStatisticsPrint(param = "?device_id=" + this.pos.id) {
      this.loadingPrinting = true;
      let param2 =
        this.selectedFilterInterval.length != 0
          ? "&start_datetime=" +
            this.selectedFilterInterval[0] +
            "&end_datetime=" +
            this.selectedFilterInterval[1]
          : "&start_datetime=" +
            moment().format("YYYY-MM-DD 00:00:00") +
            "&end_datetime=" +
            moment().format("YYYY-MM-DD 23:59:00");
      statisticService
        .getCashierReport(this.selectedParam + param2)
        .then((res) => {
          this.statisticsCashier = res;
          if (this.selectedFilterInterval.length == 0) {
            this.selectedFilterDate = moment().format("YYYY-MM-DD");
            this.getDrawerInfo();
          } else {
            // this.selectedFilterDate = moment(dateString).format('YYYY-MM-DD');
            this.getClosedDrawerInfo();
          }
        })
        .catch((error) => {
          console.log("error api : get products", error);
        });
    },
    closePopup() {
      this.modalInfo.active = false;
    },
    connectSocket() {
      //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
      const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });';

      this.socket = new WebSocket("ws://localhost:8765");
      // this.ticketPrinted = true;

      this.socket.onopen = (event) => {
        this.socket.send(token); // send the token as soon as the connection is established
      };

      this.socket.onmessage = (event) => {
        if (event.data == "Authentication successful") {
          // this.sendMessage();
        }
      };

      this.socket.onerror = (error) => {
        console.error(`WebSocket Error: ${error}`);
      };

      this.socket.onclose = (event) => {
        console.log(`WebSocket connection closed: ${event}`);
      };
    },
    sendMessage() {
      if (this.socket.readyState === WebSocket.OPEN) {
        let statObject = this.statisticsCashier;
        statObject.stock = this.statisticsProducts.stock;
        statObject.drawerInfo = {};
        // check if attributes aren't null
        statObject.drawerInfo.opening_amount =
          this.drawerInfo && this.drawerInfo.opening_amount
            ? parseFloat(this.drawerInfo.opening_amount)
            : parseFloat((0).toFixed(this.decimalPrecision));
        statObject.drawerInfo.total_cash_payment =
          this.drawerInfo && this.drawerInfo.total_cash
            ? parseFloat(
                (
                  this.drawerInfo.total_cash - this.drawerInfo.opening_amount
                ).toFixed(this.decimalPrecision)
              )
            : parseFloat((0).toFixed(this.decimalPrecision));
        statObject.drawerInfo.total_other_methods =
          this.drawerInfo && this.drawerInfo.total_other_methods
            ? parseFloat(this.drawerInfo.total_other_methods)
            : parseFloat((0).toFixed(this.decimalPrecision));
        statObject.drawerInfo.total_cash =
          this.drawerInfo && this.drawerInfo.total_cash
            ? parseFloat(this.drawerInfo.total_cash)
            : parseFloat((0).toFixed(this.decimalPrecision));
        const message = {
          action: "print_report",
          data: statObject,
        };
        this.socket.send(JSON.stringify(message));
        // this.ticketPrinted = true;
      } else {
        console.error("Socket is not open. Can't send message");
      }
    },
    printReport() {
      // if (!this.drawerOpened) {
      //     statisticService.getCashierReport("?device_id=" + this.pos.id + "&date=" + this.selectedFilterDate).then((res) => {
      //         this.statisticsCashier = res;
      if (this.NoOpenDrawer) {
        this.modalInfo.active = true;
        this.modalInfo.type = "message";
        this.modalInfo.message = this.$t("Pos.NoOpenDrawer");
        this.loadingPrinting = false;
      } else {
        this.loadingPrinting = true;
        this.sendMessage();
        setTimeout(() => {
          this.loadingPrinting = false;
        }, 2000);
      }

      //     }).catch((error) => {
      //         console.log("error api : get products", error);
      //     });
      // } else {
      //     this.openPopupInfo(this.$t('Pos.DrawerNotClose'))
      // }
    },
    selectFilterInterval(item) {
      if (item) {
        this.selectedFilterInterval = item.map((el) => {
          return dayjs(el).format("YYYY-MM-DD HH:mm");
        });
      } else {
        this.selectedFilterInterval = [];
      }
    },
    selectFilterdate(dateString) {
      this.getCashierStatisticsPrint();
    },
    getDrawerInfo() {
      cashDrawerService
        .getAll()
        .then((res) => {
          this.NoOpenDrawer = false;
          this.drawerInfo = res.data[0];
          this.drawerInfo.total_cash_payment = (
            this.drawerInfo.total_cash - this.drawerInfo.opening_amount
          ).toFixed(this.decimalPrecision);
          this.printReport();
        })
        .catch((error) => {
          console.log("error api : ", error);
          this.showErrorNotification(this.$t("Pos.ErrorTry"));
        })
        .finally(() => {
          //
        });
    },
    getClosedDrawerInfo() {
      cashDrawerService
        .getClosedDrawer(
          "?start_datetime=" +
            this.selectedFilterInterval[0] +
            "&end_datetime=" +
            this.selectedFilterInterval[1]
        )
        .then((res) => {
          if (res.message == "No open cash drawer") {
            this.NoOpenDrawer = true;
          } else {
            this.NoOpenDrawer = false;
          }
          this.drawerInfo = res.data;
          this.drawerInfo.total_cash_payment = (
            this.drawerInfo.total_cash - this.drawerInfo.opening_amount
          ).toFixed(this.decimalPrecision);
          this.printReport();
        })
        .catch((error) => {
          console.log("error api : ", error);
          this.showErrorNotification(this.$t("Pos.ErrorTry"));
        })
        .finally(() => {
          //
        });
    },
    async refreshByDate() {
      this.loading = true;
      await this.getAllProducts();
      await this.getCashierStatistics();
      this.loading = false;
    },
    selectFilterData(item) {
      this.selectedFilterData = item;
      if (item.id == 1) {
        this.selectedParam = "?device_id=" + this.pos.id;
        this.getCashierStatistics(this.selectedParam);
      } else if (item.id == 2) {
        this.selectedParam =
          "?device_id=" +
          this.pos.id +
          "&user_id=" +
          JSON.parse(localStorage.getItem("user")).user.id;
        this.getCashierStatistics(this.selectedParam);
      } else {
        this.selectedParam = "?device_id=" + this.pos.id;
        this.getCashierStatistics(this.selectedParam);
      }
    },
    handleTouchStart(event) {
      // Record the starting touch position
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchMove(event) {
      // Update the end position continuously
      this.touchEndX = event.changedTouches[0].screenX;
    },
    handleTouchEnd() {
      // Calculate the difference
      const diffX = this.touchEndX - this.touchStartX;

      // Determine swipe direction
      if (diffX > 50) {
        // Right swipe
        console.log("Swiped right");
        this.page = 1;
      } else if (diffX < -50) {
        // Left swipe
        console.log("Swiped left");
        this.page = 2;
      }
    },
    // getDrawerInfo() {
    //     this.loadingDrawer = true;
    //     cashDrawerService.open().then((res) => {
    //         if (res.data.status && res.data.status == "open") {
    //             this.drawerInfo = res.data;
    //             this.drawerInfo.total_cash_payment = (this.drawerInfo.total_cash - this.drawerInfo.opening_amount).toFixed(3);
    //             this.drawerOpened = true;
    //         }
    //     }).catch((error) => {
    //         console.log("error api : ", error);
    //     }).finally(() => {
    //         this.loadingDrawer = false;
    //     });
    // },
  },
});
</script>

<style scoped>
.footer {
  display: flex;
  align-items: center;
}

.table-status {
  display: flex;
  padding-right: 10px;
}

.place-order {
  display: flex;
  justify-content: space-evenly;
}

.occupied {
  background-color: #ff0033;
}

.onHold {
  background-color: #1891ef;
}

.vacant {
  background-color: #09aa29;
}

.reserved {
  background-color: #fe7a36;
}

.table-title {
  font-weight: 700;
  font-size: 16px;
}

.text-grey {
  color: #718096;
  font-weight: 400;
  font-size: 15px;
}

.order-btn {
  transition: transform 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.order-btn:hover {
  transform: translateY(-5px);
}

.slider-page {
  transition: width 0.3s, height 0.3s;
}
</style>