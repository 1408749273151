<template>
  <div
    class="flex-shrink-0 w-80 bg-gray-50 rounded-lg shadow-sm  flex flex-col category-container"
    :data-category-id="category.id"
  >
    <!-- category header -->
    <div
      class="p-3 bg-white rounded-t-lg border-b flex justify-between items-center"
    >
      <h3 class="font-semibold text-gray-700">
       {{ category.name }}
      </h3>
      <div class="flex gap-2">
        <button class="px-3 rounded-md bg-[#f4f4f4]" @click="moveLeft">
          <LeftOutlined />
        </button>
        <button class="px-3 rounded-md bg-[#f4f4f4]" @click="moveRight">
          <RightOutlined />
        </button>
      </div>
    </div>

    <!-- products container -->  
    <div
      class="py-4 px-6 min-h-[200px] max-h-[calc(100vh-120px)] overflow-y-auto flex flex-col scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
      style="scrollbar-width: thin; scrollbar-gutter: stable; touch-action: pan-y;"

    >
      <draggable
        v-model="state.category.products"
        :key="category.id + category.products.length"
        :animation="200"
        :group="{ name: 'products' }"
        class="space-y-2"
        @end="onProductDragEnd($event, category.id)"
      >
        <template v-if="state.category.products?.length === 0">
          <div
            class="p-4 text-gray-400 text-center border border-dashed rounded"
          >
            {{$t('Pos.dargProductTxt')}}
          </div>
        </template>
        <template v-for="product in state.category.products" :key="product.id">
          <a-row
            :gutter="16"
            class=" bg-white p-3 rounded border shadow-sm hover:shadow-md transition-shadow duration-200 cursor-move px-1 py-1"
            :data-product-id="product.id"
          >
            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :md="{ span: 4 }"
              :lg="{ span: 5 }"
              :xl="{ span: 5 }"
              :xxl="{ span: 5 }"
            >
              <img
              v-if="product.photo!==null"
                class="w-10 h-10 object-contain"
                :src="product.photo"
                alt=""
              />
              <img
              v-if="product.photo===null"
                class="w-10 h-10 object-contain"
                src="no-photo.png"
                alt=""
              />
            </a-col>

            <a-col
              :xs="{ span: 24 }"
              :sm="{ span: 24 }"
              :md="{ span: 20 }"
              :lg="{ span: 19 }"
              :xl="{ span: 19 }"
              :xxl="{ span: 19 }"
              class="flex flex-row flex-nowrap justify-start items-center"
            >
              <span
                class="font-small text-gray-800 leading-4 line-clamp-2 pb-[1px]"
                >{{ product.name }} </span
              >
            </a-col>
          </a-row>
        </template>
      </draggable>
    </div>
  </div>
</template>


<script lang="ts">
import { productService } from "@/_services";
import {
  defineComponent,
  onMounted,
  defineProps,
  reactive,
  ref,
  defineEmits,
  computed,
  watch,
} from "vue";
import { LeftOutlined,RightOutlined  } from "@ant-design/icons-vue";

export default defineComponent({
  name: "LbrxCategoryBarItem",
  components: {LeftOutlined,RightOutlined},
});
</script>

<script lang="ts" setup>
import { VueDraggableNext } from "vue-draggable-next";
const draggable = VueDraggableNext;

interface Product {
  id: string;
  name: string;
  display_rank: number;
  category_id: number;
}

interface Category {
  id: string;
  name: string;
  description: string;
  display_rank: string;
  products: Product[];
}

const state = reactive({
  category: {} as any,
  products: [] as Product[],
  warehouseId: "",
});

const props = defineProps<{
  category: Category;
}>();

const emit = defineEmits<{
  (
    e: "update-products",
    affectedProducts: Array<{
      id: string;
      category_id: string;
      display_rank: number;
    }>
  ): void;
  (
    e: "moveCategory",
    categoryId:string,
    direction:string
  ): void;
}>();

const getConfig = () => {
  const device = localStorage.getItem("device");
  if (device) {
    const parsedDevice = JSON.parse(device);
    state.warehouseId = JSON.parse(parsedDevice.config).warehouse_id;
  }
};

const getCatProducts = () => {
  productService
    .getProductsByCategory(
      props.category.id,
      "&warehouse_id=" + state.warehouseId
    )
    .then((res) => {
      state.products = res.data;
      console.log("product cat products", state.products);
    })
    .catch((error) => {
      console.error("error", error);
    });
};

const generateRandom = (length: number) => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15) +
    length
  );
};

const affectedProducts = ref<
  Array<{ id: string; category_id: any; display_rank: any }>
>([]);

const onProductDragEnd = (evt: any, categoryId: string) => {
  const { oldIndex, newIndex, from, to, item } = evt;
  console.log("onProductDragEnd", oldIndex, newIndex, from, to, categoryId);

  const fromCategoryId = from
    .closest(".category-container")
    ?.getAttribute("data-category-id");
  const toCategoryId = to
    .closest(".category-container")
    ?.getAttribute("data-category-id");

  console.log("from", fromCategoryId, "to", toCategoryId);

  if (fromCategoryId === toCategoryId) {
    if (oldIndex === newIndex) return;

    props.category.products.forEach((product: any, index: any) => {
      const newRank = index + 1;

      // checking if the product is already in affectedProducts
      const existingProductIndex = affectedProducts.value.findIndex(
        (p) => p.id === product.id
      );

      if (existingProductIndex !== -1) {
        affectedProducts.value[existingProductIndex].display_rank = newRank;
      } else {
        affectedProducts.value.push({
          id: product.id,
          category_id: categoryId,
          display_rank: newRank,
        });
      }

      product.display_rank = newRank;
    });
  } else {
    console.log("products", state.category.products);
    console.log("in catgeory", state.category.id);
  }

  console.log("Affected products:", affectedProducts.value);
  emit("update-products", affectedProducts.value);
};

watch(
  () => state.category.products,
  (newProducts, oldProducts) => {
    if (oldProducts && oldProducts.length !== newProducts.length) {
      affectedProducts.value = [];
      updateRanks(newProducts);
      console.log("affected products", affectedProducts.value);
      emit("update-products", affectedProducts.value);
    }
  },
  { deep: true }
);

const updateRanks = (productsArray: []) => {
  productsArray.forEach((product: any, index: any) => {
    const newRank = index + 1;

    // checking if the product is already in affectedProducts
    const existingProductIndex = affectedProducts.value.findIndex(
      (p) => p.id === product.id
    );

    if (existingProductIndex !== -1) {
      affectedProducts.value[existingProductIndex].display_rank = newRank;
    } else {
      affectedProducts.value.push({
        id: product.id,
        category_id: props.category.id,
        display_rank: newRank,
      });
    }

    product.display_rank = newRank;
  });

  console.log("neeeww affected products", productsArray);
};


const verifyRanks = () => {
  let currentRank = 1;
  props.category.products.forEach((product) => {
    if (product.display_rank === null || product.display_rank === undefined) {
      product.display_rank = currentRank;
    }
    currentRank++;
  });
};

//move items  
const moveLeft=()=>{
  
  emit("moveCategory", props.category.id, "left" );
}


const moveRight=()=>{
  emit("moveCategory", props.category.id, "right" );
}

onMounted(async () => {
  state.category = props.category;
  state.products = [...props.category.products];
});
</script>


<style scoped>
.draggable-ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.draggable-drag {
  opacity: 0.8;
}
</style>
