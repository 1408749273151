<template>
  <div
    class="h-full w-full flex flex-row gap-4 p-4 overflow-x-auto category-bar"
    ref="scrollContainer"
  >
    <!-- <draggable
      v-model="state.categories"
      :animation="200"
      item-key="id"
      class="flex flex-row gap-4"
      @end="onDragEnd"
      @start="startScroll"
    > -->
    <div class="flex flex-row gap-4">
      <template v-for="category in state.categories" :key="category.id">
        <LbrxCategoryBarItem
          class="category-item"
          :category="category"
          @update-products="updateAffectedProducts"
          @moveCategory="moveCategoryItem"
        />
      </template>
    </div>
    <!-- </draggable> -->
  </div>
</template>


<script lang="ts">
import { defineComponent, defineExpose } from "vue";
import LbrxCategoryBarItem from "@/components/LbrxCategoryBarItem.vue";

export default defineComponent({
  name: "LbrxCategoryBar",
  components: {
    LbrxCategoryBarItem,
  },
});
</script>

<script setup lang="ts">
import { onMounted, reactive, ref, defineEmits } from "vue";
import { categoryService, productService } from "@/_services";

import { VueDraggableNext } from "vue-draggable-next";

const draggable = VueDraggableNext;

const emit = defineEmits(["update:loadingData", "update:affectedProducts"]);

const state = reactive({
  categories: [] as any[],
  warehouseId: "",
  affectedCat: [] as any[],
  affectedProducts: [] as Array<{
    id: string;
    category_id: string;
    display_rank: number;
  }>,
});

const loading = ref(false);

const getCategories = async () => {
  emit("update:loadingData", true);
  try {
    const param = "?pagination=off&warehouse_id=" + state.warehouseId;
    const res = await categoryService.getAllWithProducts(param);
    state.categories = res.data;
    emit("update:loadingData", false);
  } catch (error) {
    console.log(error);
    emit("update:loadingData", false);
  }
};

const loadingData = ref(false);
const updateCategoryRank = async () => {
  if (state.affectedCat.length !== 0) {
    loadingData.value = true;
    emit("update:loadingData", true);

    try {
      let formData = new FormData();
      formData.append("categories", JSON.stringify(state.affectedCat));

      const res = await categoryService.updateConfig(formData);
      loadingData.value = false;
      emit("update:loadingData", false);
      state.affectedCat = [];
    } catch (error) {
      loadingData.value = false;
      emit("update:loadingData", false);
      state.affectedCat = [];

      console.error("Error updating category rank:", error);
    }
  } else {
    return;
  }
};

defineExpose({
  updateCategoryRank,
  getCategories,
  getAffectedProducts: () => state.affectedProducts,
});

const onDragEnd = async (evt: any) => {
  stopScroll();
  const { oldIndex, newIndex } = evt;
  if (oldIndex === newIndex) return;

  const movedCategory = state.categories[newIndex];
  const affectedCategories = [] as any[];

  // update display ranks
  state.categories.forEach((category, index) => {
    const newRank = index + 1;
    // if (category.display_rank !== newRank) {
    category.display_rank = newRank;
    affectedCategories.push({ id: category.id, display_rank: newRank });
    state.affectedCat = affectedCategories;
    // }
  });
  //   console.log('affectedCategories:', affectedCategories)
};

//auto scroll
const scrollContainer = ref<HTMLElement | null>(null);
let autoScrollInterval: number | null = null;

const startScroll = () => {
  console.log("start scroll");
  document.addEventListener(
    "mousemove",
    handleDragMove as (event: MouseEvent) => void
  );
};

const stopScroll = () => {
  console.log("remove scroll");

  document.removeEventListener(
    "mousemove",
    handleDragMove as (event: MouseEvent) => void
  );
  if (autoScrollInterval) {
    clearInterval(autoScrollInterval);
    autoScrollInterval = null;
  }
};

const handleDragMove = (event: DragEvent) => {
  const container = scrollContainer.value;
  if (!container) return;

  const scrollThreshold = 50;
  const scrollSpeed = 20;

  const { clientX } = event;
  const { left, right, width } = container.getBoundingClientRect();

  if (clientX < left + scrollThreshold) {
    // Scroll left
    if (!autoScrollInterval) {
      autoScrollInterval = setInterval(() => {
        container.scrollLeft -= scrollSpeed;
      }, 50);
    }
  } else if (clientX > right - scrollThreshold) {
    // Scroll right
    if (!autoScrollInterval) {
      autoScrollInterval = setInterval(() => {
        container.scrollLeft += scrollSpeed;
      }, 50);
    }
  } else {
    // Stop scrolling if not at the edge
    if (autoScrollInterval) {
      clearInterval(autoScrollInterval);
      autoScrollInterval = null;
    }
  }
};

// update category product
const updateAffectedProducts = (
  updatedProducts: Array<{
    id: string;
    category_id: string;
    display_rank: number;
  }>
) => {
  updatedProducts.forEach((updatedProduct) => {
    const existingIndex = state.affectedProducts.findIndex(
      (p) => p.id === updatedProduct.id
    );
    if (existingIndex !== -1) {
      state.affectedProducts[existingIndex] = updatedProduct;
    } else {
      state.affectedProducts.push(updatedProduct);
    }
  });

  emit("update:affectedProducts", state.affectedProducts);
};

const getConfig = () => {
  const device = localStorage.getItem("device");
  if (device) {
    const parsedDevice = JSON.parse(device);
    state.warehouseId = JSON.parse(parsedDevice.config).warehouse_id;
  }
};
//move categroy item
const moveCategoryItem2 = (categoryId: string, direction: string) => {
  const currentIndex = state.categories.findIndex(
    (category) => category.id === categoryId
  );
  if (currentIndex === -1) return;

  const container = scrollContainer.value;
  const categoryElements = document.querySelectorAll(".category-item");

  if (!container || categoryElements.length === 0) return;

  let targetIndex = currentIndex;

  if (direction === "left" && currentIndex > 0) {
    [state.categories[currentIndex - 1], state.categories[currentIndex]] = [
      state.categories[currentIndex],
      state.categories[currentIndex - 1],
    ];
    targetIndex = currentIndex - 1;
  }

  if (direction === "right" && currentIndex < state.categories.length - 1) {
    [state.categories[currentIndex + 1], state.categories[currentIndex]] = [
      state.categories[currentIndex],
      state.categories[currentIndex + 1],
    ];
    targetIndex = currentIndex + 1;
  }

  const targetCategory = categoryElements[targetIndex];
  if (targetCategory) {
    targetCategory.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }
};

const moveCategoryItem = (categoryId: string, direction: string) => {
  const currentIndex = state.categories.findIndex(
    (category) => category.id === categoryId
  );
  if (currentIndex === -1) return;

  const container = scrollContainer.value;
  const categoryElements = container?.querySelectorAll(".category-item");

  if (!container || !categoryElements || categoryElements.length === 0) return;

  let targetIndex = currentIndex;

  if (direction === "left" && currentIndex > 0) {
    [state.categories[currentIndex - 1], state.categories[currentIndex]] = [
      state.categories[currentIndex],
      state.categories[currentIndex - 1],
    ];
    targetIndex = currentIndex - 1;
  }

  if (direction === "right" && currentIndex < state.categories.length - 1) {
    [state.categories[currentIndex + 1], state.categories[currentIndex]] = [
      state.categories[currentIndex],
      state.categories[currentIndex + 1],
    ];
    targetIndex = currentIndex + 1;
  }

  //update affected categories
  const affectedCategories = [] as any[];
  state.categories.forEach((category, index) => {
    category.display_rank = index + 1;
    affectedCategories.push({
      id: category.id,
      display_rank: category.display_rank,
    });
  });

  state.affectedCat = affectedCategories;
  console.log("affected categories when sliding", state.affectedCat);

  const targetCategory = categoryElements[targetIndex] as HTMLElement;
  if (targetCategory) {
    const containerRect = container.getBoundingClientRect();
    const targetRect = targetCategory.getBoundingClientRect();

    const scrollLeft =
      container.scrollLeft +
      targetRect.left -
      containerRect.left -
      (containerRect.width - targetRect.width) / 2;

    container.scrollTo({
      left: scrollLeft,
      behavior: "smooth",
    });
  }
};

onMounted(async () => {
  await getConfig();
  await getCategories();
});
</script>

<style scoped>
.draggable-ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.draggable-drag {
  opacity: 0.8;
}

/* scrollbar for products container */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: transparent;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}
</style>
