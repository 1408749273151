<template>
  <div id="filter-container"
    class="bg-[#fff] relative flex items-center my-4 rounded-lg px-2 py-3 gap-2 min-h-16 border-2 text-sm font-semibold border-b-gray-300 overflow-x-auto overflow-y-hidden "
    :style="getDynamicStyle" :class="showOpenDrawer ? 'justify-between' : ''">
    <div class="flex flex-row items-center text-nowrap" v-if="title">
      <span>{{ title + " :" }}</span>
    </div>
    <div :class="getDivStyle">
      <div v-if="title && !fitWidth" class="ml-20"></div>
      <div v-for="(item, index) in filters" :key="index" @click="selectFilter(item)"
        class="flex flex-row items-center text-nowrap text-sm font-semibold hover:bg-primary-light hover:outline outline-primary outline-2 hover:text-primary py-2 rounded-md cursor-pointer"
        :class="selectedFilter.id == item.id ? 'text-primary bg-primary-light outline outline-primary' : ''">
        <span class="mx-3">{{ item.name }}</span>
      </div>
    </div>
    <div v-if="filters.length == 0">{{ $t('Pos.NoData') }}</div>
    <div v-if="showOpenDrawer">
      <div class="absolute top-0 right-2 my-2 flex px-8 py-3 rounded-md items-center justify-center text-sm gap-2 text-white bg-primary"
        @click="openDrawer()">
        <span>{{ $t('Pos.OpenDrawer') }}</span>
      </div>
    </div>
    <!-- <div id="scroll-icon" class="absolute top-0 right-0 hidden">
      <div class="bg-gray-300 h-16 flex items-center">
        <iconForward class="h-fit mx-1" :size="24" :color="'#fff'" />
      </div>
    </div> -->
  </div>

</template>

<script>
import iconForward from './icons/iconForward.vue';
export default {
  name: "LbrxFilterBar",
  components: {
    // iconForward
  },
  data() {
    return {

    };
  },
  props: {
    filters: {
      required: true,
      value: Array
    },
    selectedFilter: {
      required: false,
      value: Object
    },
    fitWidth: {
      required: false,
      value: Boolean,
      default: false
    },
    margin: {
      required: false,
      value: String,
      default: ""
    },
    showOpenDrawer: {
      required: false,
      value: Boolean,
      default: false
    },
    title: {
      required: false,
      value: String,
      default: ""
    }
  },
  // mounted() {
  //   // put function here tow wait until get categories API loads
  //   setTimeout(() => {
  //     const container = document.getElementById('filter-container');
  //     const icon = document.getElementById('scroll-icon');

  //       // Show icon if scrollWidth greater than clientWidth
  //       if (container.scrollWidth > container.clientWidth) {
  //         icon.classList.remove('hidden');
  //       } else {
  //         icon.classList.add('hidden');
  //       }
  //   }, 500)
  //   this.checkScroll();
  // },
  computed: {
    getDynamicStyle() {
      return {
        width: this.fitWidth ? 'fit-content' : '',
        margin: this.margin ? this.margin : ''
      }
    },
    getDivStyle() {
      return this.fitWidth ? "flex gap-2" : "absolute flex gap-2";
    }
  },
  methods: {
    // checkScroll() {
    //   window.addEventListener('DOMContentLoaded', () => {
    //   const container = document.getElementById('filter-container');
    //   const icon = document.getElementById('scroll-icon');

    //   function checkForScroll() {
    //     // Show icon if scrollWidth greater than clientWidth
    //     if (container.scrollWidth > container.clientWidth) {
    //       icon.classList.remove('hidden');
    //     } else {
    //       icon.classList.add('hidden');
    //     }
    //   }

    //   // Initial check
    //   checkForScroll();

    //   // Recheck on window resize or any action that might change size
    //   window.addEventListener('resize', checkForScroll);
    // });
    // },
    selectFilter(item) {
      this.$emit("selectFilter", item);
    },
    openDrawer() {
      this.$emit("openDrawer");
    }
  }
};
</script>

<style scoped></style>