<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[50vw] my-52 rounded-3xl overflow-hidden h-fit flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div v-if="type == 'update'" class="flex flex-col flex-grow h-full mx-10 my-9 gap-6 text">
        <form class="bg-[#fff] rounded-lg px-6 py-5 my-2 border-2 border-b-gray-300">
          <div class="input-group-col">
            <div class="column-col">
              <label for="name" class="label-col">{{ $t("Pos.Name") }}</label>
              <a-input v-model:value="selectedProduct.name" @click="selectInput('name')" @input="validateFieldsAction"
                type="text" id="name" class="input-box-col" :placeholder="$t('Pos.Name')" />
            </div>

            <div class="column-col">
              <label for="price" class="label-col">{{ $t("Pos.Price") }}</label>
              <a-input v-model:value="selectedProduct.price" @click="selectInput('price')" @input="validateFieldsAction"
                type="text" id="price" class="input-box-col" :placeholder="$t('Pos.Price')" />
            </div>
          </div>

          <div class="input-group-col">
            <div class="column-col">
              <label for="p_price" class="label-col">{{ $t("Pos.PurchasePrice") }}</label>
              <a-input v-model:value="selectedProduct.purchase_price" @click="selectInput('p_price')"
                @input="validateFieldsAction" type="text" id="p_price" class="input-box-col"
                :placeholder="$t('Pos.PurchasePrice')" />
            </div>

            <div class="column-col">
              <label for="d_rank" class="label-col">{{ $t("Pos.DisplayRank") }}</label>
              <a-input v-model:value="selectedProduct.display_rank" @click="selectInput('d_rank')"
                @input="validateFieldsAction" type="text" id="d_rank" class="input-box-col"
                :placeholder="$t('Pos.DisplayRank')" />
            </div>
          </div>

          <div class="input-group-col">
            <div class="column-col">
              <label for="code_ean" class="label-col">{{ $t("Pos.CodeEAN") }}</label>
              <a-input v-model:value="selectedProduct.code_ean" @click="selectInput('code_ean')" type="text"
                id="code_ean" class="input-box-col" :placeholder="$t('Pos.CodeEAN')" />
            </div>

            <div class="column-col">
              <label for="barcode" class="label-col">{{ $t("Pos.Barcode") }}</label>
              <a-input v-model:value="selectedProduct.barcode" @click="selectInput('barcode')" type="text" id="barcode"
                class="input-box-col" :placeholder="$t('Pos.Barcode')" />
            </div>
          </div>


          <div class="mb-5 mt-1">
            <div class="text-left">
              <label for="desc" class="label-col">{{ $t("Pos.Description") }}</label>
              <a-input v-model:value="selectedProduct.description" @click="selectInput('desc')" type="text" id="desc"
                class="input-box-col" :placeholder="$t('Pos.BillingAddress')" />
            </div>
          </div>

          <div class="flex mb-4 gap-8">
            <div class="flex gap-3">
              <label class="cursor-pointer leading-5" for="refundable">{{ $t('Pos.Refundable') }}</label>
              <a-switch id="refundable" class="bg-[#bfbfbf]" v-model:checked="selectedProduct.refundable" />
            </div>
            <div class="flex gap-3">
              <label class="cursor-pointer leading-5" for="refundable">{{ $t('Pos.Stockable') }}</label>
              <a-switch id="stockable" class="bg-[#bfbfbf]" v-model:checked="selectedProduct.is_stockable" />
            </div>
          </div>

          <div class="label-button-container">
            <a-button @click="closePopup" type="text"
              style="display: flex; align-items: center; font-size: 16px; height: auto; padding: 0 20px; margin-right: 15px;">
              <template #icon>
                <EditOutlined :style="{ fontSize: '20px' }" />
              </template>
              {{ $t("Pos.Cancel") }}
            </a-button>
            <a-button @click="confirm" :disabled="disabled"
              class="text-white font-semibold bg-primary py-6 px-8 flex items-center border-none" style="color: #fff;">
              {{ $t('Pos.Update') }}
            </a-button>
          </div>
        </form>

      </div>

      <div v-else class="flex flex-col flex-grow h-full mx-10 my-9 gap-6 text">
        <a-steps class="mt-2" :current="currentStep" size="small" :items="stepArray"></a-steps>
        <form class="bg-[#fff] rounded-lg px-6 py-5 my-2 border-2 border-b-gray-300">
          <div v-if="currentStep == 0">
            <div class="input-group-col">
              <div class="column-col">
                <label for="name" class="label-col">{{ $t("Pos.Name") }}</label>
                <a-input v-model:value="product.name" @click="selectInput('name')"
                  @input="validateFieldsActionAdd" type="text" id="name" class="input-box-col"
                  :placeholder="$t('Pos.Name')" />
              </div>

              <div class="column-col">
                <label for="category" class="label-col">{{ $t("Pos.Category") }}</label>
                <a-select v-model:value="product.category_id" :option-filter-prop="'label'"
                  @select="validateFieldsActionAdd" class="select-box-col" :size="'large'">
                  <a-select-option v-for="category in categories" :label="category.name" :key="category.id"
                    :value="category.id" :selected="category.id === product.category_id">{{ category.name
                    }}</a-select-option>
                </a-select>
              </div>
            </div>

            <div class="input-group-col">
              <div class="column-col">
                <label for="manufacturers" class="label-col">{{ $t("Pos.Manufacturer") }}</label>
                <a-select v-model:value="product.manufacturer_id" :option-filter-prop="'label'"
                  @select="validateFieldsActionAdd" class="select-box-col" :size="'large'">
                  <a-select-option v-for="item in manufacturers" :label="item.name" :key="item.id" :value="item.id"
                    :selected="item.id === product.manufacturer_id">{{ item.name }}</a-select-option>
                </a-select>
              </div>

              <div class="column-col">
                <label for="suppliers" class="label-col">{{ $t("Pos.Supplier") }}</label>
                <a-select v-model:value="product.supplier_id" :option-filter-prop="'label'"
                  @select="validateFieldsActionAdd" class="select-box-col" :size="'large'">
                  <a-select-option v-for="item in suppliers" :label="item.name" :key="item.id" :value="item.id"
                    :selected="item.id === product.supplier_id">{{ item.company_name }}</a-select-option>
                </a-select>
              </div>
            </div>

            <div class="input-group-col">
              <div class="column-col">
                <label for="vatrates" class="label-col">{{ $t("Pos.Vatrate") }}</label>
                <a-select v-model:value="product.vat_id" :option-filter-prop="'label'" @select="validateFieldsActionAdd"
                  class="select-box-col" :size="'large'">
                  <a-select-option v-for="item in vatrates" :label="item.name" :key="item.id" :value="item.id"
                    :selected="item.id === product.vat_id">{{ item.name }}</a-select-option>
                </a-select>
              </div>

              <div class="column-col">
                <label for="unit_price" class="label-col">{{ $t("Pos.Price") }}</label>
                <a-input v-model:value="product.unit_price" @click="selectInput('unit_price')"
                  @input="validateFieldsActionAdd" type="text" id="unit_price" class="input-box-col"
                  :placeholder="$t('Pos.Price')" />
              </div>
            </div>

            <div class="label-button-container">
              <a-button @click="closePopup" type="text"
                style="display: flex; align-items: center; font-size: 16px; height: auto; padding: 0 20px; margin-right: 15px;">
                <template #icon>
                  <EditOutlined :style="{ fontSize: '20px' }" />
                </template>
                {{ $t("Pos.Cancel") }}
              </a-button>
              <a-button @click="nextStep" :disabled="disabledStep1"
                class="text-white font-semibold bg-primary py-6 px-8 flex items-center border-none"
                style="color: #fff;">
                {{ $t('Pos.Next') }}
              </a-button>
            </div>
          </div>
          <div v-else>
            <div class="input-group-col">
              <div class="column-col">
                <label for="p_price" class="label-col">{{ $t("Pos.PurchasePrice") }}</label>
                <a-input v-model:value="selectedProduct.purchase_price" @click="selectInput('p_price')"
                  @input="validateFieldsActionAdd" type="text" id="p_price" class="input-box-col"
                  :placeholder="$t('Pos.PurchasePrice')" />
              </div>

              <div class="column-col">
                <label for="vatrates" class="label-col">{{ $t("Pos.Vatrate") }}</label>
                <a-select v-model:value="product.unit" :option-filter-prop="'label'" @select="validateFieldsActionAdd"
                  class="select-box-col" :size="'large'">
                  <a-select-option value="mg" :label="$t('Pos.Milligrams')">{{ $t('Pos.Milligrams') }}</a-select-option>
                  <a-select-option value="pcs" :label="$t('Pos.Pieces')">{{ $t('Pos.Pieces') }}</a-select-option>
                  <a-select-option value="ml" :label="$t('Pos.Milliliter')">{{ $t('Pos.Milliliter') }}</a-select-option>
                  <a-select-option value="mm" :label="$t('Pos.Millimeter')">{{ $t('Pos.Millimeter') }}</a-select-option>
                </a-select>
              </div>
            </div>

            <div class="input-group-col">
              <div class="column-col">
                <label for="code_ean" class="label-col">{{ $t("Pos.CodeEAN") }}</label>
                <a-input v-model:value="selectedProduct.code_ean" @click="selectInput('code_ean')" type="text"
                  id="code_ean" class="input-box-col" @input="validateFieldsActionAdd" :placeholder="$t('Pos.CodeEAN')" />
              </div>

              <div class="column-col">
                <label for="barcode" class="label-col">{{ $t("Pos.Barcode") }}</label>
                <a-input v-model:value="selectedProduct.barcode" @click="selectInput('barcode')" type="text"
                  id="barcode" class="input-box-col" @input="validateFieldsActionAdd" :placeholder="$t('Pos.Barcode')" />
              </div>
            </div>

            <div class="label-button-container">
              <a-button @click="previousStep" type="text"
                style="display: flex; align-items: center; font-size: 16px; height: auto; padding: 0 20px; margin-right: 15px;">
                <template #icon>
                  <EditOutlined :style="{ fontSize: '20px' }" />
                </template>
                {{ $t("Pos.Return") }}
              </a-button>
              <a-button @click="addProduct" :disabled="disabledStep2"
                class="text-white font-semibold bg-primary py-6 px-8 flex items-center border-none"
                style="color: #fff;">
                {{ $t('Pos.Add') }}
              </a-button>
            </div>
          </div>
        </form>

      </div>
    </div>
    <LbrxKeyboardPopup ref="keyboardPopup" :active="modalKeyboard.active" @closePopupKeyboard="closePopupKeyboard" />
  </div>
</template>

<script>
import { productService } from "@/_services";
import LbrxKeyboardPopup from "@/components/LbrxKeyboardPopup.vue";
import { notification } from 'ant-design-vue';
import router from "@/router";
export default {
  name: "LbrxDetailPopup",
  components: {
    LbrxKeyboardPopup
  },
  data() {
    return {
      activeKey: '0',
      checkedItems: [],
      quantity: "",
      productInstance: {},
      selectedProduct: {
        name: '',
        price: '',
        purchase_price: '',
        display_rank: '',
        code_ean: '',
        barcode: '',
        description: '',
        refundable: false,
        is_stockable: false
      },
      product: {
        name: '',
        category_id: null,
        manufacturer_id: null,
        supplier_id: null,
        vat_id: null,
        unit_price: '',
        purchase_price: '',
        unit: '',
        code_ean: '',
        barcode: '',
        refundable: false,
        is_stockable: false
      },
      modalKeyboard: {
        active: false
      },
      currentStep: 0,
      stepArray: [{ title: this.$t('Pos.Step1') }, { title: this.$t('Pos.Step2') }],
      disabledStep1: true,
      disabledStep2: true
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    posConfig: {
      required: true,
      value: Object
    },
    type: {
      required: true,
      value: String
    },
    categories: {
      required: true,
      value: Array
    },
    manufacturers: {
      required: true,
      value: Array
    },
    suppliers: {
      required: true,
      value: Array
    },
    vatrates: {
      required: true,
      value: Array
    }
  },
  computed: {
    getSelectedTypeStyleIcon() {
      return JSON.parse(localStorage.getItem('themeTemplate')).primaryColor;
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    goDrawer() {
      router.push('/cashier');
    },
    markOffer() {
      router.push('/checkout');
    },
    proceedNormal() {
      router.push('/checkout');
    },
    getSelectedProduct(product) {
      this.selectedProduct.name = product.name;
      this.selectedProduct.price = product.price;
      this.selectedProduct.purchase_price = product.purchase_price;
      this.selectedProduct.display_rank = product.display_rank;
      this.selectedProduct.code_ean = product.code_ean;
      this.selectedProduct.barcode = product.barcode;
      this.selectedProduct.description = product.description;
      this.selectedProduct.refundable = product.refundable == 1 ? true : false;
      this.selectedProduct.is_stockable = product.is_stockable == 1 ? true : false;
      this.productInstance = product;
      this.validateFieldsAction();
    },
    selectInput(id) {
      this.selectedText = document.getElementById(id).value;
      this.selectedId = id;
      if (this.posConfig.posConfig.showKeyboard == 1) {
        this.modalKeyboard.active = true;
        this.$refs.keyboardPopup.addSelectedInput(this.selectedText);
      }
    },
    closePopupKeyboard(text) {
      const inputValue = document.getElementById(this.selectedId);
      inputValue.value = text.toLowerCase();
      this.modalKeyboard.active = false;
      if (this.type == 'update')
        this.validateFieldsAction();
      else
        this.validateFieldsActionAdd();
    },
    validateFieldsAction() {
      this.disabled = !(document.getElementById("name")?.value !== '' && document.getElementById("price")?.value !== '' && document.getElementById("p_price")?.value !== '' && document.getElementById("code_ean")?.value != '' && document.getElementById("barcode")?.value !== '');
    },
    validateFieldsActionAdd() {
      this.disabledStep1 = !(document.getElementById("name")?.value !== '' && this.product.category_id != null && this.product.manufacturer_id != null && this.product.supplier_id != null && this.product.vat_id != null && document.getElementById("unit_price")?.value !== '');
      this.disabledStep2= !(document.getElementById("p_price")?.value !== '' && this.product.vat_id != null && document.getElementById("code_ean")?.value !== '' && document.getElementById("barcode")?.value !== '');
    },
    confirm() {
      this.$emit('toggleLoaderAnimation');
      let payload = this.productInstance;
      delete payload.photo;

      payload.name = document.getElementById("name")?.value;
      payload.price = document.getElementById("price")?.value;
      payload.purchase_price = document.getElementById("p_price")?.value;
      payload.display_rank = document.getElementById("d_rank")?.value;
      payload.code_ean = document.getElementById("code_ean")?.value;
      payload.barcode = document.getElementById("barcode")?.value;
      payload.description = document.getElementById("desc")?.value;
      payload.refundable = this.selectedProduct.refundable ? "1" : "0";
      payload.is_stockable = this.selectedProduct.is_stockable ? "1" : "0";

      productService.update(this.productInstance.id, payload).then((res) => {
        this.closePopup();
        this.showNotification(this.$t('Pos.UpdateSuccesful'));
      }).catch((error) => {
        this.showErrorNotification(this.$t('Pos.ErrorTry'));
        console.log("error api : ", error);
      }).finally(() => {
        this.$emit('toggleLoaderAnimation');
      });
    },
    showErrorNotification(message) {
      notification.error({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          backgroundColor: '#FFBFC98A',
          color: 'white'

        }
      });
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          color: 'white'
        }
      });
    },
    addProduct() {
      let payload = this.product;
      payload.purchase_price = document.getElementById("p_price")?.value;
      payload.unit = this.product.unit;
      payload.code_ean = document.getElementById("code_ean")?.value;
      payload.barcode = document.getElementById("barcode")?.value;
      this.$emit('toggleLoaderAnimation');

      productService.create(payload).then((res) => {
        this.closePopup();
        this.showNotification(this.$t('Pos.AddSuccessful'));
      }).catch((error) => {
        this.showErrorNotification(this.$t('Pos.ErrorTry'));
        console.log("error api : ", error);
      }).finally(() => {
        this.$emit('toggleLoaderAnimation');
      });

    },
    nextStep() {
      this.product.name = document.getElementById("name")?.value;
      this.product.unit_price = document.getElementById("unit_price")?.value;
      this.currentStep += 1;
      setTimeout(() => {
        this.validateFieldsActionAdd();
      }, 100);
    },
    previousStep() {
      this.currentStep -= 1;
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}

.blue-button {
  background-color: #008FCA;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 25px 40px;
}

.input-group-col {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  gap: 1.5rem;
  /* Adjust as needed */
}


.input-box-col {
  flex: 1;
  border: 3px solid #79767648;
  padding: 8px;
  border-radius: 8px;
}

.select-box-col {
  flex: 1;
  border: 2px solid #79767648;
  border-radius: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input-group label {
  font-size: 15px;
  align-self: flex-start;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-box {
  border: 2px solid #79767648;
  padding: 8px;
  border-radius: 5px;
}

.column-col {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* Take all available space */
  /* Adjust as needed for space between columns */
}


.label-col {
  color: black;
  font-weight: 500;
  font-size: 16px;
  align-self: flex-start;
}


/* Styles for the common container */
.label-button-container {
  display: flex;
}
</style>