<template>
  <div
    class="bg-[rgb(255,255,255)] flex border-r-2 border-b-gray-300"
    :class="isPortrait ? 'w-full' : 'flex-col h-full w-20'"
  >
    <div
      class="flex-1 text-xs font-semibold"
      :class="
        isPortrait
          ? 'flex justify-between overflow-auto'
          : 'grid justify-center items-center content-between'
      "
    >
      <div
        class="flex justify-center items-center gap-1 text-ellipsis"
        :class="isPortrait ? '' : 'flex-col overflow-hidden'"
      >
        <div
          v-for="(item, index) in navDataFiltered"
          :key="index"
          @click="navigatePage(item.navigation)"
          class="flex flex-col items-center py-4 w-full"
          :class="getStyleDiv(item)"
        >
          <LbrxSideMenuIcons
            :iconSize="item.iconSize"
            :iconColor="currentRoute == item.icon ? '#fff' : item.iconColor"
            :icon="item.icon"
          /><span>{{ item.name }}</span>
        </div>
      </div>
      <div
        class="px-3 rounded-2xl flex flex-col gap-4 justify-center items-center mx-3"
        :class="isPortrait ? '' : 'mb-5'"
      >
        <!-- <div> <img :src="user.photo ? user.photo : '/default_img.jpg'" class="rounded-full w-8" alt=""> </div> -->
        <!-- <div ref="languageRef" @click="showLangPopup"
          class="relative flex flex-col items-center w-full rounded-lg text-primary text-xl">
          <iconGlobe :size="8" :color="primaryColor" class="w-7 my-2" />
        </div> -->
        <div
          @click="logout"
          class="flex flex-col items-center w-full rounded-lg"
        >
          <iconLogout :size="24" :color="primaryColor" class="ml-[1px]" />
        </div>
        
      </div>
    </div>
  </div>
  
  <LbrxLanguagePopup
    :active="modalLanguage.active"
    :selectedLanguage="selectedLanguage"
    @closePopupLang="closePopupLang"
    @setLanguage="setLanguage"
  />
</template>

<script>
import LbrxSideMenuIcons from "./LbrxSideMenuIcons.vue";
import LbrxLanguagePopup from "./LbrxLanguagePopup.vue";
import iconLogout from "./icons/iconLogout.vue";
import iconGlobe from "./icons/iconGlobe.vue";
import router from "@/router";
import { authService } from "@/_services";
export default {
  name: "LbrxSideMenu",
  inject: ["echo"],
  components: {
    LbrxSideMenuIcons,
    iconLogout,
    // iconGlobe,
    LbrxLanguagePopup,
  },
  data() {
    return {
      navData: [
        {
          name: this.$t("Pos.Home"),
          icon: "Home",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/home",
        },
        {
          name: this.$t("Pos.Refund"),
          icon: "Refund",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/refund",
        },
        {
          name: this.$t("Pos.Customers"),
          icon: "Customers",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/customers",
        },
        {
          name: this.$t("Pos.Tables"),
          icon: "Tables",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/tables",
        },
        {
          name: this.$t("Pos.Cashier"),
          icon: "Cashier",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/cashier",
        },
        {
          name: this.$t("Pos.Orders"),
          icon: "Orders",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/orders",
        },
        {
          name: this.$t("Pos.Reports"),
          icon: "Reports",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/reports",
        },
        {
          name: this.$t("Pos.Stock"),
          icon: "Stock",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/stock",
        },
        {
          name: this.$t("Pos.Settings"),
          icon: "Settings",
          iconSize: 20,
          iconColor: "#9f9f9e",
          isActive: true,
          navigation: "/settings",
        },

        // { name: "Settings", icon: "Settings", iconSize: 20, iconColor: "#9f9f9e", isActive: true, navigation: "/settings" },
      ],
      showLanguageSelector: false,
      user: {},
      primaryColor: JSON.parse(localStorage.getItem("themeTemplate"))
        .primaryColor,
      modalLanguage: {
        active: false,
      },
      selectedLanguage: "",
      pos: {},
      posConfig: {},
      userRole: "",
    };
  },
  props: {
    currentRoute: {
      required: true,
      value: String,
    },
    isPortrait: {
      required: true,
      value: Boolean,
    },
  },
  created() {
    if (localStorage.getItem("device")) {
      this.pos = JSON.parse(localStorage.getItem("device"));
      if (JSON.parse(localStorage.getItem("device")).config) {
        this.posConfig = JSON.parse(this.pos.config);
        this.navData[3].isActive =
          this.posConfig.posConfig.show_table == 0 ? false : true;
        // this.navData[7].isActive = this.posConfig.posConfig.show_stock == 0 ? false : true
      }
    }
  },
  computed: {
    // navDataFiltered() {
    //   return this.navData.filter((el) => el.isActive == true)
    // }
    navDataFiltered() {
      return this.navData.filter((el) => {
         if ((el.name === "Settings" || el.name === "Stock") && this.userRole !== "super_admin" && this.userRole !== "Manager" && this.userRole !== "Admin") {
          return false;
        }
        return el.isActive == true;
      });
    },
  },
  mounted() {
    this.selectedLanguage = localStorage.getItem("user_language");
    if (JSON.parse(localStorage.getItem("user"))) {
      this.user = JSON.parse(localStorage.getItem("user")).user;
      this.userRole = JSON.parse(localStorage.getItem("user")).role;
    }
  },
  methods: {
    navigatePage(link) {
      router.push(link);
    },
    logout() {
      this.echo.leaveChannel(
        "device-" +
          this.pos.id +
          "-" +
          JSON.parse(localStorage.getItem("user")).store.uid
      );
      authService
        .logout()
        .then(() => {
          router.push("/login");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          localStorage.removeItem("user");
          localStorage.removeItem("user_language");
          localStorage.removeItem("table");
          localStorage.removeItem("cart");
          localStorage.removeItem("refund_cart");
          localStorage.removeItem("sale");
          console.log("finish");
        });
    },
    showLangPopup() {
      this.modalLanguage.active = true;
    },
    setLanguage(lang) {
      localStorage.setItem("user_language", lang);
      this.$i18n.locale = localStorage.getItem("user_language");
      this.closePopupLang();
      location.reload();
    },
    closePopupLang() {
      this.modalLanguage.active = false;
    },
    getStyleDiv(item) {
      let style = "";
      if (this.currentRoute == item.icon) {
        style = "bg-primary text-[#fff]";
      }
      if (this.isPortrait) {
        style += " px-4";
      } else {
        style += " px-8";
      }
      return style;
    },
  },
};
</script>

<style scoped>
/* (A) SPEECH BOX */
.speech {
  position: absolute;
  bottom: -20px;
  /* (A1) FONT & COLORS */
  font-size: 0.7em;
  background: #fff;
  width: fit-content;
  /* (A2) DIMENSIONS */
  padding: 20px;
  border-radius: 10px;
}

/* (B) USE ::AFTER TO CREATE THE "CALLOUT" */
.speech::after {
  display: block;
  width: 0;
  content: "";
  border: 15px solid transparent;
}

/* (C) "CALLOUT" DIRECTIONS */
.speech.up::after {
  border-bottom-color: #fff;
  border-top: 0;
}

.speech.down::after {
  border-top-color: #fff;
  border-bottom: 0;
}

.speech.left::after {
  border-right-color: #fff;
  border-left: 0;
}

.speech.right::after {
  border-left-color: #fff;
  border-right: 0;
}

/* (D) POSITION THE CALLOUT */
.speech {
  position: absolute;
  left: 85px;
}

.speech::after {
  position: absolute;
}

.speech.up::after {
  top: -15px;
  left: calc(50% - 15px);
}

.speech.down::after {
  bottom: -15px;
  left: calc(50% - 15px);
}

.speech.left::after {
  left: -15px;
  top: calc(50% + 10px);
}

.speech.right::after {
  right: -15px;
  top: calc(50% - 15px);
}
</style>