<template>
    <!-- <div v-if="!loadingDrawer"> -->
    <div v-if="!drawerOpened && !loadingDrawer" class="flex flex-col justify-start h-full">
        <div class="mt-8 mb-3">
            <span class="text-xl font-bold">
                {{ $t('Pos.InsertAmount') }}
            </span>
        </div>
        <div class="grid grid-flow-col justify-center h-[60vh]">
            <div></div>
            <div>
                <LbrxNumPad :amountToGive="openingAmount" @addAmount="addAmount" :cashierMode="true"
                    :formattedInput="true" @confirmAction="openDrawer" :posConfig="posConfig" />
            </div>
            <div></div>
        </div>
        <!-- <div>
                <button @click="openDrawer" class="text-white py-3 px-28 rounded-md"
                    :class="openingAmount.length > 3 ? 'bg-confirmation' : 'bg-gray-400'">{{ $t('Pos.OpenDrawer')
                    }}</button>
            </div> -->
    </div>
    <div v-else-if="drawerOpened && !loadingDrawer" class="overflow-y-auto h-full">
        <div class="flex my-4 items-center justify-between">
            <span class="text-xl font-bold">
                {{ $t('Pos.DrawerAmount') }}
            </span>
        </div>
        <div class="bg-white overflow-hidden flex flex-col flex-grow rounded-md py-3">
            <div class="px-4">
                <div class="bg-[#f5f5f5] flex justify-between px-2 py-5">
                    <span>{{ $t('Pos.ODAmount') }}</span>
                    <span class="font-semibold">{{ formatAmount(openingDrawerAmount, currencyCode, decimalPrecision)
                        }}</span>
                </div>
                <div class="flex justify-between px-2 py-5">
                    <span>{{ $t('Pos.CPSale') }}</span>
                    <span class="text-confirmation font-semibold">{{ formatAmount(expectedDrawerAmount -
                        openingDrawerAmount, currencyCode, decimalPrecision)
                        }}</span>
                </div>
                <div class="bg-[#f5f5f5] flex justify-between px-2 py-5">
                    <span>{{ $t('Pos.OPSale') }}</span>
                    <span class="text-confirmation font-semibold">{{ formatAmount(otherPayments, currencyCode,
                        decimalPrecision)
                        }}</span>
                </div>
                <div class="flex justify-between px-2 py-5">
                    <span>{{ $t('Pos.EDAmount') }}</span>
                    <span class="text-confirmation font-semibold">{{ formatAmount(expectedDrawerAmount, currencyCode,
                        decimalPrecision)
                        }}</span>
                </div>
            </div>
            <div class="bg-gray-200 h-[1px]"></div>
            <div class="flex justify-between px-6 pt-4 pb-2">
                <span class="text-lg font-semibold">{{ $t('Pos.Difference') }}</span>
                <span class="font-semibold">{{ formatAmount(otherPayments + (expectedDrawerAmount -
                    openingDrawerAmount), currencyCode, decimalPrecision) }}</span>
            </div>
        </div>
        <div class="flex my-4 items-center justify-between">
            <span class="text-xl font-bold">
                {{ $t('Pos.Remarks') }}
            </span>
        </div>
        <div>
            <a-textarea v-model:value="cashierNote" :placeholder="$t('Pos.ClosureNote')" :rows="3" />
        </div>
        <div class="py-4 flex">
            <button @click="updateCashDrawer" class="bg-confirmation text-white py-3 px-20 rounded-md">{{
                $t('Pos.CloseDrawer') }}</button>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import LbrxNumPad from "@/components/LbrxNumPad.vue"
import { notification } from 'ant-design-vue';
import moment from "moment/moment";
import { cashDrawerService, cartService } from "@/_services";
import { currency } from "@/_helpers";
import router from "@/router";
export default {
    name: "LbrxCashDrawer",
    components: {
        LbrxNumPad
    },
    data() {
        return {
            currentPage: 1,
            cashierNote: "",
            drawerOpened: false,
            openingAmount: "",
            openingDrawerAmount: 0,
            closingDrawerAmount: 0,
            otherPayments: 0,
            expectedDrawerAmount: 0,
            cashDrawerObject: {},
            loadingDrawer: true,
        };
    },
    props: {
        cashDrawer: {
            required: true,
            value: Object
        },
        posConfig: {
            required: true,
            value: Object
        },
        totalItems: {
            required: true,
            value: Number
        },
        perPage: {
            required: true,
            value: Number
        },
        currencyCode: {
            required: false,
            value: String
        },
        decimalPrecision: {
            required: false,
            value: Number,
            default: 3
        }
    },
    computed: {
        openingAmountFormat() {
            return this.openingAmount / currency.getPrecisionInUnit(this.decimalPrecision);
        },
    },
    mounted() {
        this.getDrawerInfo();
    },
    methods: {
        formatAmount(amount, currencyCode, decimalPrecision) {
            return currency.formatAmount(amount, currencyCode, decimalPrecision);
        },
        selectOrder(item) {
            this.$emit("selectOrder", item)
        },
        formatDate(date) {
            return moment(date).format('MMMM Do YYYY, HH:mm:ss')
        },
        selectSale(item) {
            this.$emit("selectSale", item)
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.$emit("handlePageChange", page);
        },
        addAmount(item) {
            if (item === -1) {
                this.openingAmount = "0";
            } else if (item === -2) {
                this.openingAmount = this.openingAmount.substring(0, this.openingAmount.length - 1);
            } else {
                this.openingAmount += item;
            }
        },
        openDrawer() {
            if (parseFloat(this.openingAmountFormat) > 0) {
                let payload = {
                    user_id: JSON.parse(localStorage.getItem("user")).user.id,
                    opening_amount: this.openingAmountFormat
                }
                cashDrawerService.create(payload).then((res) => {
                    this.drawerOpened = true;
                    this.showNotification(this.$t('Pos.DrawerOpened'));
                    this.getDrawerInfo();
                }).catch((error) => {
                    console.log("error api : ", error);
                    this.showErrorNotification(this.$t('Pos.ErrorTry'));
                }).finally(() => {
                    // 
                });
            }
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        getDrawerInfo() {
            this.loadingDrawer = true;
            cashDrawerService.open().then((res) => {
                if (res.data.status && res.data.status == "open") {
                    this.cashDrawerObject = res.data;
                    this.drawerOpened = true;
                    this.openingDrawerAmount = res.data.opening_amount;
                    this.closingDrawerAmount = res.data.closing_amount;
                    this.otherPayments = res.data.total_other_methods;
                    this.expectedDrawerAmount = res.data.total_cash;
                    this.$emit("setCashDrawer", res.data)
                }
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.loadingDrawer = false;
            });
        },
        updateCashDrawer() {
            let payload = {
                note: this.cashierNote
            }
            this.showLoader = true;
            cartService.getAll('?pagination=off').then((res) => {
                // if (res.data.length == 0) {
                    cashDrawerService.update(this.cashDrawerObject.id, payload).then((res) => {
                        this.drawerOpened = false;
                        this.openingAmount = "";
                        this.showNotification(this.$t('Pos.DrawerClosed'));
                    }).catch((error) => {
                        console.log("error api : ", error);
                    });
                // } else {
                //     localStorage.setItem("order_page", "carts")
                //     router.push('/orders');
                //     this.showErrorNotification(this.$t('Pos.CheckCartOnhold'))
                // }
            }).catch((error) => {
                console.log("error api : ", error);
            }).finally(() => {
                this.showLoader = false;
            });
        }
    }
};
</script>

<style></style>