<template>
    <div class="relative flex flex-col h-full w-full">
        <!--search card-->
        <LbrxLoaderBlur v-if="showLoader" />
        <div class="w-3/5 mb-5 mx-4">
            <LbrxFilterBar v-if="tableLocations.length != 1" :filters="tableLocations" :selectedFilter="selectedFilter"
                @selectFilter="selectFilter" />
        </div>

        <div class="flex flex-wrap content-start flex-grow overflow-auto" :class="isPortrait ? 'mb-40 px-3' : 'px-4'">
            <template v-if="filteredTables.length === 0">
                <div class="col-span-4 text-center p-4">
                    {{ loadingP ? $t('Pos.LoadingTables') : $t('Pos.NoTables') }}
                </div>
            </template>

            <template v-else>
                <div v-for="(table, index) in filteredTables" :key="index">
                    <LbrxTableCard :table="table" @table-clicked="handleTableClick" :currentCart="currentCart"
                        :cartCreated="cartCreated" :selectedTable="selectedTable" :displayConfig="deviceConfig"  />
                </div>
            </template>

        </div>
        <div :class="isPortrait ? 'fixed bottom-0 w-full' : 'w-full'">
            <div class="bg-white py-4 px-4">
                <div class="flex flex-row justify-between">
                    <div class="w-full md:w-4/6 lg:w-2/3 flex space-x-2">
                        <div class="flex items-center space-x-2">
                            <a-avatar size="small" class="occupied"></a-avatar>
                            <p class="font-semibold">{{ $t('Pos.Occupied') }}</p>
                        </div>
                        <div class="flex items-center space-x-2">
                            <a-avatar size="small" class="onHold"></a-avatar>
                            <p class="font-semibold">{{ $t('Pos.OnHold') }}</p>
                        </div>
                        <div class="flex items-center space-x-2">
                            <a-avatar size="small" class="vacant"></a-avatar>
                            <p class="font-semibold">{{ $t('Pos.Vacant') }}</p>
                        </div>
                        <div class="flex items-center space-x-2">
                            <a-avatar size="small" class="reserved"></a-avatar>
                            <p class="font-semibold">{{ $t('Pos.Reserved') }}</p>
                        </div>
                        <!-- <div class="flex items-center">
                            <button @click="showReservePopup" class="border-2 flex items-center border-primary text-primary px-3 py-3 rounded-md">{{ $t('Pos.ReserveTable') }}</button>
                        </div> -->
                    </div>
                    <div class="place-order px-5 flex flex-row gap-8 text-nowrap items-center">
                        <div v-if="selectedTable.instantStatus">
                            <p class="table-title">{{ $t('Pos.Table') }} {{ selectedTable.number }}</p>
                            <p v-if="cartCreated" class="text-grey whitespace-nowrap">{{ $t('Pos.Cart', {
                                value:
                                    currentCart.id
                            }) }}</p>
                        </div>

                        <div>

                            <div class="flex gap-x-3" v-if="selectedTable.cart">
                                <a-button class="order-btn py-6 flex text-primary items-center border-primary"
                                    :disabled="!selectedTable.instantStatus" @click="addProducts">

                                    <template #icon>
                                        <PlusOutlined />
                                    </template>
                                    {{ $t('Pos.AddProducts') }}
                                </a-button>
                                <a-button
                                    class="order-btn bg-confirmation text-white py-6 flex items-center border-none"
                                    :style="!selectedTable.instantStatus ? 'color: #888888' : 'color: #fff'"
                                    :disabled="!selectedTable.instantStatus" @click="proceedOrder">

                                    <template #icon>
                                        <ShoppingOutlined />
                                    </template>
                                    {{ $t('Pos.ProceedPayment') }}
                                </a-button>
                            </div>
                            <a-button v-else class="order-btn bg-primary py-6 flex items-center border-none"
                                :style="!selectedTable.instantStatus ? 'color: #888888' : 'color: #fff'"
                                :disabled="!selectedTable.instantStatus" @click="handleClickOrder">

                                <template #icon>
                                    <ShoppingOutlined />
                                </template>
                                {{ $t('Pos.ChooseTable') }}
                            </a-button>
                        </div>
                        <!-- <div v-if="selectedTableStatus == 'occupied'" class="flex gap-4">
                            <a-button class="text-primary border-primary font-semibold border-2 h-full flex items-center"><template #icon>
                                    <ShoppingOutlined />
                                </template>{{ $t('Pos.AddProducts') }}</a-button>
                            <a-button class="order-btn bg-confirmation py-6 flex items-center border-none" @click="proceedPayment">
                                <template #icon>
                                    <ShoppingOutlined />
                                </template>
                                {{ $t('Pos.ProceedPayment') }}
                            </a-button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <LbrxReservePopup :active="modalReserve.active" :selectedTable="selectedTable" @closePopup="closePopup" />
        <LbrxInfoPopup :active="modalInfo.active" @closePopup="closePopup"
            :message="$t('Pos.ChangeTableOrder', { value: currentCart.id })" :type="'table'" @assignOrder="assignOrder"
            @placeOrder="placeOrder" />
    </div>
</template>

<script>
import { computed } from 'vue';
import LbrxTableCard from '@/components/LbrxTableCard.vue';
import LbrxFilterBar from "@/components/LbrxFilterBar.vue"
import LbrxReservePopup from "@/components/LbrxReservePopup.vue"
import { ShoppingOutlined, PlusOutlined } from '@ant-design/icons-vue';
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue"
import { defineComponent, ref } from 'vue';
import { notification } from 'ant-design-vue';
import router from "@/router";
import { tableService, tableLocationService, cartService, saleService, customerService } from '@/_services';
import LbrxInfoPopup from '@/components/LbrxInfoPopup.vue';

export default defineComponent({
    name: "tableIndex",
    components: {
        LbrxTableCard,
        ShoppingOutlined,
        LbrxFilterBar,
        LbrxReservePopup,
        PlusOutlined,
        LbrxLoaderBlur,
        LbrxInfoPopup
    },
    data() {
        return {
            selectedTable: {},
            selectedFilter: {},
            modalReserve: {
                active: false
            },
            modalInfo: {
                active: false
            },
            tables: [],
            tableLocations: [],
            showLoader: false,
            loadingP: true,
            contentList: {
                all: 'content1',
                vacant: 'content2',
                occupied: 'Content3',
                onHold: 'Content4'
            },
            cartCreated: false,
            currentCart: {},
            pos: {},
            posConfig: {},
            isPortrait: window.matchMedia("(orientation: portrait)").matches,
            deviceConfig:""
        };
    },
    created() {
        if (localStorage.getItem('device')) {
            this.pos = JSON.parse(localStorage.getItem('device'));
            if (JSON.parse(localStorage.getItem("device")).config) {
                this.posConfig = JSON.parse(this.pos.config);
                console.log("device config", this.posConfig)
                this.deviceConfig = this.posConfig.posConfig.table_item_display ? this.posConfig.posConfig.table_item_display : "";
            }
        }
    },
    mounted() {
        this.getCartInfo();
        this.getTableLocations();
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    computed: {
        filteredTables() {
            return this.tables.map((el) => {
                return {
                    ...el,
                    color: el.instantStatus == "available" ? "#09AA29" : el.instantStatus == "occupied" ? "#FF0033" : el.instantStatus == "reserved" ? "#FE7A36" : "#1891EF",
                    image: el.seating_capacity <= 4 ? "img/round_table.png" : "img/table_6_seats.png"
                }
            });
        },
        selectedTableOccup() {
            return this.selectedTable.instantStatus == "occupied";
        }
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        },
        selectFilter(item) {
            this.selectedFilter = item;
            this.tables = []
            this.getTablesByCategory("table_location_id", this.selectedFilter.id);
        },
        getCartInfo() {
            if (JSON.parse(localStorage.getItem("cart"))) {
                cartService.show(JSON.parse(localStorage.getItem("cart")).id).then((res) => {
                    this.currentCart = res.data;
                    this.cartCreated = true;
                }).catch((error) => {
                    this.cartCreated = false;
                }).finally(() => {
                    // this.selectedTable = table;
                });
            } else {
                this.cartCreated = false;
            }
        },
        handleTableClick(table) {
            // if (JSON.parse(localStorage.getItem("cart"))) {
            //     cartService.show(JSON.parse(localStorage.getItem("cart")).id).then((res) => {
            //         this.currentCart = res.data;
            //         this.cartCreated = true;
            //     }).catch((error) => {
            //         this.cartCreated = false;
            //     }).finally(() => {
            //         this.selectedTable = table;
            //     });
            // } else {
            //     this.cartCreated = false;
            this.selectedTable = table;
            // }
        },
        proceedOrder() {
            this.showLoader = true;
            cartService.show(this.selectedTable.cart.id).then((res) => {
                const cart = res.data;

                if (this.posConfig.posConfig.generate_sale == 1) {
                    localStorage.setItem("cart_info", JSON.stringify(cart));

                    // localStorage.removeItem("cart");

                    // this.showNotification(this.$t('Pos.ProceedSaleSuccess'));
                    if (this.posConfig.posConfig.redirect_after_cart && this.posConfig.posConfig.redirect_after_cart.length != 0) {
                        router.push(this.posConfig.posConfig.redirect_after_cart)
                    } else {
                        router.push('/checkout')
                    }
                } else {

                    let payload = {
                        cart_id: cart.id.toString(),
                        device_id: this.pos.id,
                        user_id: JSON.parse(localStorage.getItem("user")).user.id
                    }
                    saleService.proceedSale(payload).then((res) => {
                        // localStorage.removeItem("cart");
                        
                        localStorage.setItem("sale", JSON.stringify(res.sale));
                        this.showNotification(this.$t('Pos.ProceedSaleSuccess'));
                        if (this.posConfig.posConfig.redirect_after_cart && this.posConfig.posConfig.redirect_after_cart.length != 0) {
                            router.push(this.posConfig.posConfig.redirect_after_cart)
                        } else {
                            router.push('/checkout')
                        }
                    }).catch((error) => {
                        console.log("error api : ", error);
                    }).finally(() => {
                        this.showLoader = true;
                    });
                }

            }).catch((error) => {
                console.log("error api :", error);
            }).finally(() => {
                this.showLoader = true;
            });

        },
        updateTableStorage() {
            localStorage.setItem("table", JSON.stringify(this.selectedTable));
            window.dispatchEvent(new CustomEvent('table-localstorage-changed', {
                detail: {
                    storage: JSON.parse(localStorage.getItem('table'))
                }
            }));
        },
        handleClickOrder() {
            if (this.cartCreated)
                this.modalInfo.active = true;
            else
                this.placeOrder();
        },
        placeOrder() {
            let payload = {
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                table_id: this.selectedTable.id,
                device_id: this.pos.id
            }
            cartService.create(payload).then((res) => {
                localStorage.setItem("cart", JSON.stringify(res.data))
                this.updateTableStorage();
                this.getCartInfo();
                router.push("/home");
            }).catch((error) => {
                console.log("error api : ", error);
            });
        },
        assignOrder() {
            let payload = {
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                table_id: this.selectedTable.id,
                is_takeaway: 0,
            }
            cartService.update(JSON.parse(localStorage.getItem("cart")).id, payload).then((res) => {
                localStorage.setItem("cart", JSON.stringify(res.data))
                this.showNotification("Assigned table to cart");
                this.updateTableStorage();
                this.getCartInfo();
                router.push("/home");
            }).catch((error) => {
                this.showErrorNotification(error);
            }).finally(() => {
                //
            });
        },
        getTables() {
            tableService.getAll("table_location_id", this.selectedFilter.id).then((response) => {
                console.log("getting products", response.data);
                this.tables = response.data;
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        getTablesByCategory(key, selectedFilter) {
            this.loadingP = true;
            tableService.getAll(key, selectedFilter).then((response) => {
                console.log("getting products", response.data);
                this.tables = response.data;
            }).catch((error) => {
                console.log("error api : get products", error);
            }).finally(() => {
                this.loadingP = false;
            });
        },
        getTableLocations() {
            tableLocationService.getAll().then((response) => {
                console.log("getting products", response.data);
                this.tableLocations = response.data;
                if (this.posConfig.posConfig.defaultTableFilter) {
                    this.selectedFilter.id = this.posConfig.posConfig.defaultTableFilter;
                } else {
                    this.selectedFilter = this.tableLocations[0];
                }
                this.getTablesByCategory("table_location_id", this.selectedFilter.id);
            }).catch((error) => {
                console.log("error api : get products", error);
            });
        },
        showErrorNotification(message) {
            notification.error({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    backgroundColor: '#FFBFC98A',
                    color: 'white'

                }
            });
        },
        addProducts() {
            this.showLoader = true;
            cartService.show(this.selectedTable.cart.id).then((res) => {
                localStorage.setItem("cart", JSON.stringify(res.data))
            }).catch((error) => {
                console.log("error api :", error);
            }).finally(() => {
                // this.loadingP = false;
            });

            localStorage.setItem("table", JSON.stringify(this.selectedTable))
            window.dispatchEvent(new CustomEvent('table-localstorage-changed', {
                detail: {
                    storage: JSON.parse(localStorage.getItem('table'))
                }
            }));
            if (this.selectedTable.cart.customer_id) {
                customerService.getById(this.selectedTable.cart.customer_id).then((res) => {
                    localStorage.setItem("customer", JSON.stringify(res.data))
                }).catch((error) => {
                    console.log("error api :", error);
                }).finally(() => {
                    this.loadingP = false;
                });

            }
            setTimeout(() => {
                this.showLoader = false;
                router.push("/home");
            }, 1300);
        },
        showNotification(message) {
            notification.success({
                message: message,
                duration: 3, // 3s
                top: '80px',
                style: {
                    color: 'white'
                }
            });
        },
        showReservePopup() {
            this.modalReserve.active = true;
        },
        closePopup() {
            this.modalReserve.active = false;
            this.modalInfo.active = false;
        }
    }
});
</script>

<style scoped>
.footer {
    display: flex;
    align-items: center;
}

.table-status {
    display: flex;
    padding-right: 10px;
}

.place-order {
    display: flex;
    justify-content: space-evenly;
}

.occupied {
    background-color: #FF0033;
}

.onHold {
    background-color: #1891EF;
}

.vacant {
    background-color: #09AA29;
}

.reserved {
    background-color: #FE7A36;
}

.table-title {
    font-weight: 700;
    font-size: 16px;
}

.text-grey {
    color: #718096;
    font-weight: 400;
    font-size: 15px;
}

.order-btn {
    transition: transform 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.order-btn:hover {
    transform: translateY(-5px);
}
</style>