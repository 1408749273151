<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[370px] my-52 rounded-3xl overflow-hidden h-fit flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex flex-col flex-grow items-center h-full mx-10 my-9 gap-6">
        <iconAlert :size="90" :color="getSelectedTypeStyleIcon" class="w-56" alt="" />
        <div v-if="type == 'message'" class="flex flex-col gap-4">
          <span>{{ message }}</span>
          <button @click="closePopup"
            class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.Return') }}</button>
        </div>
        <div v-if="type == 'qty'" class="flex flex-col">
          <span>{{ $t('Pos.NoQuantity') }}</span>
          <span>{{ $t('Pos.RemainingQty') + ": " }} <span class="text-red-600">{{ remainingQty }}</span></span>
        </div>
        <div v-else-if="type == 'drawer'" class="flex flex-col gap-6">
          <span>{{ message }}</span>
          <button @click="goDrawer"
            class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.GoDrawer') }}</button>
        </div>
        <div v-else-if="type == 'offer'" class="flex flex-col gap-6">
          <span>{{ message }}</span>
          <button @click="markOffer"
            class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.MarkOffer') }}</button>
          <button @click="proceedNormal"
            class="bg-gray-400 flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.proceedNormally') }}</button>
        </div>
        <div v-else-if="type == 'table'" class="flex flex-col gap-6 w-full">
          <span>{{ message }}</span>
          <button @click="assignOrder"
            class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.ChangeTable') }}</button>
          <button @click="placeOrder"
            class="bg-gray-400 flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.PlaceNewOrder') }}</button>
        </div>
        <div v-else-if="type == 'flouci'" class="flex flex-col gap-6 w-full">
          <span>{{ message }}</span>
          <button @click="proceed"
            class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.Confirm') }}</button>
          <button @click="closePopup"
            class="bg-gray-400 flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white">{{
              $t('Pos.Cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconAlert from "./icons/iconAlert.vue"
import router from "@/router";
export default {
  name: "LbrxInfoPopup",
  components: {
    iconAlert
  },
  data() {
    return {
      activeKey: '0',
      checkedItems: [],
      quantity: ""
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    remainingQty: {
      required: false,
      value: Number
    },
    type: {
      required: false,
      value: String
    },
    message: {
      required: false,
      value: String,
      default: ""
    }
  },
  computed: {
    getSelectedTypeStyleIcon() {
      return JSON.parse(localStorage.getItem('themeTemplate')).primaryColor;
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup")
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    goDrawer() {
      router.push('/cashier');
    },
    markOffer() {
      router.push('/checkout');
    },
    proceedNormal() {
      router.push('/checkout');
    },
    assignOrder() {
      this.$emit("assignOrder")
    },
    placeOrder() {
      this.$emit("placeOrder")
    },
    proceed() {
      this.$emit("proceed")
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>