<template>
  <a-card
    :class="!loading ? 'card-container' : 'card-container-loading'"
    :loading="loading"
    :style="
      selectedTable.id == table.id ? `background-color: ${primaryLight}` : ''
    "
    @click="handleTableClick"
  >
 
    <div class="table-image-container">
      <img :src="table.image" alt="Table Image" class="table-image" />
      <div class="table-title">
        <p>{{ table.number }}</p>

        <!-- <p class="table-detail" v-if="cartCreated && currentCart.table_id == table.id">{{ "#"+currentCart.id }}</p> -->

        <!---->

        <template v-if="displayConfig">
          <p
            class="table-detail"
            v-if="
              table.cart != null && table.sale == null && displayConfig === 'id'
            "
          >
            {{ "#" + table.cart.id }}
          </p>
          <p
            class="table-detail"
            v-if="
              table.cart != null &&
              table.sale == null &&
              displayConfig === 'order_number' &&
              table.cart.order_number != null
            "
          >
            {{ "#" + table.cart.order_number }}
          </p>

          <p
            class="table-detail"
            v-if="
              table.cart !== null &&
              table.sale != null &&
              displayConfig === 'id'
            "
          >
            {{ "#" + table.sale.id }}
          </p>

          <p
            class="table-detail"
            v-if="
              table.cart == null &&
              table.sale != null &&
              displayConfig === 'order_number'
            "
          >
            {{ "#" + table.sale.order_number }}
          </p>

          <p
            class="table-detail"
            v-if="
              table.cart == null && table.sale != null && displayConfig === 'id'
            "
          >
            {{ "#" + table.sale.id }}
          </p>
          <p
            class="table-detail"
            v-if="
              table.cart != null &&
              table.sale != null &&
              displayConfig === 'order_number' &&
              table.sale.order_number != null
            "
          >
            {{ "#" + table.sale.order_number }}
          </p>
        </template>
        <template v-else>
          
            <p
            class="table-detail"
            v-if="
              table.cart != null && table.sale == null 
            "
          >
            {{ "#" + table.cart.id }}
          </p>

           <p
            class="table-detail"
            v-if="
              table.cart === null && table.sale !== null 
            "
          >
            {{ "#" + table.sale.id }}
          </p>

           
        </template>

        <p
          class="table-detail"
          v-if="table.cart && table.cart.user_id == userId"
        >
          {{ userName }}
        </p>
        <p class="table-detail" v-if="table.status == 'reserved' && table.instantReservations[0]?.reservation_time" >
          {{ formatDate(table.instantReservations[0].reservation_time) }}
        </p>
      </div>
    </div>
    <div class="table-footer" :style="{ backgroundColor: table.color }"></div>
  </a-card>
</template>

<script>
import moment from "moment/moment";
export default {
  name: "LbrxTableCard",

  components: {},
  data() {
    return {
      loading: true,
      primaryLight: JSON.parse(localStorage.getItem("themeTemplate"))
        .primaryLight,
    };
  },
  props: {
    table: {
      type: Object,
      default: Object,
    },
    currentCart: {
      type: Object,
      default: Object,
    },
    cartCreated: {
      type: Boolean,
      default: false,
    },
    selectedTable: {
      type: Boolean,
      default: Object,
    },
    displayConfig: {
      type: String,
      default: Object,
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    userName() {
      return (
        JSON.parse(localStorage.getItem("user")).user.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).user.last_name
      );
    },
    userId() {
      return JSON.parse(localStorage.getItem("user")).user.id;
    },
  },
  methods: {
    handleTableClick() {
      this.$emit("table-clicked", this.table);
    },
    formatDate(date) {
      return moment(date).lang("en-gb").format("LTS");
    },
  },
};
</script>

<style scoped>
.card-container {
  width: fit-content;
  height: fit-content;
  /* padding: 0 10px 15px 10px; */
  padding-bottom: 10px;
  margin: 4px 4px;
}

.card-container-loading {
  width: 240px;
}

.table-image-container {
  position: relative;
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
}

.table-image {
  display: block;
  width: 80px;
}

.table-title {
  position: absolute;
  top: 50%;
  /*vertical center */
  left: 50%;
  /* horizontal center */
  transform: translate(-50%, -50%);
  font-size: 19px;
  font-weight: 600;
  color: #000000d9;
  text-wrap: nowrap;
  text-align: center;
}

.table-detail {
  font-size: 14px;
  font-weight: 500;
}

.table-footer {
  height: 5px;
  width: 100%;
  height: 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 6px 6px;
}
</style>