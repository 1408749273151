<template>
  <div
    class="flex flex-col flex-grow mx-6"
    :class="isPortrait ? 'h-[40vh]' : 'w-[50%]  mb-4'"
  >
    <LbrxLoaderBlur v-if="showLoader" />
    <div class="flex justify-between">
      <a-button
        type="text"
        @click="goRoute('/home')"
        style="
          display: flex;
          align-items: center;
          width: fit-content;
          font-size: 16px;
          font-weight: 500;
        "
        class="text-primary my-4 px-2"
      >
        <template #icon>
          <LeftOutlined :style="{ fontSize: '16px' }" />
        </template>
        {{ $t("Pos.Back") }}
      </a-button>
      <!-- <button @click="toggleMutliplePayment" class="my-2 px-2 rounded-md border-2 border-primary text-primary">
                {{ $t("Pos.MultiplePay") }}
            </button> -->
    </div>
    <div class="h-full overflow-hidden">
      <LbrxOrderCheckout
        ref="orderCheckout"
        :amountToGive="amountToGive"
        :saleDetails="saleDetails"
        :sale="sale"
        :loading="loading"
        :posConfig="posConfig"
        :currencyCode="currencyCode"
        :decimalPrecision="posConfig.posConfig.decimalPrecision"
        :isPortrait="isPortrait"
        :paidAmount="paidAmount"
        :balanceArray="balanceArray"
      />
    </div>
  </div>
  <div class="flex flex-col flex-grow" :class="isPortrait ? '' : 'w-[50%]'">
    <div class="h-full overflow-hidden">
      <LbrxNumPad
        :amountToGive="amountToGive"
        @addAmount="addAmount"
        :sale="sale"
        :pos="pos"
        :paymentMethods="paymentMethods"
        :paymentMethodDefault="paymentMethodDefault"
        :otherModes="otherModes"
        :selectedMethod="selectedMethod"
        @selectMethod="selectMethod"
        :posConfig="posConfig"
        :textButtonConfirm="$t('Pos.ConfirmPayment')"
        @confirmPayment="confirmPayment"
        :formattedInput="true"
        @confirmPaymentNoCash="confirmPaymentNoCash"
        @confirmPaymentOffer="confirmPaymentOffer"
        :loading="loading"
        :currencyCode="currencyCode"
        @toggleLoading="toggleLoading"
        :balanceArray="balanceArray"
        @handlePayment="handlePayment"
        @openPopupFlouci="openPopupFlouci"
      />
    </div>
  </div>
  <LbrxMultiPayPopup :active="multiplePayment" @closePopup="closePopup" />
  <LbrxInfoPopup
    :active="modalInfo.active"
    @closePopup="closePopup"
    :type="'flouci'"
    @proceed="confirmPaymentFlouci"
    :message="$t('Pos.PayWithFlouci')"
  />
  <LbrxLoaderPopup
    :active="modalLoader.active"
    @closePopup="closePopup"
    :message="$t('Pos.FlouciProcedure')"
  />
</template>

<script>
import LbrxOrderCheckout from "@/components/LbrxOrderCheckout.vue";
import LbrxNumPad from "@/components/LbrxNumPad.vue";
import LbrxMultiPayPopup from "@/components/LbrxMultiPayPopup.vue";
import LbrxInfoPopup from "@/components/LbrxInfoPopup.vue";
import { LeftOutlined } from "@ant-design/icons-vue";
import {
  saleService,
  cartService,
  paymentService,
  paymentMethodService,
} from "@/_services";
import router from "@/router";
import { notification } from "ant-design-vue";
import { currency } from "@/_helpers";
import LbrxLoaderBlur from "@/components/LbrxLoaderBlur.vue";
import LbrxLoaderPopup from "@/components/LbrxLoaderPopup.vue";

export default {
  name: "checkoutIndex",
  components: {
    LbrxOrderCheckout,
    LeftOutlined,
    LbrxNumPad,
    LbrxMultiPayPopup,
    LbrxInfoPopup,
    LbrxLoaderBlur,
    LbrxLoaderPopup,
  },
  data() {
    return {
      modal: {
        active: false,
      },
      modalInfo: {
        active: false,
      },
      modalLoader: {
        active: false,
      },
      loading: false,
      sale: {},
      saleDetails: [],
      paymentMethods: [],
      paymentMethodDefault: {},
      otherModes: [],
      saleCustomer: "",
      selectedFilter: {},
      selectedMethod: {},
      selectedOrder: {
        key: "2",
        orderId: "#123456799",
        date: "27 Feb 2021, 12:30",
        totalSales: 40000,
        customer: {
          name: "Oussema",
        },
        purchases: [
          {
            name: "Spicy Shrimp Soup",
            price: 40000,
            description: "Half grilled",
            qty: 1,
          },
          { name: "Lablabi", price: 60000, qty: 1 },
        ],
      },
      filters: [
        { name: this.$t("Pos.OrderHistory"), type: "online" },
        { name: this.$t("Pos.OrderOnhold"), type: "onhold" },
        { name: this.$t("Pos.OrderOffline"), type: "offline" },
      ],
      amountToGive: "",
      pos: {},
      posConfig: {},
      payment: {},
      currency: {},
      currencyCode: {},
      multiplePayment: false,
      paymentArray: [],
      isPortrait: window.matchMedia("(orientation: portrait)").matches,
      intervalFlouci: null,
      qrcodeFlouci: null,
      flousiIdSession: null,
      flousiIdTransaction: null,
      showLoader: false,
    };
  },
  created() {
    if (localStorage.getItem("device")) {
      this.pos = JSON.parse(localStorage.getItem("device"));
      if (JSON.parse(localStorage.getItem("device")).config) {
        this.posConfig = JSON.parse(this.pos.config);
      }
    }
    // check if sale is after confirm payment
    if (this.posConfig.posConfig.generate_sale == 1) {
      if (JSON.parse(localStorage.getItem("cart_info"))) {
        cartService
          .show(JSON.parse(localStorage.getItem("cart_info")).id)
          .then((res) => {
            console.log("show cart ");
            this.sale = res.data;
            this.saleDetails = res.data.cart_items;
            this.sale.sale_details = res.data.cart_items;
            this.saleCustomer = res.data.customer;
            this.dispatchEvent("showCart", this.sale, this.saleDetails);
          })
          .catch((error) => {
            console.log("error api : ", error);
            // this.showErrorNotification('Error fetching cart items')
          })
          .finally(() => {
            // this.showLoaderCart = false;
          });
      }
    } else {
      if (JSON.parse(localStorage.getItem("sale"))) {
        saleService
          .getById(JSON.parse(localStorage.getItem("sale")).id)
          .then((res) => {
            this.sale = res.data;
            this.saleDetails = res.data.sale_details;
            this.saleCustomer = res.data.customer;
            this.dispatchEvent("showCart", this.sale, this.saleDetails);
          })
          .catch((error) => {
            console.log("error api : ", error);
            // this.showErrorNotification('Error fetching cart items')
          })
          .finally(() => {
            // this.showLoaderCart = false;
          });
      }
    }
    if (localStorage.getItem("currencies")) {
      let currencies = JSON.parse(localStorage.getItem("currencies"));
      this.currency = currencies.find((el) => {
        return el.is_default == 1;
      });
      if (this.currency.currency) {
        this.currencyCode = this.currency.currency.code;
      }
    }
    this.getAllPaymentMethods();
  },
  mounted() {
    this.selectedFilter = this.filters[0];
    this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
    this.orientationMediaQuery.addEventListener(
      "change",
      this.handleOrientationChange
    );
  },
  beforeUnmount() {
    this.orientationMediaQuery.removeEventListener(
      "change",
      this.handleOrientationChange
    );
    this.stopInterval();
  },
  computed: {
    amountToGiveFormat() {
      return (
        this.amountToGive / currency.getPrecisionInUnit(this.decimalPrecision)
      );
    },
    decimalPrecision() {
      return this.posConfig.posConfig.decimalPrecision
        ? this.posConfig.posConfig.decimalPrecision
        : 3;
    },
    balanceArray() {
      return (
        parseFloat(
          this.paymentArray
            .map((element) => element.amount_paid)
            .reduce((a, b) => a + b, 0)
        ) +
        this.amountToGiveFormat -
        parseFloat(this.sale.total)
      ).toFixed(this.decimalPrecision);
    },
    paidAmount() {
      return parseFloat(
        this.paymentArray
          .map((element) => element.amount_paid)
          .reduce((a, b) => a + b, 0)
      ).toFixed(this.decimalPrecision);
    },
  },
  methods: {
    dispatchEvent(event, sale, saleDetails) {
      var channel = new BroadcastChannel("cart-info-channel");
      let payload = {
        event: event,
        sale: sale,
        saleDetails: saleDetails,
      };
      channel.postMessage(JSON.stringify(payload));
      channel.close();
    },
    dispatchEventQrcode(event, qrcodeValue) {
      var channel = new BroadcastChannel("cart-info-channel");
      let payload = {
        event: event,
        qrcodeValue: qrcodeValue,
      };
      channel.postMessage(JSON.stringify(payload));
      channel.close();
    },
    handleOrientationChange(event) {
      this.isPortrait = event.matches;
    },
    toggleLoading(state) {
      this.loading = state;
    },
    addAmount(item) {
      if (item === -1) {
        this.amountToGive = "";
      } else if (item === -2) {
        this.amountToGive = this.pinCode.substring(0, this.pinCode.length - 1);
      } else {
        this.amountToGive += item;
      }
    },
    selectOrder(item) {
      this.selectedOrder = item;
    },
    findCartIndex(product) {
      return this.cart.findIndex((p) => p.productId === product.id);
    },
    findCartItem(product) {
      return this.cart.findIndex((p) => p.productId === product.productId);
    },
    selectFilter(item) {
      this.selectedFilter = item;
    },
    updateTableStorage() {
      window.dispatchEvent(
        new CustomEvent("table-localstorage-changed", {
          detail: {
            storage: {},
          },
        })
      );
    },
    separateByDefault(arr) {
      return arr.reduce(
        (result, item) => {
          if (item.is_default === 0) {
            result[0].push(item);
          } else if (item.is_default === 1) {
            result[1].push(item);
          }
          return result;
        },
        [[], []]
      );
    },
    confirmPayment() {
      console.log("confirm Payment helloooo");
      this.loading = true;
      let payload = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        amount_paid: this.amountToGiveFormat,
        payment_method_id: this.selectedMethod.id.toString(),
        notes: "",
        currency_id: JSON.parse(
          localStorage.getItem("default_currency")
        ).currency_id.toString(),
      };
      paymentService
        .create(payload)
        .then((res) => {
          this.payment = res.payment;
          // send payments object if print after payment is enabled
          if (this.posConfig.posConfig.allow_print_after_payement == 1) {
            this.$refs.orderCheckout.printTicket(res.payment, "pad");
          }
          localStorage.removeItem("cart");
          localStorage.removeItem("customer");
          localStorage.removeItem("table");
          localStorage.removeItem("sale");
          this.updateTableStorage();
          this.showNotification(this.$t("Pos.paymentSuccessful"));
          //products have no production point => home
          let hasProductionPoint = false;
          this.sale.sale_details.forEach((sale) => {
            if (
              sale.product.production_point_id !== null &&
              sale.product.production_point_id !== "null" &&
              sale.product.production_point_id !== ""
            ) {
              hasProductionPoint = true;
              return;
            }
          });
          if (
           !hasProductionPoint
          ) {
            router.push("/home");
          }
        })
        .catch((error) => {
          console.log("error api : ", error);
          // this.showErrorNotification('Error fetching cart items')
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmPaymentNoCash() {
      this.loading = true;
      let payload = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        amount_paid: this.sale.total,
        payment_method_id: this.selectedMethod.id.toString(),
        notes: "no cash",
        currency_id: JSON.parse(
          localStorage.getItem("default_currency")
        ).currency_id.toString(),
      };
      paymentService
        .create(payload)
        .then((res) => {
          localStorage.removeItem("cart");
          localStorage.removeItem("customer");
          localStorage.removeItem("table");
          localStorage.removeItem("sale");
          this.updateTableStorage();
          // send payments object if print after payment is enabled
          if (this.posConfig.posConfig.allow_print_after_payement == 1) {
            this.$refs.orderCheckout.printTicket(res.payment);
          }
          this.showNotification(this.$t("Pos.paymentSuccessful"));
          //products have no production point => home
          let hasProductionPoint = false;
          this.sale.sale_details.forEach((sale) => {
            if (
              sale.product.production_point_id !== null &&
              sale.product.production_point_id !== "null" &&
              sale.product.production_point_id !== ""
            ) {
              hasProductionPoint = true;
              return;
            }
          });
          if (
           !hasProductionPoint
          ) {
            router.push("/home");
          }
        })
        .catch((error) => {
          console.log("error api : ", error);
          this.showErrorNotification(this.$t("Pos.ErrorTry"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmPaymentOffer() {
      this.loading = true;
      saleService
        .markAsSale(JSON.parse(localStorage.getItem("sale")).id.toString())
        .then((res) => {
          if (this.posConfig.posConfig.allow_print_after_payement == 1) {
            this.$refs.orderCheckout.printTicket("offer", "pad");
          }
          localStorage.removeItem("cart");
          localStorage.removeItem("customer");
          localStorage.removeItem("table");
          localStorage.removeItem("sale");
          this.updateTableStorage();
          this.showNotification(this.$t("Pos.paymentSuccessful"));
          //products have no production point => home
          let hasProductionPoint = false;
          this.sale.sale_details.forEach((sale) => {
            if (
              sale.product.production_point_id !== null &&
              sale.product.production_point_id !== "null" &&
              sale.product.production_point_id !== ""
            ) {
              hasProductionPoint = true;
              return;
            }
          });
          if (
           !hasProductionPoint
          ) {
            router.push("/home");
          }
        })
        .catch((error) => {
          console.log("error api : ", error);
          this.showErrorNotification(this.$t("Pos.ErrorTry"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: "80px",
        style: {
          color: "white",
        },
      });
    },
    showErrorNotification(message) {
      notification.error({
        message: message,
        duration: 3, // 3s
        top: "80px",
        style: {
          backgroundColor: "#FFBFC98A",
          color: "white",
        },
      });
    },
    goRoute(route) {
      this.dispatchEvent("showAd", {}, []);
      router.push(route);
    },
    selectMethod(item) {
      this.selectedMethod = item;
    },
    getAllPaymentMethods() {
      paymentMethodService
        .getAll()
        .then((res) => {
          this.paymentMethods = res.data.map((el) => {
            return {
              ...el,
              is_default: el.method.name == "Cash" ? 1 : 0,
            };
          });
          // this.paymentMethods = this.separateByDefault(this.paymentMethods);
          const methodsFiltered = this.paymentMethods.map((el) => {
            if (el.is_default == 1) {
              return el;
            } else if (el.method.name == "Cash") {
              return el;
            }
          });
          this.otherModes = this.paymentMethods.filter(function (el) {
            return el.is_default != 1;
          });
          // insert flousi method
          // this.otherModes.unshift({ id: 4, method: { name: this.$t('Pos.Flouci') } })
          // if customer has appropriate privilege than push offer mode
          if (localStorage.getItem("customer")) {
            if (
              JSON.parse(localStorage.getItem("customer"))
                .can_get_free_orders == 1
            ) {
              // console.log('dfdfdfdxxxx')
              this.otherModes.unshift({
                id: -1,
                method: { name: this.$t("Pos.Offer") },
              });
            }
          }
          this.paymentMethodDefault = methodsFiltered.filter((obj) => obj);
          this.selectedMethod = this.paymentMethodDefault[0];
        })
        .catch((error) => {
          console.log("error api : ", error);
          // this.showErrorNotification('Error fetching cart items')
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    toggleMutliplePayment() {
      this.multiplePayment = !this.multiplePayment;
    },
    handlePayment() {
      let payment = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        amount_paid: this.amountToGiveFormat,
        payment_method_id: this.selectedMethod.id.toString(),
        notes: "",
        currency_id: JSON.parse(
          localStorage.getItem("default_currency")
        ).currency_id.toString(),
      };
      this.loading = true;
      setTimeout(() => {
        this.paymentArray.push(payment);
        this.loading = false;
      }, 1000);
    },
    closePopup() {
      this.multiplePayment = false;
      this.modalInfo.active = false;
      this.modalLoader.active = false;
    },
    openPopupFlouci() {
      this.modalInfo.active = true;
    },
    confirmPaymentFlouci() {
      this.closePopup();
      this.loading = true;
      this.showLoader = true;
      let payload = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        amount_paid: this.sale.total,
        payment_method_id: this.selectedMethod.id.toString(),
        notes: "Flouci",
        currency_id: JSON.parse(
          localStorage.getItem("default_currency")
        ).currency_id.toString(),
      };
      paymentService
        .paymentFlouci(payload)
        .then((res) => {
          this.qrcodeFlouci = res.code;
          this.flousiIdSession = res.sale.flousiIdSession;
          this.flousiIdTransaction = res.sale.flousiIdTransaction;
          this.showNotification(this.$t("Pos.TransactionSuccessful"));
          this.dispatchEventQrcode("showQrcode", this.qrcodeFlouci);
          this.startInterval();
          this.modalLoader.active = true;
        })
        .catch((error) => {
          console.log("error api : ", error);
          this.showErrorNotification(this.$t("Pos.ErrorTry"));
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    startInterval() {
      if (!this.intervalFlouci) {
        // Prevent multiple intervals
        this.intervalFlouci = setInterval(
          () => this.checkPaymentFlouci(),
          3000
        );
      }
    },
    stopInterval() {
      if (this.intervalFlouci) {
        clearInterval(this.intervalFlouci);
        this.intervalFlouci = null;
      }
    },
    checkPaymentFlouci() {
      let payload = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        payment_method_id: this.selectedMethod.id.toString(),
        flousiIdSession: this.flousiIdSession,
        flousiIdTransaction: this.flousiIdTransaction,
      };
      paymentService
        .checkFlouci(payload)
        .then((res) => {
          if (res.status == "Transaction valide") {
            this.loading = false;
            this.showNotification(this.$t("Pos.TransactionSuccessful"));
            this.stopInterval();
            this.closePopup();
            this.goRoute("/home");
          }
        })
        .catch((error) => {
          console.log("error api : ", error);
          // this.showErrorNotification(this.$t('Pos.ErrorTry'));
        })
        .finally(() => {
          // this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>