<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-20" @click="closePopup">
    <div
      class="bg-[#fff] w-[600px] pt-12 rounded-3xl overflow-hidden h-full flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex gap-3 mx-9">
        <div @click="selectType('amount')" class="p-3 bg-[#eeeeee] rounded-md"
          :style="selectedType == 'amount' ? getSelectedTypeStyle : ''">
          <iconDollar :size="20" :color="selectedType == 'amount' ? getSelectedTypeStyleIcon : '#B4B4B8'" class="w-5" alt="" />
        </div>
        <div @click="selectType('percentage')" class="p-3 bg-[#eeeeee] rounded-md"
          :style="selectedType == 'percentage' ? getSelectedTypeStyle : ''">
          <iconPercent :size="20" :color="selectedType == 'percentage' ? getSelectedTypeStyleIcon : '#B4B4B8'" class="w-5" alt="" />
        </div>
        <input v-if="selectedType == 'amount'" v-model="amountToGiveFixed" type="text"
          class="w-full text-3xl rounded-sm text-right border-2 border-gray-300 px-2 font-medium">
        <input v-else v-model="amount" type="text"
          class="w-full text-3xl rounded-sm text-right border-2 border-gray-300 px-2 font-medium">
      </div>
      <div class="flex flex-col flex-grow h-full mt-3">
        <LbrxNumPad :amountToGive="amount" @addAmount="addAmount" :cashierMode="true" :noInput="true"
          @confirmAction="confirmDiscount" :posConfig="posConfig" />
      </div>
    </div>
  </div>
</template>

<script>
import LbrxNumPad from "@/components/LbrxNumPad.vue"
import iconDollar from "./icons/iconDollar.vue"
import iconPercent from "./icons/iconPercent.vue"
import { currency } from "@/_helpers";
export default {
  name: "LbrxDiscountPopup",
  components: {
    LbrxNumPad,
    iconDollar,
    iconPercent
  },
  data() {
    return {
      activeKey: '0',
      checkedItems: [],
      amount: "",
      selectedType: "percentage"
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3
    },
    posConfig: {
      required: true,
      value: Object
    }
  },
  computed: {
    getSelectedTypeStyle() {
      return {
        backgroundColor: JSON.parse(localStorage.getItem('themeTemplate')).primaryLight,
      }
    },
    getSelectedTypeStyleIcon() {
      return JSON.parse(localStorage.getItem('themeTemplate')).primaryColor;
    },
    amountToGiveFixed() {
      return this.amount.length != 0 ? (parseFloat(this.amount) / currency.getPrecisionInUnit(this.decimalPrecision)).toFixed(this.decimalPrecision) : (0).toFixed(this.decimalPrecision);
    },
  },
  methods: {
    closePopup() {
      this.$emit("closePopup")
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    confirmDiscount() {
      if (this.amount.length != 0 && this.selectedType != 0) {
        this.$emit("confirmDiscount", this.amount, this.selectedType)
        this.amount = "";
        this.selectedType = "";
      }
    },
    addAmount(item) {
      if (item === -1) {
        this.amount = "";
      } else if (item === -2) {
        this.amount = this.pinCode.substring(0, this.pinCode.length - 1);
      } else {
        this.amount += item;
      }
    },
    selectType(type) {
      this.selectedType = type;
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>