<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[70%] my-52 rounded-3xl overflow-hidden h-full flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex h-full">
        <div class="flex flex-col flex-grow mx-6" :class="isPortrait ? 'h-[40vh]' : 'w-[50%]  mb-4'">
          <div class="flex justify-between mx-2">
            <button @click="toggleMutliplePayment" class="py-2 mt-2 px-2 text-primary text-xl">
              {{ $t("Pos.MultiplePay") }}
            </button>
          </div>
          <div class="h-full overflow-hidden">
            <LbrxOrderCheckout ref="orderCheckout" :amountToGive="amountToGive" :saleDetails="saleDetails" :sale="sale"
              :loading="loading" :posConfig="posConfig" :currencyCode="currencyCode"
              :decimalPrecision="posConfig.posConfig.decimalPrecision" :isPortrait="isPortrait"
              :multiplePayment="true" :paidAmount="paidAmount" :balanceArray="balanceArray" :showPrintReceipt="false" />
          </div>
        </div>
        <div class="flex flex-col flex-grow" :class="isPortrait ? '' : 'w-[50%]'">
          <div class="h-full overflow-hidden">
            <LbrxNumPad :amountToGive="amountToGive" @addAmount="addAmount" :sale="sale" :pos="pos"
              :paymentMethods="paymentMethods" :paymentMethodDefault="paymentMethodDefault" :otherModes="otherModes"
              :selectedMethod="selectedMethod" @selectMethod="selectMethod" :posConfig="posConfig"
              :textButtonConfirm="$t('Pos.ConfirmPayment')" @confirmPayment="confirmPayment" :formattedInput="true"
              @confirmPaymentNoCash="confirmPaymentNoCash" @confirmPaymentOffer="confirmPaymentOffer" :loading="loading"
              :currencyCode="currencyCode" @toggleLoading="toggleLoading" :multiplePayment="true"
              :balanceArray="balanceArray" @handlePayment="handlePayment" :paidAmount="paidAmount" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LbrxOrderCheckout from "@/components/LbrxOrderCheckout.vue"
import LbrxNumPad from "@/components/LbrxNumPad.vue"
// import { LeftOutlined } from '@ant-design/icons-vue';
import { saleService, cartService, paymentService, paymentMethodService } from "@/_services";
import router from "@/router";
import { notification } from 'ant-design-vue';
import { currency } from "@/_helpers";

export default {
  name: "LbrxMultiPayPopup",
  components: {
    LbrxOrderCheckout,
    LbrxNumPad
  },
  data() {
    return {
      modal: {
        active: false
      },
      loading: false,
      sale: {},
      saleDetails: [],
      paymentMethods: [],
      paymentMethodDefault: {},
      otherModes: [],
      saleCustomer: '',
      selectedFilter: {},
      selectedMethod: {},
      orders: [
        {
          key: '1',
          orderId: '#123456790',
          date: '28 Feb 2021, 12:30',
          totalSales: 25000,
          purchases: [
            { name: "Schezwan Egg Noodles", price: 25000, qty: 1 }
          ],
        },
        {
          key: '2',
          orderId: '#123456799',
          date: '27 Feb 2021, 12:30',
          totalSales: 40000,
          purchases: [
            { name: "SPicy Shrimp Soup", price: 40000, qty: 1 }
          ],
        },
      ],
      ordersOffline: [
        {
          key: '1',
          orderId: '#123456790-off001',
          date: '28 Feb 2021, 12:30',
          totalSales: 25000,
          purchases: [
            { name: "Schezwan Egg Noodles", price: 25000, qty: 1 }
          ],
        },
        {
          key: '2',
          orderId: '#123456349-off002',
          date: '27 Feb 2021, 12:30',
          totalSales: 40000,
          purchases: [
            { name: "SPicy Shrimp Soup", price: 40000, qty: 1 }
          ],
        },
      ],
      selectedOrder: {
        key: '2',
        orderId: '#123456799',
        date: '27 Feb 2021, 12:30',
        totalSales: 40000,
        customer: {
          name: "Oussema"
        },
        purchases: [
          { name: "Spicy Shrimp Soup", price: 40000, description: "Half grilled", qty: 1 },
          { name: "Lablabi", price: 60000, qty: 1 }
        ],
      },
      filters: [{ name: this.$t('Pos.OrderHistory'), type: "online" }, { name: this.$t('Pos.OrderOnhold'), type: "onhold" }, { name: this.$t('Pos.OrderOffline'), type: "offline" }],
      amountToGive: "",
      pos: {},
      posConfig: {},
      payment: {},
      currency: {},
      currencyCode: {},
      paymentArray: [],
      isPortrait: window.matchMedia("(orientation: portrait)").matches
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
  },
  created() {
    if (localStorage.getItem('device')) {
      this.pos = JSON.parse(localStorage.getItem('device'));
      if (JSON.parse(localStorage.getItem("device")).config) {
        this.posConfig = JSON.parse(this.pos.config);
      }
    }
    // check if sale is after confirm payment
    if (this.posConfig.posConfig.generate_sale == 1) {
      if (JSON.parse(localStorage.getItem("cart_info"))) {
        cartService.show(JSON.parse(localStorage.getItem("cart_info")).id).then((res) => {
          this.sale = res.data;
          this.saleDetails = res.data.cart_items;
          this.sale.sale_details = res.data.cart_items;
          this.saleCustomer = res.data.customer;
          console.log("this.sale", this.sale)
        }).catch((error) => {
          console.log("error api : ", error);
          // this.showErrorNotification('Error fetching cart items')
        }).finally(() => {
          // this.showLoaderCart = false;
        });
      }
    } else {
      if (JSON.parse(localStorage.getItem("sale"))) {
        saleService.getById(JSON.parse(localStorage.getItem("sale")).id).then((res) => {
          this.sale = res.data;
          this.saleDetails = res.data.sale_details;
          this.saleCustomer = res.data.customer;
        }).catch((error) => {
          console.log("error api : ", error);
          // this.showErrorNotification('Error fetching cart items')
        }).finally(() => {
          // this.showLoaderCart = false;
        });
      }
    }
    if (localStorage.getItem('currencies')) {
      let currencies = JSON.parse(localStorage.getItem('currencies'));
      this.currency = currencies.find(el => { return el.is_default == 1 });
      if (this.currency.currency) {
        this.currencyCode = this.currency.currency.code;
      }
    }
    this.getAllPaymentMethods();
  },
  mounted() {
    this.selectedFilter = this.filters[0];
    this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
    this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
  },
  beforeUnmount() {
    this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
  },
  computed: {
    amountToGiveFormat() {
      return this.amountToGive / currency.getPrecisionInUnit(this.posConfig.posConfig.decimalPrecision);
    },
    decimalPrecision() {
      return this.posConfig.posConfig.decimalPrecision ? this.posConfig.posConfig.decimalPrecision : 3;
    },
    balanceArray() {
      return ((parseFloat(this.paymentArray.map(element => element.amount_paid).reduce((a, b) => a + b, 0)) + this.amountToGiveFormat) - parseFloat(this.sale.total)).toFixed(this.decimalPrecision);
    },
    paidAmount() {
      return (parseFloat(this.paymentArray.map(element => element.amount_paid).reduce((a, b) => a + b, 0))).toFixed(this.decimalPrecision);
    }
  },
  methods: {
    handleOrientationChange(event) {
      this.isPortrait = event.matches;
    },
    toggleLoading(state) {
      this.loading = state;
    },
    addAmount(item) {
      if (item === -1) {
        this.amountToGive = "";
      } else if (item === -2) {
        this.amountToGive = this.pinCode.substring(0, this.pinCode.length - 1);
      } else {
        this.amountToGive += item;
      }
    },
    selectOrder(item) {
      this.selectedOrder = item;
    },
    findCartIndex(product) {
      return this.cart.findIndex((p) => p.productId === product.id);
    },
    findCartItem(product) {
      return this.cart.findIndex((p) => p.productId === product.productId);
    },
    selectFilter(item) {
      this.selectedFilter = item;
    },
    updateTableStorage() {
      window.dispatchEvent(new CustomEvent('table-localstorage-changed', {
        detail: {
          storage: {}
        }
      }));
    },
    separateByDefault(arr) {
      return arr.reduce((result, item) => {
        if (item.is_default === 0) {
          result[0].push(item);
        } else if (item.is_default === 1) {
          result[1].push(item);
        }
        return result;
      }, [[], []]);
    },
    confirmPayment() {
      this.loading = true;
      let payload = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        amount_paid: this.amountToGiveFormat,
        payment_method_id: this.selectedMethod.id.toString(),
        notes: "",
        currency_id: JSON.parse(localStorage.getItem("default_currency")).currency_id.toString()
      }
      paymentService.create(payload).then((res) => {
        this.payment = res.payment;
        // send payments object if print after payment is enabled
        if (this.posConfig.posConfig.allow_print_after_payement == 1) {
          this.$refs.orderCheckout.printTicket(res.payment);
        }
        localStorage.removeItem("cart");
        localStorage.removeItem("customer");
        localStorage.removeItem("table");
        localStorage.removeItem("sale");
        this.updateTableStorage();
        this.showNotification(this.$t('Pos.paymentSuccessful'));
        router.push('/home');
      }).catch((error) => {
        console.log("error api : ", error);
        // this.showErrorNotification('Error fetching cart items')
      }).finally(() => {
        this.loading = false;
      });
    },
    confirmPaymentNoCash() {
      this.loading = true;
      let payload = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        amount_paid: this.sale.total,
        payment_method_id: this.selectedMethod.id.toString(),
        notes: "no cash",
        currency_id: JSON.parse(localStorage.getItem("default_currency")).currency_id.toString()
      }
      paymentService.create(payload).then((res) => {
        localStorage.removeItem("cart");
        localStorage.removeItem("customer");
        localStorage.removeItem("table");
        localStorage.removeItem("sale");
        this.updateTableStorage();
        // send payments object if print after payment is enabled
        if (this.posConfig.posConfig.allow_print_after_payement == 1) {
          this.$refs.orderCheckout.printTicket(res.payment);
        }
        this.showNotification(this.$t('Pos.paymentSuccessful'));
        router.push('/home');
      }).catch((error) => {
        console.log("error api : ", error);
        this.showErrorNotification(this.$t('Pos.ErrorTry'));
      }).finally(() => {
        this.loading = false;
      });
    },
    confirmPaymentOffer() {
      this.loading = true;
      saleService.markAsSale(JSON.parse(localStorage.getItem("sale")).id.toString()).then((res) => {
        localStorage.removeItem("cart");
        localStorage.removeItem("customer");
        localStorage.removeItem("table");
        localStorage.removeItem("sale");
        this.updateTableStorage();
        this.showNotification(this.$t('Pos.paymentSuccessful'));
        router.push('/home');
      }).catch((error) => {
        console.log("error api : ", error);
        this.showErrorNotification(this.$t('Pos.ErrorTry'));
      }).finally(() => {
        this.loading = false;
      });
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          color: 'white'
        }
      });
    },
    showErrorNotification(message) {
      notification.error({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          backgroundColor: '#FFBFC98A',
          color: 'white'

        }
      });
    },
    goBack() {
      router.go(-1);
    },
    selectMethod(item) {
      this.selectedMethod = item;
    },
    getAllPaymentMethods() {
      paymentMethodService.getAll().then((res) => {
        this.paymentMethods = res.data.map((el) => {
          return {
            ...el,
            is_default: el.method.name == "Cash" ? 1 : 0
          }
        });
        // this.paymentMethods = this.separateByDefault(this.paymentMethods);
        const methodsFiltered = this.paymentMethods.map((el) => {
          if (el.is_default == 1) {
            return el;
          } else if (el.method.name == "Cash") {
            return el;
          }
        });
        this.otherModes = this.paymentMethods.filter(function (el) { return el.is_default != 1; });
        // if customer has appropriate privilege than push offer mode
        if (localStorage.getItem('customer')) {
          if (JSON.parse(localStorage.getItem('customer')).can_get_free_orders == 1) {
            console.log('dfdfdfdxxxx')
            this.otherModes.unshift({ id: -1, method: { name: this.$t('Pos.Offer') } })
          }
        }
        this.paymentMethodDefault = methodsFiltered.filter(obj => obj);
        this.selectedMethod = this.paymentMethodDefault[0];
      }).catch((error) => {
        console.log("error api : ", error);
        // this.showErrorNotification('Error fetching cart items')
      }).finally(() => {
        // this.loading = false;
      });
    },
    handlePayment() {
      let payment = {
        sale_id: JSON.parse(localStorage.getItem("sale")).id.toString(),
        amount_paid: this.amountToGiveFormat,
        payment_method_id: this.selectedMethod.id.toString(),
        notes: "",
        currency_id: JSON.parse(localStorage.getItem("default_currency")).currency_id.toString()
      }
      this.loading = true;
      setTimeout(() => {
        this.paymentArray.push(payment);
        this.loading = false;
      }, 1000);

    },
    closePopup() {
      this.$emit("closePopup");
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>