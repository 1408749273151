<template>
  <!-- <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[670px] my-52 rounded-3xl overflow-hidden h-full flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span> -->
  <a-drawer
    v-model:open="open"
    class="custom-class"
    root-class-name="root-class-name"
    :width="modalWidth"
    @close="closePopup"
  >

  <template #title >
    <p class="text-xl">{{$t('Pos.customizeProduct')}}</p>
  </template>
   
    <div class="flex flex-col flex-grow h-full mx-2 mt-2">
      <div class="flex justify-between items-start">
        <img class="w-40 h-40" :src="selectedItem.photo" alt="" />
        <div class="flex flex-col justify-center flex-grow my-6 mx-5">
          <div class="flex justify-between items-baseline">
            <span class="text-xl font-bold">{{ selectedItem.name }}</span>
            <span class="text-lg font-semibold">{{
              totalAmount + " " + currencyCode
            }}</span>
          </div>
          <div class="flex flex-wrap gap-x-4">
            <span class="text-nowrap text-sm">{{
              selectedItem.description
            }}</span>
          </div>
          <div class="flex flex-wrap gap-x-1">
            <div
              v-for="(item, index) in descriptionItems"
              :key="index"
              class="my-1"
            >
              <span class="text-sm">{{ item.name }}</span
              ><span v-if="index < descriptionItems.length - 1">, </span>
            </div>
          </div>
          <div class="flex my-2 gap-x-2 gap-y-2 flex-wrap">
            <div
              v-for="(item, index) in preferencesChecked"
              :key="index"
              class="my-1"
            >
              <span
                class="border-2 border-primary rounded-lg pb-[6px] pt-1 px-3 my-4 text-primary text-center items-center cursor-pointer"
                >{{ item.preference }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="flex mb-2 gap-x-2 gap-y-2 flex-wrap">
          <div v-for="(item, index) in checkedItems" :key="index" class="my-1">
            <span class="border-2 border-primary rounded-2xl pb-[6px] pt-1 px-3 my-4 text-primary text-center items-center cursor-pointer">{{
                item.name }} {{ item.qty ? "x" + item.qty : "" }}</span>
          </div>
        </div> -->

      <div class="flex flex-col items-start mt-2 mb-3 overflow-auto flex-grow">
        <div class="w-full">
          <a-collapse v-model:activeKey="activeKey" accordion>

            <!-- ingredients panel -->
            <a-collapse-panel
              v-for="(item, index) in selectedItem.ingredients"
              :key="index"
              :header="index"
              :class="findPrefArrayIndex(index) == -1 ? 'hidden' : ''"
              class="bg-primary-light custom-collapse"
            >
              <div class="flex flex-col gap-x-3 gap-y-2 flex-wrap">
                <div v-for="(itemIng, indexParent) in item" :key="indexParent">
                  <div
                    v-if="itemIng.included_by_default == 0"
                    class="flex justify-between my-1"
                  >
                    <span class="font-semibold">{{
                      itemIng.ingredient.name
                    }}</span>
                    <div
                      v-if="itemIng.ingredient.has_price == 0"
                      class="flex justify-center items-center"
                    >
                      <button
                        @click="selectItem(itemIng.ingredient)"
                        class="text-lg leading-3 text-white px-[9px] pt-2 pb-[10px] rounded-md"
                        :class="
                          findItemIndex(itemIng.ingredient) != -1
                            ? 'bg-[#09aa29]'
                            : 'bg-primary'
                        "
                      >
                        <span
                          class="w-12"
                          v-if="findItemIndex(itemIng.ingredient) == -1"
                          >+</span
                        ><img
                          v-else
                          src="../assets/icons/tick.svg"
                          class="w-3"
                          alt=""
                        />
                      </button>
                    </div>
                    <div v-else class="flex justify-center items-center gap-3">
                      <span class="font-semibold">{{
                        "+" +
                        calculateAmountIngr(
                          parseFloat(itemIng.ingredient.default_price),
                          itemIng.ingredient.qty
                        ) +
                        " " +
                        currencyCode +
                        " "
                      }}</span>
                      <button
                        v-if="findItemIndex(itemIng.ingredient) != -1"
                        @click="removeIngredient(itemIng.ingredient)"
                        class="text-lg leading-3 text-white px-[9px] pt-2 pb-[10px] rounded-md"
                        :class="
                          findItemIndex(itemIng.ingredient) != -1
                            ? 'bg-[#09aa29]'
                            : 'bg-primary'
                        "
                      >
                        <iconMinus :size="12" :color="'#fff'" />
                      </button>
                      <span
                        v-if="findItemIndex(itemIng.ingredient) != -1"
                        class="font-semibold"
                        >{{ itemIng.ingredient.qty }}</span
                      >
                      <button
                        @click="selectItemQty(itemIng.ingredient)"
                        class="text-lg leading-3 text-white px-[9px] pt-2 pb-[10px] rounded-md"
                        :class="
                          findItemIndex(itemIng.ingredient) != -1
                            ? 'bg-[#09aa29]'
                            : 'bg-primary'
                        "
                      >
                        <span class="w-12">+</span>
                      </button>
                    </div>
                  </div>
                  <!-- <div v-if="itemIng.ingredient.preferences.length != 0">
                      <div v-for="(itemPr, index) in itemIng.ingredient.preferences" :key="index">
                        <div class="flex justify-between my-1">
                          <span class="font-semibold">{{
                            itemPr.preference }}</span>
                          <div class="flex justify-center items-center">
                            <button @click="selectPrefItem(itemPr)"
                              class="text-lg leading-3 text-white px-[9px] pt-2 pb-[10px] rounded-full"
                              :class="findPrefItemIndex(itemPr) != -1 ? 'bg-[#09aa29]' : 'bg-primary'"><span
                                class="w-12" v-if="findPrefItemIndex(itemPr) == -1">+</span><img v-else
                                src="../assets/icons/tick.svg" class="w-3" alt=""></button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                </div>
              </div>
              <!--  -->
            </a-collapse-panel>
            <!-- preferences panel -->
            <a-collapse-panel
              v-if="preferences.length > 0"
              :header="$t('Pos.Preferences')"
              class="bg-primary-light custom-collapse"
            >
              <div class="flex flex-col gap-x-3 gap-y-2 flex-wrap">
                <div v-for="(item, index) in preferences" :key="index">
                  <div class="flex justify-between my-1">
                    <span class="font-semibold">{{ item.preference }}</span>
                    <div class="flex justify-center items-center">
                      <button
                        @click="selectPrefItem(item)"
                        class="text-lg leading-3 text-white px-[9px] pt-2 pb-[10px] rounded-md"
                        :class="
                          findPrefItemIndex(item) != -1
                            ? 'bg-[#09aa29]'
                            : 'bg-primary'
                        "
                      >
                        <span class="w-12" v-if="findPrefItemIndex(item) == -1"
                          >+</span
                        ><img
                          v-else
                          src="../assets/icons/tick.svg"
                          class="w-3"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </a-collapse-panel>
            <!-- <a-collapse-panel :key="index" header="Choose your ingrediants" class="bg-[#fff2e8]">
                  <div class="flex gap-x-3 flex-wrap">
                    <div v-for="(item, index) in selectedItem.ingredients" :key="index" class="flex justify-between cursor-pointer my-1" @click="selectItem(item)">
                      <span class="border-2 border-primary rounded-2xl py-1 px-2" :class="findItemIndex(item) != -1 ? 'bg-primary text-white' : 'text-primary'">{{ item }}</span>
                    </div>
                  </div>
                </a-collapse-panel>
                <a-collapse-panel :key="index" header="Would you like any extras?" class="bg-[#fff2e8]">
                  <div class="flex gap-x-3 flex-wrap">
                    <div v-for="(item, index) in selectedItem.extras" :key="index" class="flex justify-between cursor-pointer my-1" @click="selectItem(item)">
                      <span class="border-2 border-primary rounded-2xl py-1 px-2" :class="findItemIndex(item) != -1 ? 'bg-primary text-white' : 'text-primary'">{{ item }}</span>
                    </div>
                  </div>
                </a-collapse-panel> -->
          </a-collapse>
        </div>
      </div>

      <div>
        <button
          class="w-full py-3 bg-confirmation rounded-lg my-2 text-white text-xl font-semibold"
          @click="confirmItem"
        >
          {{ $t("Pos.Confirm") }}
        </button>
      </div>
    </div>
  </a-drawer>

  <!-- </div>
  </div> -->
</template>

<script>
import { notificationPlay } from "../_helpers";
import moment from "moment/moment";
import iconMinus from "./icons/iconMinus.vue";
export default {
  name: "LbrxCustomizePopup",
  components: {
    iconMinus,
  },
 
  data() {
    return {
      open: this.active,
      modalWidth: 520,
      activeKey: "0",
      checkedItems: [],
      descriptionItems: [],
      choicesItems: [],
      preferences: [],
      preferencesChecked: [],
      collapseHidden: [],
      collapseArray: [],
    };
  },
  props: {
    selectedItem: {
      required: true,
      value: Object,
    },
    active: {
      required: true,
      value: Boolean,
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3,
    },
    currencyCode: {
      required: false,
      value: String,
    },
  },
  watch: {
    active(newValue) {
      this.open = newValue;
    },
    open(newValue) {
      this.$emit('update:active', newValue);
    }
  },
  computed: {
    productPriceFormat() {
      return this.selectedItem.price.toFixed(this.decimalPrecision);
    },
    additionalIngrediants() {
      return this.checkedItems.map((el) => {
        return {
          id: el.id,
          quantity: el.qty,
        };
      });
    },
    additionalPreferences() {
      return this.preferencesChecked.map((el) => {
        return el.id;
      });
    },
    totalAmount() {
      console.log("ererere", this.checkedItems);
      return parseFloat(
        this.checkedItems
          .map((o) => parseFloat(o.default_price) * o.qty)
          .reduce((a, c) => {
            return a + c;
          }, this.selectedItem.price)
      ).toFixed(this.decimalPrecision);
    },
  },
  mounted() {
    this.updateModalWidth();
    window.addEventListener('resize', this.updateModalWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateModalWidth);
  },
  methods: {
    updateModalWidth() {
      this.modalWidth = window.innerWidth <= 1200 ? 720 : window.innerWidth;
    },
    closePopup() {
      this.reset();
      this.$emit("closePopup");
    },
    reset() {
      this.checkedItems = [];
      this.descriptionItems = [];
      this.preferences = [];
      this.preferencesChecked = [];
      this.collapseArray = [];
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    confirmItem() {
      this.$emit(
        "confirmItem",
        this.additionalIngrediants,
        this.additionalPreferences
      );
      this.closePopup();
    },
    // selectItem(item) {
    //   console.log('dfdsfdsf', item)
    //   if (item.qty)
    //     item.qty += 1;
    //   else {
    //     item.qty = 1;
    //     this.checkedItems.push(item);
    //   }
    // },
    selectItemQty(item) {
      const currentItem = item;
      const index = this.findItemIndex(currentItem);
      if (index === -1) {
        currentItem.qty = 1;
        this.checkedItems.push(item);
      } else {
        this.checkedItems[index].qty += 1;
      }
    },
    selectItem(item) {
      const currentItem = item;
      const index = this.findItemIndex(currentItem);
      if (index === -1) {
        currentItem.qty = 1;
        this.checkedItems.push(item);
      } else {
        this.checkedItems = this.checkedItems.filter(function (el) {
          return el !== item;
        });
      }
    },
    selectPrefItem(item) {
      console.log("select preference ite", item);
      const currentItem = item;
      const index = this.findPrefItemIndex(currentItem);
      if (index === -1) {
        currentItem.qty = 1;
        this.preferencesChecked.push(item);
      } else {
        this.preferencesChecked = this.preferencesChecked.filter(function (el) {
          return el !== item;
        });
      }
      console.log("this.preferences", this.preferencesChecked);
    },
    findItemIndex(item) {
      return this.checkedItems.findIndex((el) => el === item);
    },
    findPrefItemIndex(item) {
      return this.preferencesChecked.findIndex((el) => el === item);
    },
    findPrefArrayIndex(item) {
      return this.collapseArray.findIndex((el) => el === item);
    },
    assignDefaultIngr(itemDefault, itemNotDefault) {
      this.checkedItems = itemDefault;
      this.descriptionItems = [...itemDefault];
      this.choicesItems = itemNotDefault;
      // filter out name category and remove duplicates
      this.collapseArray = this.choicesItems.map((el) => {
        return el.category.name;
      });
      this.collapseArray = [...new Set(this.collapseArray)];
      console.log(this.checkedItems);
    },
    assignPreferences(item) {
      this.preferences = item;
    },
    removeIngredient(item) {
      if (item.qty > 1) {
        item.qty -= 1;
      } else {
        this.checkedItems = this.checkedItems.filter(function (el) {
          return el !== item;
        });
      }
    },
    calculateAmountIngr(a, b) {
      if (b == 0) return a.toFixed(this.decimalPrecision);
      else return (a * b).toFixed(this.decimalPrecision);
    },
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  transform: scale(1.5);
}

.custom-collapse {
  background-color: transparent;
  border: none;
}

.custom-collapse :deep(.ant-collapse-item) {
  border: none;
  margin-bottom: 8px;
}

.custom-collapse :deep(.ant-collapse-header) {
  border-radius: 8px !important;
  background-color: #fff; 
  padding: 12px 16px;
  border-bottom:1px solid #f0f0f0 ;
}

.custom-collapse :deep(.ant-collapse-content) {
  border-radius: 0 0 8px 8px;
  border: 1px solid #f0f0f0; 
  border-top: none;
}

.custom-collapse :deep(.ant-collapse-arrow) {
  right: 16px;
}

/* for the active panel */
.custom-collapse :deep(.ant-collapse-item-active .ant-collapse-header) {
  border-radius: 8px 8px 0 0 !important;
}
</style>