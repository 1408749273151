<template>
    <div class="relative flex flex-col h-screen w-full bg-[#ebeef0]">
        <img v-if="typeDisplay == 'displayAd'" :src="secondScreenBg" class="object-fill h-screen" alt="">
        <div v-else class="flex">
            <div class="bg-white w-[50%] h-full">
               
                <LbrxOrderCheckout v-if="!showQrcode" ref="orderCheckout" :amountToGive="0" :saleDetails="saleDetails"
                    :sale="sale" :loading="false" :posConfig="posConfig" :currencyCode="currencyCode"
                    :decimalPrecision="posConfig.posConfig.decimalPrecision" :isPortrait="isPortrait"
                    :showAmount="false" :showImage="true" :showPrintReceipt="false" />
                <div v-else
                    class="relative flex items-center justify-center h-screen flex-col text-5xl gap-10">
                    <div class="flousi-container">
                        <img class="absolute top-8 w-36" src="/flouci.png" alt="">
                        <span>{{ $t('Pos.ScanQrcode') }} {{ $t('Pos.Qrcode') }}</span>
                        <span>{{ $t('Pos.ToPay') }}</span>
                        <vue-qrcode :value="qrcodeValue" :scale="3" class="mt-2" />
                    </div>
                </div>
            </div>
            <div class="bg-white w-[50%]">
                <img class="object-fill w-full h-screen" :src="secondScreenSider" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { notification } from 'ant-design-vue';
import router from "@/router";
import LbrxOrderCheckout from '@/components/LbrxOrderCheckout.vue';
import VueQrcode from 'vue-qrcode'

export default {
    name: "clientInterfaceIndex",
    components: {
        LbrxOrderCheckout,
        VueQrcode
    },
    data() {
        return {
            typeDisplay: "displayAd",
            currency: {},
            currencyCode: {},
            pos: {},
            posConfig: {},
            secondScreenBg:"",
            secondScreenSider:"",
            isPortrait: window.matchMedia("(orientation: portrait)").matches,
            channel: new BroadcastChannel('cart-info-channel'),
            sale: {},
            saleDetails: [],
            qrcodeValue: "",
            showQrcode: false
        };
    },
    created() {
       
        if (localStorage.getItem('device')) {
            
            this.pos = JSON.parse(localStorage.getItem('device'));
            
            if (JSON.parse(localStorage.getItem("device")).config) {
                
                this.posConfig = JSON.parse(this.pos.config);
                if(this.posConfig.posConfig.second_screen_bg){
                    this.secondScreenBg = this.posConfig.posConfig.second_screen_bg 
                }else{
                    this.secondScreenBg="/tj.jpg";
                }

                if(this.posConfig.posConfig.second_screen_side){
                    this.secondScreenSider = this.posConfig.posConfig.second_screen_side; 
                }else{
                    this.secondScreenSider="/tj2.jpg";
                }
                
            }
        }
        if (localStorage.getItem('currencies')) {
            let currencies = JSON.parse(localStorage.getItem('currencies'));
            this.currency = currencies.find(el => { return el.is_default == 1 });
            if (this.currency.currency) {
                this.currencyCode = this.currency.currency.code;
            }
        }
    },
    mounted() {
        
        this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
        this.orientationMediaQuery.addEventListener('change', this.handleOrientationChange);
        this.channel.addEventListener('message', (event) => {
            const data = JSON.parse(event.data);
            this.showQrcode = false;
            if (data.event == "showCart") {
                console.log('shoow caaartt', data)
                this.sale = data.sale;
                console.log('salee', this.sale)
                this.saleDetails = data.saleDetails;
                console.log('sale details', this.saleDetails)
                this.typeDisplay = "showCart";
            } else if (data.event == "showAd") {
                this.sale = {};
                this.saleDetails = [];
                this.typeDisplay = "displayAd";
            } else if (data.event == "showQrcode") {
                this.showQrcode = true;
                this.qrcodeValue = data.qrcodeValue;
            }
        });
        document.title = 'POSYS - Client interface';
    },
    beforeUnmount() {
        this.orientationMediaQuery.removeEventListener('change', this.handleOrientationChange);
    },
    methods: {
        handleOrientationChange(event) {
            this.isPortrait = event.matches;
        }
    }
};
</script>

<style scoped>

.flousi-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 2px;
}
</style>