<template>
  <LbrxLoaderSpinner :isLoaded="loading" />
  <div class="relative flex flex-col h-full w-full overflow-hidden px-4">
    <div class="pt-4 px-4 flex flex-row gap-4">
      <button
        @click="updateConfig"
        class="flex px-3 py-3 rounded-md items-center justify-center text-sm gap-2 text-white bg-primary"
      >
        <SaveOutlined />
        <span>{{ $t("Pos.saveSettings") }}</span>
      </button>
      
    </div>
    <div class="flex-1 overflow-hidden  last-child-padding">
      <LbrxCategoryBar
        v-model:loadingData="loading"
        ref="categoryBar"
        @update:affectedProducts="updateAffectedProducts"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LbrxCategoryBar from "@/components/LbrxCategoryBar.vue";
import LbrxLoaderSpinner from "@/components/LbrxLoaderSpinner.vue";
import { SaveOutlined, EyeOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "ConfigPos",
  components: {
    LbrxCategoryBar,
    LbrxLoaderSpinner,
    SaveOutlined,
    // EyeOutlined,
  },
});
</script>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { productService } from "@/_services";
const loading = ref(false);
const categoryBar = ref<InstanceType<typeof LbrxCategoryBar> | null>(
  null
) as any;

const state = reactive({
  affectedProducts: [] as any[],
});
const saveCategoryConfig = () => {
  console.log("save category config");
  if (categoryBar.value) {
    categoryBar.value.updateCategoryRank();
  }
};

const updateAffectedProducts = (
  products: Array<{ id: string; category_id: string; display_rank: number }>
) => {
  state.affectedProducts = products;
  console.log("update affected products in index ", state.affectedProducts);
};

const updateProductsRank = async () => {
  if (state.affectedProducts.length > 0) {
    loading.value = true;
    try {
      const formData = new FormData();
      formData.append("products", JSON.stringify(state.affectedProducts));
      const res = await productService.updateConfig(formData);
      loading.value = false;
    } catch (e) {
      loading.value = false;
      console.error("Error updating product ranks", e);
    }
  } else {
    return;
  }
};

const updateConfig = async () => {
  await saveCategoryConfig();
  await updateProductsRank();
  await categoryBar.value.getCategories();
  state.affectedProducts = [];
};
</script>

<style scoped>

.last-child-padding > :last-child {
  padding-right: 5rem; 
}
</style>