<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[620px] my-52 rounded-3xl overflow-hidden h-fit flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex flex-col flex-grow h-full mx-10 my-9 gap-6 text">
        <div class="grid grid-cols-2 gap-4 text-left">
          <div class="flex gap-3">
            <span class="font-semibold text-nowrap">{{ $t('Pos.Article') + " : " }}</span>
            <span>{{ detail.product ? detail.product.name : detail.ingredient.name }}</span>
          </div>
          <div class="flex gap-3">
            <span class="font-semibold text-nowrap">{{ $t('Pos.Variants') + " : " }}</span>
            <span>{{ detail.variant }}</span>
          </div>
          <div class="flex gap-3">
            <span class="font-semibold text-nowrap">{{ $t('Pos.Warehouse') + " : " }}</span>
            <span>{{ detail.warehouse.name }}</span>
          </div>
          <div class="flex gap-3">
            <span class="font-semibold text-nowrap">{{ $t('Pos.Type') + " : " }}</span>
            <span>{{ detail.type == "sale" ? $t('Pos.Sale') : detail.type == "received" ? $t('Pos.Received') : detail.type == "transfered" ? $t('Pos.Transfered') : $t('Pos.Adjustment') }}</span>
          </div>
          <div class="flex gap-3">
            <span class="font-semibold text-nowrap">{{ $t('Pos.Quantity') + " : " }}</span>
            <span>{{ detail.quantity }}</span>
          </div>
          <div class="flex flex-col gap-3">
            <span class="font-semibold text-nowrap">{{ $t('Pos.TransactionDate') + " : " }}</span>
            <span>{{ detail.transaction_date }}</span>
          </div>
        </div>
        <div class="flex gap-3 mt-3">
            <span class="font-semibold text-nowrap">{{ $t('Pos.Remark') + " : " }}</span>
            <span>{{ detail.notes }}</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "LbrxDetailPopup",
  components: {
  },
  data() {
    return {
      activeKey: '0',
      checkedItems: [],
      quantity: ""
    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    detail: {
      required: false,
      value: Object
    }
  },
  computed: {
    getSelectedTypeStyleIcon() {
      return JSON.parse(localStorage.getItem('themeTemplate')).primaryColor;
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup")
    },
    handleClickPropagation() {
      event.stopPropagation();
    },
    goDrawer() {
      router.push('/cashier');
    },
    markOffer() {
      router.push('/checkout');
    },
    proceedNormal() {
      router.push('/checkout');
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>