<template>
  <div
    class="overflow-hidden h-full flex flex-col border-l-2 border-b-gray-300"
  >
    <div class="bg-[#fff] flex justify-between py-3 px-4 font-semibold">
      <div class="flex flex-col text-left">
        <span class="text-lg font-bold"
          >{{ $t("Pos.OrderId") }} #{{ sale.id }}</span
        >
        <span>{{
          sale.customer
            ? sale.customer.first_name + " " + sale.customer.last_name
            : $t("Pos.Anonymous")
        }}</span>
      </div>
      <div class="flex items-end font-light">
        <span v-if="sale.table_id"
          >{{ $t("Pos.DineIn") }} | {{ sale.table_id }}</span
        >
        <span v-else>{{ $t("Pos.TakeAway") }}</span>
      </div>
    </div>
    <div class="bg-gray-200 h-[1px]"></div>
    <div
      class="bg-[#fff] flex flex-col space-y-1 px-4 py-4 overflow-y-auto overflow-x-hidden"
      :class="isPortrait ? 'h-32' : 'flex-1'"
    >
      <a-list
        :loading="initLoading"
        item-layout="horizontal"
        :data-source="saleDetails"
        :split="false"
      >
        <template #renderItem="{ item, index }">
          <a-list-item
            class="text-left rounded-md font-bold"
            :style="index % 2 == 0 ? 'background-color: #f5f5f5;' : ''"
          >
            <a-skeleton avatar :title="false" :loading="!!item.loading" active>
              <a-list-item-meta
                :description="
                  item.product.description && item.product.description != 'null'
                    ? item.product.description
                    : ''
                "
              >
                <template #title>
                  <span>{{ item.product.name }}</span>
                </template>
                <template #avatar>
                  <div class="flex items-start gap-2">
                    <span>{{ item.quantity }}</span>
                    <img
                      v-if="showImage"
                      class="h-12 items-center"
                      :src="item.product.photo"
                      alt=""
                    />
                  </div>
                </template>
              </a-list-item-meta>
              <div class="font-bold">
                {{ formatAmount(item.total, currencyCode, decimalPrecision) }}
              </div>
            </a-skeleton>
          </a-list-item>
        </template>
      </a-list>
    </div>
    <div class="bg-[#fff] px-4">
      <div class="flex justify-between text-sm">
        <span>{{ $t("Pos.Subtotal") }}</span
        ><span class="font-semibold">{{
          formatAmount(sale.sub_total, currencyCode, decimalPrecision)
        }}</span>
      </div>
      <div class="flex justify-between text-sm">
        <span>{{ $t("Pos.Tax") }}</span
        ><span class="font-semibold">{{
          formatAmount(sale.total_vat, currencyCode, decimalPrecision)
        }}</span>
      </div>
      <div
        v-if="parseFloat(sale.discount_value) > 0"
        class="flex justify-between text-sm"
      >
        <span>{{ $t("Pos.Discount") }}</span
        ><span class="font-semibold">{{
          formatAmount(
            sale.discount_value,
            sale.discount_type == "percentage" ? "%" : currencyCode,
            decimalPrecision
          )
        }}</span>
      </div>
      <div class="flex justify-between text-xl font-semibold my-2">
        <span>{{ $t("Pos.GrandTotal") }}</span
        ><span>{{
          formatAmount(sale.total, currencyCode, decimalPrecision)
        }}</span>
      </div>
      <div v-if="showAmount" class="flex flex-col my-2">
        <div class="bg-[#ebeef0] p-3 rounded-md font-semibold text-lg">
          <div class="flex justify-between">
            <span>{{ $t("Pos.Credit") }}</span
            ><span>{{
              formatAmount(
                amountToGive ? amountToGiveFormat : 0,
                currencyCode,
                decimalPrecision
              )
            }}</span>
          </div>
          <div v-if="multiplePayment" class="flex justify-between">
            <span>{{ $t("Pos.Paid") }}</span
            ><span>{{ paidAmount }}</span>
          </div>
          <div class="flex justify-between">
            <span>{{ $t("Pos.Balance") }}</span
            ><span>{{
              multiplePayment
                ? balanceArray
                : formatAmount(balance, currencyCode, decimalPrecision)
            }}</span>
          </div>
        </div>
      </div>
      <!-- <div v-if="ticketPrinted" class="flex my-3 gap-2">
        <button @click="confirmPayment" :disabled="(balance < 0 && sale.total) || loading"
          class="flex w-full rounded-md py-3 text-lg items-center justify-center text-white"
          :class="balance >= 0 && !loading ? 'bg-[#09aa29]' : 'bg-[#bebebe]'">
          <div v-if="!loading" class="flex gap-3 items-center">
            <iconPrint :color="'#fff'" :size="20" />{{ $t('Pos.ConfirmPayment') }}
          </div>
          <LoadingOutlined v-else :style="{ fontSize: '28px' }" />
        </button>
      </div> -->
      <div
        v-if="
          posConfig.posConfig.allow_print_before_payement == 1 &&
          showPrintReceipt
        "
        class="flex my-3"
      >
        <button
          @click="printTicket()"
          class="flex w-full rounded-md py-3 text-xl items-center justify-center text-white"
          :class="!loadingPrinting ? 'bg-[#09aa29]' : 'bg-[#bebebe]'"
        >
          <div v-if="!loadingPrinting" class="flex gap-3 items-center">
            <iconPrint :color="'#fff'" :size="20" />{{ $t("Pos.PrintReceipt") }}
          </div>
          <LoadingOutlined v-else :style="{ fontSize: '28px' }" />
        </button>
      </div>
    </div>
  </div>

  <LbrxPrintModal
    v-model:open="openPrint"
    @print="printReceipt"
    :loadingPrinting="loadingPrinting"
  />
</template>

<script>
import { currency } from "@/_helpers";
import iconPrint from "./icons/iconPrint.vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import webSocketService from "@/_services/webSocketService";
import LbrxPrintModal from "../components/LbrxPrintModal.vue";
import router from "@/router";

export default {
  name: "LbrxOrderCheckout",
  components: {
    iconPrint,
    LoadingOutlined,
    LbrxPrintModal,
  },
  data() {
    return {
      // ticketPrinted: false,
      loadingPrinting: false,
      user: {},
      printerConfig: [],
      table: {},
      socket: null,
      openPrint: false,
      source: "",
      payments: "",
    };
  },
  created() {
    if (localStorage.getItem("user")) {
      this.user = JSON.parse(localStorage.getItem("user")).user;
    }
    if (localStorage.getItem("printer_config")) {
      this.printerConfig = JSON.parse(localStorage.getItem("printer_config"));
    }
    if (localStorage.getItem("table")) {
      this.table = JSON.parse(localStorage.getItem("table"));
    }
  },
  mounted() {
    this.socket = webSocketService.getSocket();
  },
  computed: {
    ticketPrinted() {
      return this.amountToGive <= 0 ? false : true;
    },
    amountToGiveFormat() {
      return (
        this.amountToGive / currency.getPrecisionInUnit(this.decimalPrecision)
      );
    },
    balance() {
      // if (this.amountToGive) {
      //   return (parseInt(this.amountToGiveFormat) - parseFloat(this.sale.total)).toFixed(3);
      // } else {
      //   return 0-parseFloat(this.sale.total);
      // }
      return (
        parseFloat(this.amountToGiveFormat) - parseFloat(this.sale.total)
      ).toFixed(this.decimalPrecision);
    },
    // calculateDiscount() {
    //   if(this.sale.discount_type == "percentage")
    //   return
    // }
  },
  props: {
    amountToGive: {
      required: true,
      value: Number,
    },
    saleDetails: {
      required: true,
      value: Object,
    },
    sale: {
      required: true,
      value: Object,
    },
    loading: {
      required: true,
      value: Boolean,
    },
    posConfig: {
      required: true,
      value: Object,
    },
    currencyCode: {
      required: false,
      value: String,
    },
    decimalPrecision: {
      required: false,
      value: Number,
      default: 3,
    },
    isPortrait: {
      required: true,
      value: Boolean,
    },
    paidAmount: {
      required: false,
      value: Number,
      default: false,
    },
    multiplePayment: {
      required: false,
      value: Boolean,
      default: false,
    },
    balanceArray: {
      required: false,
      value: Number,
      default: false,
    },
    showPrintReceipt: {
      required: false,
      value: Boolean,
      default: true,
    },
    showImage: {
      required: false,
      value: Boolean,
      default: false,
    },
    showAmount: {
      required: false,
      value: Boolean,
      default: true,
    },
  },
  methods: {
    formatAmount(amount, currencyCode, decimalPrecision) {
      const safeAmount = isNaN(amount) || amount == null ? 0 : amount;
      const safeCurrencyCode = currencyCode ?? "";
      const safeDecimalPrecision = decimalPrecision ?? 0;
      return currency.formatAmount(
        safeAmount,
        safeCurrencyCode,
        safeDecimalPrecision
      );
      //return currency.formatAmount(amount, currencyCode, decimalPrecision);
    },
    removeQuantity(product) {
      this.$emit("removeQuantity", product);
    },
    addQuantity(product) {
      this.$emit("addQuantity", product);
    },
    // confirmPayment() {
    //   this.$emit("confirmPayment", this.balance);
    // },
    connectSocket() {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });';

        this.socket = new WebSocket("ws://localhost:8765");

        this.socket.onopen = (event) => {
          this.socket.send(token);
        };

        this.socket.onmessage = (event) => {
          if (event.data == "Authentication successful") {
            // this.sendMessage();
          }
        };

        this.socket.onerror = (error) => {
          console.error(`WebSocket Error: ${error}`);
        };

        this.socket.onclose = (event) => {
          console.log(`WebSocket connection closed: ${event}`);
        };
      }
    },
    sendMessage(payments) {
      console.log("----- sending message ----");
      if (this.socket.readyState === WebSocket.OPEN) {
        let saleObject = this.sale;

        const device = localStorage.getItem("device");
        const deviceConfig = JSON.parse(device).config;
        const displaySettings = JSON.parse(deviceConfig).posConfig
          .table_item_display
          ? JSON.parse(deviceConfig).posConfig.table_item_display
          : "";


        if (displaySettings !== "") {
         
          if (displaySettings == "id") {
            saleObject.id = this.sale.id;
          } else if (displaySettings == "order_number") {
            if (
              this.sale.order_number !== null &&
              this.sale.order_number !== "" &&
              this.sale.order_number !== "null"
            ) {
              saleObject.id = this.sale.order_number;
            } else {
              saleObject.id = this.sale.id;
            }
          }
        } else {
          saleObject.id = this.sale.id;
        }

        saleObject.receipt_number = this.sale.id;


        //user
        const user = JSON.parse(localStorage.getItem("user")).user;
        saleObject.user = user;

        saleObject.table = this.table ? this.table : {};
        // console.log("change", saleObject);
        if (payments && payments !== "offer") {
          saleObject.payments = [];
          saleObject.payments[0] = payments;

          // saleObject.payments[0].amount_given = Math.ceil((parseFloat(saleObject?.payments[0]?.change_due) +
          //   parseFloat(saleObject?.payments[0]?.amount_paid))).toFixed(this.decimalPrecision);
          saleObject.payments[0].amount_given = parseFloat(
            (
              parseFloat(saleObject?.payments[0]?.change_due) +
              parseFloat(saleObject?.payments[0]?.amount_paid)
            ).toFixed(this.decimalPrecision)
          );
        }
        if (this.printerConfig.length != 0) {
          // filter the sales according to production point
          let arraySalesByIp = [];
          this.sale.sale_details.forEach((el) => {
            this.printerConfig.forEach((el2) => {
              if (
                el.product.production_point_id == el2.config.production_point_id
              ) {
                const index = arraySalesByIp.findIndex(
                  (el) => el.ip_address == el2.config.ip_address
                );
                if (index != -1) {
                  arraySalesByIp[index].sale_details.push(el);
                } else {
                  arraySalesByIp.push({
                    ip_address: el2.config.ip_address,
                    production_point_name: el2.config.production_point_name,
                    sale_details: [el],
                  });
                }
              }
            });
          });
          // arraySalesByIp.push({ "ip_address": "all", sale_details: this.sale.sale_details })
          saleObject.production_point = arraySalesByIp;
        }
        const message = {
          action: "print_receipt",
          data: saleObject,
        };

        console.log("saleObject", saleObject);

        if (payments == "offer") {
          message.is_offer = true;
        }

        this.socket.send(JSON.stringify(message));

        if (
          saleObject.production_point &&
          saleObject.production_point.length > 0
        ) {
          const messageIp = {
            action: "print_production_point",
            data: saleObject,
          };
          if (payments == "offer") {
            messageIp.is_offer = true;
          }
          this.socket.send(JSON.stringify(messageIp));
        }
        // this.ticketPrinted = true;
      } else {
        console.error("Socket is not open. Can't send message");
      }
    },
    printReceipt(action, payments) {
      console.log("------- print receipt ------- !!!");
      this.loadingPrinting = true;
      let messages = [];
      if (action === "productionPoint") {
        let message = {
          action: "print_production_point",
          data: "",
        };
        if (this.payments == "offer") {
          message.is_offer = true;
        }
        // console.log('message', message)
        messages.push(message);
      } else if (action === "POS") {
        let message = {
          action: "print_receipt",
          data: "",
        };
        if (this.payments === "offer") {
          message.is_offer = true;
        }
        messages.push(message);
      } else {
        let message1 = {
          action: "print_receipt",
          data: "",
        };
        if (this.payments === "offer") {
          message1.is_offer = true;
        }
        messages.push(message1);
        let message2 = {
          action: "print_production_point",
          data: "",
        };
        if (this.payments === "offer") {
          message2.is_offer = true;
        }
        messages.push(message2);
      }

      //print
      if (this.socket.readyState === WebSocket.OPEN) {
        let saleObject = this.sale;

        // add config diplay
        const device = localStorage.getItem("device");
        const deviceConfig = JSON.parse(device).config;
        const displaySettings = JSON.parse(deviceConfig).posConfig
          .table_item_display
          ? JSON.parse(deviceConfig).posConfig.table_item_display
          : "";


        if (displaySettings !== "") {
          if (displaySettings == "id") {
            saleObject.id = this.sale.id;
          } else if (displaySettings == "order_number") {
            if (
              this.sale.order_number !== null ||
              this.sale.order_number !== "" ||
              this.sale.order_number !== "null"
            ) {
              saleObject.id = this.sale.order_number;
            } else {
              saleObject.id = this.sale.id;
            }
          }
        } else {
          saleObject.id = this.sale.id;
        }
        saleObject.receipt_number = this.sale.id;



        //user
        const user = JSON.parse(localStorage.getItem("user")).user;
        saleObject.user = user;

        // saleObject.receipt_number = this.sale.id;
        saleObject.table = this.table ? this.table : {};

        if (this.payments && this.payments !== "offer") {
          saleObject.payments = [];
          saleObject.payments[0] = this.payments;
          // saleObject.payments[0].amount_given = Math.ceil(
          //   parseFloat(saleObject?.payments[0]?.change_due) +
          //     parseFloat(saleObject?.payments[0]?.amount_paid)
          // ).toFixed(this.decimalPrecision);
          saleObject.payments[0].amount_given = parseFloat(
            (
              parseFloat(saleObject?.payments[0]?.change_due) +
              parseFloat(saleObject?.payments[0]?.amount_paid)
            ).toFixed(this.decimalPrecision)
          );
        }
        if (this.printerConfig.length != 0) {
          // filter the sales according to production point
          let arraySalesByIp = [];
          this.sale.sale_details.forEach((el) => {
            this.printerConfig.forEach((el2) => {
              if (
                el.product.production_point_id == el2.config.production_point_id
              ) {
                const index = arraySalesByIp.findIndex(
                  (el) => el.ip_address == el2.config.ip_address
                );
                if (index != -1) {
                  arraySalesByIp[index].sale_details.push(el);
                } else {
                  arraySalesByIp.push({
                    ip_address: el2.config.ip_address,
                    production_point_name: el2.config.production_point_name,
                    sale_details: [el],
                  });
                }
              }
            });
          });
          // arraySalesByIp.push({ "ip_address": "all", sale_details: this.sale.sale_details })
          saleObject.production_point = arraySalesByIp;
        }

        messages.forEach((item) => {
          item.data = saleObject;
          this.socket.send(JSON.stringify(item));
        });
      }

      setTimeout(() => {
        this.loadingPrinting = false;
      }, 2000);
      // console.log("print", this.source)
      if (this.source === "pad") {
        // console.log("pad ?", this.source);
        router.push("/home");
      }
      this.openPrint = false;
    },
    openPrintModal() {
      this.openPrint = true;
    },
    printTicket(payments = null, source = null) {
      // console.log("print ticket ??!!! ", this.sale.sale_details, source);
      this.source = source;
      this.payments = payments;
      this.loadingPrinting = true;
      let hasProductionPoint = false;
      this.sale.sale_details.forEach((sale) => {
        if (
          sale.product.production_point_id !== null &&
          sale.product.production_point_id !== "null" &&
          sale.product.production_point_id !== ""
        ) {
          hasProductionPoint = true;
          return;
        }
      });
      // console.log("the profuct has pp ? ", hasProductionPoint);

      if (hasProductionPoint) {
        // console.log('the profuct has pp')
        this.loadingPrinting = false;
        this.openPrintModal();
      } else {
        this.sendMessage(payments);
        setTimeout(() => {
          this.loadingPrinting = false;
        }, 2000);
      }
    },
  },
};
</script>

<style scoped></style>