<template>
  <a-modal width="600px" centered v-model:open="isOpen" :closable="false">
    <div class="flex justify-center mb-5">
      <span class="text-2xl font-semibold">{{ $t("Pos.logoutTxt") }}</span>
    </div>

    <div class="flex gap-4">
      <button
        class="flex w-full rounded-md py-2 text-lg items-center justify-center text-white"
        :style="{ backgroundColor: backgroundColor }"
        @click="logout"
      >
        <span>{{ $t("Pos.Logout") }}</span>
      </button>

      <button
        class="flex w-full rounded-md py-2 text-lg items-center justify-center border"
        :style="{
          color: backgroundColor,
          backgroundColor: '#fff',
          borderColor: backgroundColor,
        }"
        @click="closeModal"
      >
        <span>{{ $t("Pos.Cancel") }}</span>
      </button>
    </div>

    <template #footer> </template>
  </a-modal>
</template>

<script lang="ts">
import {
  defineComponent,
  defineProps,
  defineEmits,
  onMounted,
  watch,
} from "vue";

export default defineComponent({
  name: "LbrxLogoutModal",
});
</script>

<script setup lang="ts">
import { ref } from "vue";
const props = defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  (e: "update:open", value: boolean): void;
  (e: 'logout'): void

}>();

const isOpen = ref(props.open);

watch(
  () => props.open,
  (newValue) => {
    isOpen.value = newValue;
  }
);

watch(isOpen, (newValue) => {
  emit("update:open", newValue);

});

const closeModal=()=>{
  isOpen.value = false;
}

const logout = () => {
  emit('logout');
}

const backgroundColor = ref("");

onMounted(() => {
  const bgColor = localStorage.getItem("themeTemplate");

  if (bgColor) {
    backgroundColor.value = JSON.parse(bgColor).primaryColor;
  } else {
    backgroundColor.value = "#fc8019";
  }
});
</script>