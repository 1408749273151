function server() {
    return process.env.VUE_APP_API_URL_TN;
}

export const ApiConfigs = {
    base_url: server(),
    broadcast_url: server() + "/broadcasting",
    /* -------------------------------- */
    // PUSHER
    pusher: {
        auth: "/auth",
    },
    // ACCOUNT
    accounts: {
        captcha: process.env.VUE_APP_CAPTCHA_LINK,
        login: "/auth",
        loginPin: "/pin-auth",
        loginBagde: "/badge-auth",
        logout: "/logout"
    },
    device: {
        generate: "/devices/auth/generate",
        authCodeConfirm: "/devices/auth/confirm"
    },
    warehouse: {
        getAll: "/warehouses",
        show: "/warehouses/:id",
        store: "/warehouses",
        update: "/warehouses/:id",
        delete: "/warehouses/:id"
    },

    customer: {
        getAll: "/customers",
        getById: "/customers/:id",
        store: "/customers",
        update: "/customers/:id",
        delete: "/customers/:id"
    },
    product:{
        getAll:"/products",
        getById:"/products/:id",
        create:"/products",
        update:"/products/:id/update",
        delete:"/products/",
        getVariantByAttribute:"/products/:id/variants-by-attribute-value-combination",
        batchRank:"/products/batch-rank"

    },
    ingredients:{
        getAll:"/ingredients",
        getById:"/ingredients/",
        add:"/ingredients",
        update:"/ingredients/",
        delete:"/ingredients/"
    },
    category:{
        getAll: "/categories",
        show: "/categories/:id",
        add:"/categories",
        update:"/categories/:id",
        delete: "/categories/:id",
        batchRank:"/categories/batch-rank"
    },
    manufacturer:{
        getAll:"/manufacturers",
        create:"/manufacturers",
        getById: "/manufacturers/:id",
        update:"/manufacturers/:id",
        delete: "/manufacturers/:id"
    },
    store:{
        getAll:"/stores",
        create:"/stores",
        show: "/stores/:id",
        update:"/stores/:id",
        delete: "/stores/:id"
    },
    supplier:{
        getAll:"/suppliers",
        create:"/suppliers",
        getById: "/suppliers/:id",
        update:"/suppliers/:id",
        delete: "/suppliers/:id"
    },
    tables:{
        getAll:"/tables",
        create:"/tables",
        show: "/tables/:id",
        update:"/tables/:id",
        delete: "/tables/:id"
    },
    tableLocation:{
        getAll:"/table-locations",
        create:"/table-locations",
        show: "/table-locations/:id",
        update:"/table-locations/:id",
        delete: "/table-locations/:id" 
    },
    discount:{
        getAll:"/discounts",
        create:"/discounts",
        show: "/discounts/:id",
        update:"/discounts/:id",
        delete: "/discounts/:id"
    },
    ingredientCategories:{
        getAll:"/ingredient_categories",
        create:"/ingredient_categories",
        show: "/ingredient_categories/:id",
        update:"/ingredient_categories/:id",
        delete: "/ingredient_categories/:id"
    },
    variant:{
        getAll:"/variants",
        create:"/variants",
        show: "/variants/:id",
        update:"/variants/:id",
        delete: "/variants/:id"
    },
    attribute:{
        getAll:"/attributes",
        create:"/attributes",
        show: "/attributes/:id",
        update:"/attributes/:id",
        delete: "/attributes/:id"
    },
    attributeValues:{
        getAll:"/attribute_values",
        create:"/attribute_values",
        show: "/attribute_values/:id",
        update:"/attribute_values/:id",
        delete: "/attribute_values/:id"
    },
    vatrate:{
        getAll:"/vat-rates",
        create:"/vat-rates",
        getById: "/vat-rates/:id",
        update:"/vat-rates/:id",
        delete: "/vat-rates/:id"
    },
    role:{
        getAll:"/roles",
        create:"/roles",
        show: "/roles/:id",
        update:"/roles/:id",
        delete: "/roles/:id"
    },
    user:{
        getAll:"/users",
        create:"/users",
        show: "/users/:id",
        update:"/users/:id",
        delete: "/users/:id"
    },
    payment:{
        getAll:"/payments",
        create:"/payments",
        getById: "/payments/:id",
        update:"/payments/:id",
        delete: "/payments/:id",
        paymentFlouci: "/payments-via-flousi",
        checkFlouci: "/payments-check-flousi"
    },
    payment_method:{
        getAll:"/store/payment-methods",
        create:"/store/payment-methods",
        getById: "/store/payment-methods/:id",
        update:"/store/payment-methods/:id",
        delete: "/store/payment-methods/:id"
    },
    currency:{
        getAll:"/store/currencies",
        create:"/store/currencies",
        getById: "/store/currencies/:id",
        update:"/store/currencies/:id",
        delete: "/store/currencies/:id"
    },
    cart:{
        getAll:"/carts",
        create:"/carts",
        show: "/carts/:id",
        update:"/carts/:id",
        delete: "/carts/:id"
    },
    cartItem:{
        getAll:"/carts/:id/items",
        create:"/carts/:id/items",
        show: "/carts/:id/items/:id",
        update:"/carts/:id_cart/items/:id_item",
        delete: "/carts/:id/items/:id"
    },
    sale:{
        getAll:"/sales",
        create:"/sales",
        getById: "/sales/:id",
        update:"/sales/:id",
        delete: "/sales/:id",
        proceedSale: "/sales/proceed",
        markAsSale: "/sales/:id/mark-as-offer"
    },
    cash_drawer:{
        getAll:"/cash-drawer",
        getClosedDrawer:"/cash-drawer/closed-by-date",
        create:"/cash-drawer",
        open:"/cash-drawer/open",
        getById: "/cash-drawer/:id",
        update:"/cash-drawer/:id",
        delete: "/cash-drawer/:id"
    },
    return:{
        getAll:"/returns",
        create:"/returns",
        getById: "/returns/:id",
        update:"/returns/:id",
        delete: "/returns/:id"
    },
    statistic:{
        getAllSales:"/statistics/sales",
        getAllProducts:"/statistics/products",
        report:"/statistics/cashier",
        create:"/returns",
        getById: "/returns/:id",
        update:"/returns/:id",
        delete: "/returns/:id"
    },
    production_point:{
        getAll:"/production-points",
        create:"/production-points",
        getById: "/production-points/:id",
        update:"/production-points/:id",
        delete: "/production-points/:id"
    },
    printer:{
        getAll:"/printers",
        create:"/printers",
        getById: "/printers/:id",
        update:"/printers/:id",
        delete: "/printers/:id"
    },
    stock_items:{
        getAll:"/stock-items",
        getAllTransaction:"/inventory_transactions",
        create:"/stock-items",
        getById: "/stock-items/:id",
        update:"/stock-items/:id",
        delete: "/stock-items/:id"
    }
};
