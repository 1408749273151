<template>
  <div class="overflow-hidden h-full flex flex-col border-l-2 border-b-gray-300 relative">
    <LbrxLoaderSpinner :isLoaded="showLoaderCart" />
    
    <div class="bg-[#fff] flex justify-between items-center py-3 px-2 font-semibold">
      <div>
        <div class="flex items-center" v-if="customer.id">
          <div class="flex items-center" @click="navigateUpdateCustomer">
            <img class="w-10 h-10 rounded-full" src="/default_img.jpg" alt="">
            <div class="flex flex-col ml-3 items-start">
              <span>{{ customer.first_name + " " + customer.last_name }}</span>
              <span>#{{ customer.id }}</span>
            </div>
          </div>
          <div class="mx-2 px-3 py-2" @click="removeSelectedCustomer">
            <CloseCircleOutlined :style="{ fontSize: '19px' }" />
          </div>
        </div>
        <button v-else @click="goAddCustomer"
          class="h-full p-2 rounded-md bg-[#ebeef0] items-center justify-center text-[#141B34]">
          <div class="gap-2 items-center hidden xl:flex">
            <iconPlus :size="20" :color="'#141B34'" /><span>{{ $t('Pos.SelectCustomer') }}</span>
          </div>
          <div class="flex gap-2 items-center xl:hidden">
            <UserAddOutlined />
          </div>
        </button>
      </div>
      <div class="flex gap-3 h-fit">
        <button class="p-2 rounded-md" :class="isInputFocused ? 'bg-primary' : 'bg-[#f4f4f4]'" @click="enableScanmode">
          <iconBarcode :size="20" :color="isInputFocused ? '#fff' : '#141B34'" />
        </button>
        <button class="p-2 rounded-md bg-[#f4f4f4]" @click="gatCartItems"><img src="../assets/icons/refresh.svg"
            class="w-5" alt=""></button>
        <button class="p-2 rounded-md bg-[#f4f4f4]" @click="emptyCart">
          <iconTrash :size="20" :color="'#141B34'" />
        </button>
      </div>
    </div>
    <div class="bg-gray-200 h-[1px]"></div>
    <div v-if="cart.length == 0" class="bg-[#fff] flex flex-col h-full justify-center">
      <div class="opacity-20">
        <iconCart class="w-20 h-fit mx-auto" :size="24" :color="'#000'" />
        <span>{{ $t('Pos.CartEmpty') }}</span>
      </div>
    </div>
    <div v-if="cart.length"
      class="bg-[#fff] flex flex-col flex-1 space-y-1 px-2 py-4 overflow-y-auto overflow-x-hidden">
      <div v-for="(item, index) in cart" :key="index">
        <LbrxCartItem :item="item" :index="index" :bgColor="index % 2 == 0 ? '#f5f5f5' : '#fff'"
          @removeQuantity="removeQuantity" @addQuantity="addQuantity" @pointerdown.prevent="startCountdown(true, item)"
          @pointerup.prevent="startCountdown(false)" :decimalPrecision="posConfig.posConfig.decimalPrecision" />
      </div>
    </div>
    <div v-if="!hideDiscountBar" class="bg-[#fff]">
      <div class="bg-primary-light flex justify-between items-center m-2 p-3 font-semibold text-sm rounded-md">
        <span>{{ $t('Pos.Add') }}</span>
        <div class="flex gap-3 text-primary">
          <div v-if="parseFloat(cartObject?.discount_value) > 0"
            class="bg-primary rounded-2xl flex gap-1 text-white px-2 py-1">
            <div class="bg-white text-primary rounded-full px-[6px] flex flex-col"><span class="scale-[140%]"
                @click="deleteDiscount">&times;</span></div>
            <div>{{ formatAmount(cartObject.discount_value, cartObject.discount_type == 'percentage' ? '%' :
              currencyCode, decimalPrecision) }}
            </div>
          </div>
          <div v-else class="flex gap-3">
            <button @click="showDiscount">{{ $t('Pos.Discount') }}</button>
            <button @click="showCoupon">{{ $t('Pos.CouponCode') }}</button>
          </div>
          <button>{{ $t('Pos.Note') }}</button>
        </div>
      </div>
    </div>
    <div class="flex flex-col mx-3 my-2">
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Subtotal') }}</span><span class="font-semibold">{{ formatAmount(cartObject?.sub_total ?
          cartObject.sub_total : 0, currencyCode, decimalPrecision) }}</span>
      </div>
      <div class="flex justify-between text-sm">
        <span>{{ $t('Pos.Tax') }}</span><span class="font-semibold">{{ formatAmount(cartObject?.total_vat ?
          cartObject.total_vat : 0, currencyCode, decimalPrecision) }}</span>
      </div>
      <!-- <div v-if="parseFloat(cartObject.discount_value) > 0" class="flex justify-between text-sm">
        <span>{{ $t('Pos.Discount') }}</span><span class="font-semibold">{{ cartObject.discount_type == 'percentage' ?
      '%' : 'DT' }} {{ cartObject.discount_value }}</span>
      </div> -->
      <div class="flex justify-between text-xl font-semibold mt-2">
        <span>{{ $t('Pos.PayableAmount') }}</span><span>{{ formatAmount(cartObject?.total ?
          cartObject.total : 0, currencyCode, decimalPrecision)
          }}</span>
      </div>
    </div>
    <div class="flex mx-2 my-3 gap-2">
      <button @click="holdOrder"
        class="bg-primary flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white"
        :disabled="calculateAmountDifference < 0 || cart.length == 0"><img src="../assets/icons/pause.svg" class="w-5"
          alt="">{{ $t('Pos.HoldOrder') }}</button>
      <button @click="proceedOrder"
        class="bg-[#09aa29] flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white"
        :disabled="calculateAmountDifference < 0 || cart.length == 0"><img src="../assets/icons/proceed.svg" class="w-5"
          alt="">{{ $t('Pos.Proceed') }}</button>
    </div>
    <div v-if="enableBook" class="flex mx-2 mb-3 gap-2">
      <div class="w-full"></div>
      <button @click="bookOrder"
        class="bg-[#777777] flex w-full rounded-md py-2 text-lg items-center justify-center gap-3 text-white"
        :disabled="calculateAmountDifference < 0 || cart.length == 0">{{ $t('Pos.Book') }}</button>
    </div>
  </div>


</template>

<script>
import iconCart from "./icons/iconCart.vue"
import iconPlus from "./icons/iconPlus.vue"
import iconBarcode from "./icons/iconBarcode.vue"
import iconTrash from "./icons/iconTrash.vue"
import LbrxCartItem from "./LbrxCartItem.vue"
import LbrxLoaderSpinner from "./LbrxLoaderSpinner.vue"
import { CloseCircleOutlined, UserAddOutlined } from '@ant-design/icons-vue';
import { currency, notificationPlay } from '../_helpers';
import { notification } from 'ant-design-vue';
import { saleService, cartService } from "@/_services"
import router from "@/router";
export default {
  name: "LbrxCart",
  inject: ['longPressTimer'],
  components: {
    iconCart,
    iconTrash,
    LbrxCartItem,
    iconPlus,
    LbrxLoaderSpinner,
    CloseCircleOutlined,
    iconBarcode,
    UserAddOutlined,
  },
  data() {
    return {
      amountToGive: 0,
      customer: {},
      enableBook: false,
      modalQuantity: {
        active: false,
      },
    };
  },
  created() {
    if (localStorage.getItem('customer')) {
      if (JSON.parse(localStorage.getItem("cart"))) {
        if (JSON.parse(localStorage.getItem("cart")).customer_id == JSON.parse(localStorage.getItem('customer')).id)
          this.customer = JSON.parse(localStorage.getItem('customer'));
        else {
          this.removeSelectedCustomer()
        }
      } else {
        this.customer = JSON.parse(localStorage.getItem('customer'));
      }
    }
    this.enableBook = this.posConfig.posConfig.enable_booking == 1 ? true : false;
  },
  computed: {
    totalPriceCart() {
      return this.cart.map(element => element.price * element.qty).reduce((a, b) => a + b, 0);
    },
    totalQuantityCart() {
      return this.cart.map(element => 1 * element.qty).reduce((a, b) => a + b, 0);
    },
    calculateAmountDifference() {
      let difference = this.amountToGive - this.totalPriceCart;
      if (difference >= 0) {
        return difference.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else {
        return difference.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
    },
    decimalPrecision() {
      return this.posConfig.posConfig.decimalPrecision ? this.posConfig.posConfig.decimalPrecision : 3;
    }
  },
  props: {
    cart: {
      required: true,
      value: Array
    },
    cartObject: {
      required: true,
      value: Object
    },
    showLoaderCart: {
      required: true,
      value: Boolean
    },
    hideDiscountBar: {
      required: false,
      value: Boolean,
      default: false
    },
    currencyCode: {
      required: false,
      value: String
    },
    isInputFocused: {
      required: false,
      value: Boolean
    },
    pos: {
      required: true,
      value: Object
    },
    posConfig: {
      required: true,
      value: Object
    },
    isPortrait: {
      required: true,
      value: Boolean
    },
    showBook: {
      required: false,
      value: Boolean,
      default: false
    },
  },
  methods: {
    formatAmount(amount, currencyCode, decimalPrecision) {
      return currency.formatAmount(amount, currencyCode, decimalPrecision);
    },
    removeQuantity(product) {
      this.$emit('removeQuantity', product);
    },
    addQuantity(product) {
      this.$emit('addQuantity', product);
    },
    emptyCart() {
      this.updateTableStorage();
      this.$emit("emptyCart");
      this.dispatchEvent("showAd", {}, []);
    },
    dispatchEvent(event, sale, saleDetails) {
            var channel = new BroadcastChannel('cart-info-channel');
            let payload = {
                event: event,
                sale: sale,
                saleDetails: saleDetails
            }
            channel.postMessage(JSON.stringify(payload));
            channel.close();
        },
    addAmount(amount) {
      this.amountToGive += amount;
      notificationPlay.beep();
    },
    deleteAmount() {
      this.amountToGive = 0;
      notificationPlay.delete();
    },
    showReceipt() {
      this.$emit("showReceipt");
    },
    gatCartItems() {
      this.$emit("gatCartItems");
    },
    goAddCustomer() {
      router.push("/customers");
    },
    proceedOrder() {
      if (this.posConfig.posConfig.generate_sale == 1) {
        localStorage.setItem("cart_info", localStorage.getItem("cart"));
        localStorage.removeItem("cart");
        this.$emit("deleteCartVar");
        // this.showNotification(this.$t('Pos.ProceedSaleSuccess'));
        if (this.posConfig.posConfig.redirect_after_cart && this.posConfig.posConfig.redirect_after_cart.length != 0) {
          router.push(this.posConfig.posConfig.redirect_after_cart)
        } else {
          router.push('/checkout')
        }
      } else {
        this.$emit("toggleCartLoader", true);
        let payload = {
          cart_id: JSON.parse(localStorage.getItem("cart")).id.toString(),
          device_id: this.pos.id,
          user_id: JSON.parse(localStorage.getItem("user")).user.id
        }
        saleService.proceedSale(payload).then((res) => {
          localStorage.removeItem("cart");
          // localStorage.removeItem("customer");
          // localStorage.removeItem("table");
          localStorage.setItem("sale", JSON.stringify(res.sale));
          this.$emit("deleteCartVar");
          this.showNotification(this.$t('Pos.ProceedSaleSuccess'));
          if (this.posConfig.posConfig.redirect_after_cart && this.posConfig.posConfig.redirect_after_cart.length != 0) {
            router.push(this.posConfig.posConfig.redirect_after_cart)
          } else {
            router.push('/checkout')
          }
        }).catch((error) => {
          console.log("error api : ", error);
        }).finally(() => {
          this.$emit("toggleCartLoader", false);
        });
      }
    },
    bookOrder() {
      localStorage.removeItem("cart");
      localStorage.removeItem("table");
      localStorage.removeItem("customer");
      this.customer = {};
      this.updateTableStorage();
      this.$emit("deleteCartVar");
      this.showNotification(this.$t('Pos.BookSuccessful'));
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          color: 'white'

        }
      });
    },
    navigateUpdateCustomer() {
      router.push("/customer/" + this.customer.id + "/update")
    },
    removeSelectedCustomer() {
      this.$emit("toggleCartLoader", true);
      this.customer = {};
      localStorage.removeItem('customer');
      let payload = {
        user_id: JSON.parse(localStorage.getItem("user")).user.id,
        customer_id: null
      }
      cartService.update(JSON.parse(localStorage.getItem("cart")).id, payload).then((res) => {
        this.gatCartItems();
        router.push("/home");
      }).catch((error) => {
        console.log("error api : ", error);
      }).finally(() => {
        // 
      });
    },
    showDiscount() {
      if (this.cartObject.id) {
        this.$emit("showDiscount")
      }
    },
    showCoupon() {
      if (this.cartObject.id) {
        this.$emit("showCoupon")
      }
    },
    deleteDiscount() {
      this.$emit("deleteDiscount")
    },
    enableScanmode() {
      this.$emit("enableScanmode")
    },
    holdOrder() {
      if (this.cartObject.id) {
        localStorage.removeItem("cart");
        localStorage.removeItem("table");
        localStorage.removeItem("customer");
        this.customer = {};
        this.updateTableStorage();
        this.showNotification(this.$t('Pos.CartOnHold'));
        this.$emit("deleteCartVar");
      }
    },
    updateTableStorage() {
      window.dispatchEvent(new CustomEvent('table-localstorage-changed', {
        detail: {
          storage: {}
        }
      }));
    },
    startCountdown(param, item = {}) {
      if (param) {
        this.timer = setTimeout(() => {
          this.$emit("showQuantityPopup", item.product, item);
        }, this.longPressTimer ? this.longPressTimer : 2000);
      } else {
        clearTimeout(this.timer);
        console.log("Countdown disabled.");
      }
    },
    openQuantityPopup(){
      this.modalQuantity.active = true;
    }
  }
};
</script>

<style scoped></style>