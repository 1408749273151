<template>
  <div
    v-if="active"
    class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"
  ></div>
  <div
    v-if="active"
    class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16"
    @click="closePopup"
  >
    <div
      class="bg-[#fff] w-[670px] my-52 rounded-3xl overflow-hidden h-full flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation"
    >
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup"
        >&times;</span
      >
      <div class="flex flex-col flex-grow h-full mx-10 mt-6">
        <span class="text-left text-lg mx-8"
          ><span class="font-semibold">{{ $t("Pos.Product") + ": " }}</span
          >{{ selectedItem.name }}</span
        >

        <LbrxNumPad
          :amountToGive="state.quantity"
          @addAmount="addAmount"
          :cashierMode="true"
          @confirmAction="confirmQty"
        />
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {
  defineComponent,
  defineProps,
  defineEmits,
  onMounted,
  watch,
  reactive,
} from "vue";

export default defineComponent({
  name: "LbrxPrdQuantity",
});
</script>

<script setup lang="ts">
import LbrxNumPad from "@/components/LbrxNumPad.vue";
const props = defineProps<{
  active: boolean;
  selectedItem:object;
}>();
const emit = defineEmits<{
  (e: "closeQuantityPopup"): void;
  (e: "confirmPrdQty", quantity:any): void;


}>();

const state = reactive({
  quantity: "",
});

const closePopup=()=> {
    emit("closeQuantityPopup")
}

const handleClickPropagation = () => {
  event?.stopPropagation();
};

const addAmount = (item: any) => {
  if (item === -1) {
    state.quantity = "";
  } else if (item === -2) {
    state.quantity = "";
  } else {
    state.quantity += item;
  }
};

const confirmQty=()=> {
    emit("confirmPrdQty", state.quantity)
    state.quantity = "";
}

</script>