<template>
  <div v-if="active" class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50"></div>
  <div v-if="active" class="fixed inset-0 flex justify-center items-center z-50 blur-none my-16" @click="closePopup">
    <div
      class="bg-[#fff] w-[370px] my-52 rounded-3xl overflow-hidden h-fit flex flex-col border-2 border-b-gray-300 absolute"
      @click="handleClickPropagation">
      <span class="absolute right-6 top-2 scale-[240%]" @click="closePopup">&times;</span>
      <div class="flex flex-col flex-grow items-center h-full mx-10 my-9 gap-6">
        <img src="/flouci_logo.png" class="w-20" alt="">
        <div class="flex flex-col gap-4">
          <span>{{ message }}</span>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "LbrxInfoPopup",
  components: {

  },
  data() {
    return {

    };
  },
  props: {
    active: {
      required: true,
      value: Boolean
    },
    message: {
      required: false,
      value: String,
      default: ""
    }
  },
  methods: {
    closePopup() {
      this.$emit("closePopup")
    },
    handleClickPropagation() {
      event.stopPropagation();
    }
  }
};
</script>

<style scoped>
input[type=checkbox] {
  transform: scale(1.5);
}
</style>