<template>
  <div
    class="flex flex-col flex-grow px-4"
    :class="isPortrait ? 'h-[40vh] mb-4' : 'w-[58%]'"
  >
  
    <input
      id="scanProduct"
      type="text"
      :style="{ position: 'absolute', left: '-9999px' }"
      @keyup.enter="confirmBarcode"
      v-model="barcode"
      @focus="isInputFocused = true"
      @blur="isInputFocused = false"
    />
    <LbrxFilterBar
      v-if="categories.length != 1"
      :filters="categories"
      :selectedFilter="selectedFilter"
      @selectFilter="selectFilter"
    />
    <div class="flex flex-wrap mt-3 overflow-auto">
      <div
        v-for="(item, index) in products"
        :key="index"
        class="w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 aspect-w-1 aspect-h-1"
        :class="isPortrait ? 'w-1/3' : 'w-1/2'"
      >
        <LbrxProductItem
          :product="item"
          :loadingCard="loadingCard"
          @click="selectItem(item)"
          :currencyCode="currencyCode"
          :decimalPrecision="posConfig.posConfig.decimalPrecision"
        />
      </div>
      <div v-if="products.length == 0" class="p-4">
        {{ loadingP ? $t("Pos.LoadingProducts") : $t("Pos.NoProducts") }}
      </div>
    </div>
  </div>
  <div :class="isPortrait ? '' : 'w-[32%]'">
    <LbrxCart
      v-if="!isPortrait"
      :showLoaderCart="showLoaderCart"
      :cart="cart"
      :cartObject="cartObject"
      @removeQuantity="removeQuantity"
      @addQuantity="addQuantity"
      @emptyCart="emptyCart"
      @showReceipt="showReceipt"
      @gatCartItems="checkCartStatus"
      @deleteCartVar="deleteCartVar"
      @toggleCartLoader="toggleCartLoader"
      @showDiscount="showDiscount"
      @showCoupon="showCoupon"
      @showQuantityPopup="showQuantityPopup"
      @deleteDiscount="deleteDiscount"
      @enableScanmode="enableScanmode"
      :currencyCode="currencyCode"
      :isInputFocused="isInputFocused"
      :pos="pos"
      :posConfig="posConfig"
      :isPortrait="isPortrait"
    />
    <LbrxCartPortrait
      v-else
      :showLoaderCart="showLoaderCart"
      :cart="cart"
      :cartObject="cartObject"
      @removeQuantity="removeQuantity"
      @addQuantity="addQuantity"
      @emptyCart="emptyCart"
      @showReceipt="showReceipt"
      @gatCartItems="checkCartStatus"
      @deleteCartVar="deleteCartVar"
      @toggleCartLoader="toggleCartLoader"
      @showDiscount="showDiscount"
      @showCoupon="showCoupon"
      @showQuantityPopup="showQuantityPopup"
      @deleteDiscount="deleteDiscount"
      @enableScanmode="enableScanmode"
      :currencyCode="currencyCode"
      :isInputFocused="isInputFocused"
      :pos="pos"
      :posConfig="posConfig"
      :isPortrait="isPortrait"
    />
  </div>

  <LbrxCustomizePopup
    ref="ingredRef"
    v-model:active="modal.active"
    :active="modal.active"
    :selectedItem="selectedItem"
    @closePopup="closePopup"
    @confirmItem="confirmItem"
    :decimalPrecision="posConfig.posConfig.decimalPrecision"
    :currencyCode="currencyCode"
  />
  <LbrxCustomizeVariantPopup
    ref="variantRef"
    :active="modalVariant.active"
    :selectedItem="selectedItem"
    @closePopup="closePopup"
    @confirmItemVariant="confirmItemVariant"
    :decimalPrecision="posConfig.posConfig.decimalPrecision"
    :currencyCode="currencyCode"
  />
  <LbrxQuantityPopup
    :active="modalQuantity.active"
    @closePopup="closePopup"
    @confirmQty="confirmQty"
    :selectedItem="selectedItem"
  />
  <LbrxDiscountPopup
    :active="modalDiscount.active"
    @closePopup="closePopup"
    @confirmDiscount="confirmDiscount"
    :decimalPrecision="posConfig.posConfig.decimalPrecision"
    :posConfig="posConfig"
  />
  <LbrxCouponPopup
    ref="couponRef"
    :active="modalCoupon.active"
    @closePopup="closePopup"
    @confirmCoupon="confirmCoupon"
  />
  <LbrxKeyboardPopup
    :active="modalKeyboard.active"
    @closePopup="closePopup"
    @confirmCoupon="confirmCoupon"
  />
  <LbrxInfoPopup
    :active="modalInfo.active"
    @closePopup="closePopupInfo"
    :remainingQty="remainingQty"
    :type="'qty'"
  />

  <LbrxPrdQuantity
    :active="modalPrdQuantity.active"
    @closeQuantityPopup="closeQuantityPopup"
    @confirmPrdQty="confirmPrdQty"
    :selectedItem="selectedItem"
  />
</template>

<script>
import LbrxPrdQuantity from "@/components/LbrxPrdQuantity.vue";

import LbrxProductItem from "@/components/LbrxProductItem.vue";
import LbrxCart from "@/components/LbrxCart.vue";
import LbrxCartPortrait from "@/components/LbrxCartPortrait.vue";
import LbrxFilterBar from "@/components/LbrxFilterBar.vue";

import LbrxCustomizePopup from "@/components/LbrxCustomizePopup.vue";
import LbrxCustomizeVariantPopup from "@/components/LbrxCustomizeVariantPopup.vue";
import LbrxQuantityPopup from "@/components/LbrxQuantityPopup.vue";
import LbrxDiscountPopup from "@/components/LbrxDiscountPopup.vue";
import LbrxCouponPopup from "@/components/LbrxCouponPopup.vue";
import LbrxKeyboardPopup from "@/components/LbrxKeyboardPopup.vue";
import LbrxInfoPopup from "@/components/LbrxInfoPopup.vue";
import { notificationPlay } from "../../_helpers";
import { notification } from "ant-design-vue";
import {
  productService,
  categoryService,
  cartService,
  cartItemService,
} from "@/_services";
import webSocketService from "@/_services/webSocketService";

export default {
  name: "homeIndex",
  components: {
    LbrxPrdQuantity,
    LbrxProductItem,
    LbrxCart,
    LbrxFilterBar,
    LbrxCustomizePopup,
    LbrxCustomizeVariantPopup,
    LbrxQuantityPopup,
    LbrxDiscountPopup,
    LbrxCouponPopup,
    LbrxKeyboardPopup,
    LbrxInfoPopup,
    LbrxCartPortrait,
  },
  data() {
    return {
      drawer: {
        active: false,
      },
      modal: {
        active: false,
      },
      modalVariant: {
        active: false,
      },
      modalVariant2: {
        active: false,
      },
      modalQuantity: {
        active: false,
      },
      modalDiscount: {
        active: false,
      },
      modalCoupon: {
        active: false,
      },
      modalKeyboard: {
        active: false,
      },
      modalInfo: {
        active: false,
      },
      selectedQty: 1,
      perPage: 10,
      currentPage: 1,
      selectedFilter: {},
      selectedItem: {},
      itemDisplayLcd: {},
      discountAmount: "",
      discountType: "",
      loadingCard: true,
      loadingP: true,
      cartObject: {},
      showLoaderCart: false,
      products: [],
      cart: [],
      categories: [],
      timer: null,
      socketDisplay: null,
      pos: {},
      posConfig: {},
      currency: {},
      currencyCode: "TND",
      barcode: "",
      remainingQty: 0,
      isInputFocused: false,
      selectedCartItem: {},
      includedItems: [],
      notIncludedItems: [],
      includedPreferences: [],
      isPortrait: window.matchMedia("(orientation: portrait)").matches,
      modalPrdQuantity: {
        active: false,
      },
      selectedPrdQty: "",
    };
  },
  created() {
    if (localStorage.getItem("device")) {
      this.pos = JSON.parse(localStorage.getItem("device"));
      if (JSON.parse(localStorage.getItem("device")).config) {
        this.posConfig = JSON.parse(this.pos.config);
      }
    }
    if (localStorage.getItem("currencies")) {
      let currencies = JSON.parse(localStorage.getItem("currencies"));
      this.currency = currencies.find((el) => {
        return el.is_default == 1;
      });
      if (this.currency.currency) {
        this.currencyCode = this.currency.currency.code;
      }
    }
    this.checkCartStatus();
    window.addEventListener("cart-search", (event) => {
      if (event.detail.search.length != 0)
        this.getProductsFilter("name", event.detail.search);
      else this.getProductsFilter("category_id", this.selectedFilter.id);
    });
  },
  mounted() {
    this.getCategories();
    setTimeout(() => {
      this.loadingCard = false;
    }, 1000);
    setTimeout(() => {
      document.getElementById("scanProduct").focus();
    }, 500);
    this.orientationMediaQuery = window.matchMedia("(orientation: portrait)");
    this.orientationMediaQuery.addEventListener(
      "change",
      this.handleOrientationChange
    );
    this.socketDisplay = webSocketService.getSocket();
  },
  beforeUnmount() {
    this.orientationMediaQuery.removeEventListener(
      "change",
      this.handleOrientationChange
    );
  },
  methods: {
    handleOrientationChange(event) {
      this.isPortrait = event.matches;
    },
    toggleCartLoader(state) {
      this.showLoaderCart = state;
    },
    checkCartStatus() {
      if (JSON.parse(localStorage.getItem("cart"))) {
        this.showLoaderCart = true;
        cartService
          .show(JSON.parse(localStorage.getItem("cart")).id)
          .then((res) => {
            this.cartObject = res.data;
            this.cart = res.data.cart_items;
            localStorage.setItem("cart", JSON.stringify(res.data));
          })
          .catch((error) => {
            console.log("error api : ", error);
            // this.showErrorNotification('Error fetching cart items')
          })
          .finally(() => {
            this.showLoaderCart = false;
          });
      }
    },
    getProducts() {
      productService
        .getProducts(1, 100)
        .then((response) => {
          console.log("getting products", response.data);
          this.products = response.data;
        })
        .catch((error) => {
          console.log("error api : get products", error);
        });
    },
    getProductsFilter(key, filter) {
      productService
        .getProductsFilter(
          key,
          filter,
          "&warehouse_id=" + this.posConfig.warehouse_id
        )
        .then((response) => {
          console.log("getting products", response.data);
          this.products = response.data;
        })
        .catch((error) => {
          console.log("error api : get products", error);
        });
    },
    getProductsByCategory(id) {
      this.loadingP = true;
      productService
        .getProductsByCategory(
          id,
          "&warehouse_id=" + this.posConfig.warehouse_id
        )
        .then((response) => {
          console.log("getting products", response.data);
          this.products = response.data;
        })
        .catch((error) => {
          console.log("error api : get products", error);
        })
        .finally(() => {
          this.loadingP = false;
        });
    },
    getCategories() {
      categoryService
        .getAll("?pagination=off")
        .then((response) => {
          console.log("getting products", response.data);
          this.categories = response.data;
          this.selectedFilter = this.categories[0];
          this.getProductsByCategory(this.selectedFilter.id);
        })
        .catch((error) => {
          console.log("error api : get products", error);
        });
    },
    confirmItem(additionalIngrediants = [], additionalPreferences = []) {
      if (this.modalPrdQuantity.active) {
        return;
      }

      this.showLoaderCart = true;
      let payload = {
        cart_id: JSON.parse(localStorage.getItem("cart")).id,
        product_id: this.selectedItem.id,
        quantity: 1,
      };
      if (additionalIngrediants[0]?.id)
        payload.additional_ingredients = JSON.stringify({
          ingredients: additionalIngrediants,
        });
      if (additionalPreferences.length > 0)
        payload.additional_preferences = JSON.stringify({
          preferences: additionalPreferences,
        });
      //
      if (this.selectedQty > 1) {
        payload.quantity = this.selectedQty;

        cartItemService
          .update(
            JSON.parse(localStorage.getItem("cart")).id,
            this.selectedCartItem.id,
            payload
          )
          .then((res) => {
            this.itemDisplayLcd = this.selectedItem;
            this.cartObject = res.data;
            this.cart = res.data.cart_items;
            this.closePopup();
            notificationPlay.beep();
            this.sendMessage(this.cartObject, this.cart);
          })
          .catch((error) => {
            console.log("error api : ", error);
            if (error.message == "Insufficient quantity in stock") {
              this.modalInfo.active = true;
              this.remainingQty = error.remaining_quantity;
            }
          })
          .finally(() => {
            this.showLoaderCart = false;
            this.selectedQty = 1;
          });
      } else if (this.selectedQty == 1 && this.selectedPrdQty == "") {
        // console.log("abbaa");
        cartItemService
          .create(JSON.parse(localStorage.getItem("cart")).id, payload)
          .then((res) => {
            this.itemDisplayLcd = this.selectedItem;
            this.cartObject = res.data;
            this.cart = res.data.cart_items;
            this.closePopup();
            notificationPlay.beep();
            this.sendMessage(this.cartObject, this.cart);
          })
          .catch((error) => {
            console.log("error api : ", error);
            if (error.message == "Insufficient quantity in stock") {
              this.modalInfo.active = true;
              this.remainingQty = error.remaining_quantity;
            }
          })
          .finally(() => {
            this.showLoaderCart = false;
            this.selectedQty = 1;
          });
      }

      //product quantity
      if (this.selectedPrdQty != "") {
        // console.log("aaaaa");
        payload.quantity = this.selectedPrdQty;
        cartItemService
          .create(JSON.parse(localStorage.getItem("cart")).id, payload)
          .then((res) => {
            this.itemDisplayLcd = this.selectedItem;
            this.cartObject = res.data;
            this.cart = res.data.cart_items;
            this.closePopup();
            this.closeQuantityPopup();
            notificationPlay.beep();
            this.sendMessage(this.cartObject, this.cart);
          })
          .catch((error) => {
            console.log("error api : ", error);
            if (error.message == "Insufficient quantity in stock") {
              this.modalInfo.active = true;
              this.remainingQty = error.remaining_quantity;
            }
          })
          .finally(() => {
            this.showLoaderCart = false;
            this.selectedPrdQty = "";
          });
      }
    },
    confirmItemVariant(product = {}) {
      this.showLoaderCart = true;
      let payload = {
        cart_id: JSON.parse(localStorage.getItem("cart")).id,
        product_id: product.product_id,
        product_variant_id: product.id,
        quantity: 1,
      };
      // when updating the quantity of a variant product confirmItem() function will be executed with the payload needing only product_id/quantity
      if (this.selectedQty > 1) {
        payload.quantity = this.selectedQty;
        cartItemService
          .update(
            JSON.parse(localStorage.getItem("cart")).id,
            this.selectedCartItem.id,
            payload
          )
          .then((res) => {
            this.itemDisplayLcd = product;
            this.cartObject = res.data;
            this.cart = res.data.cart_items;
            this.closePopup();
            notificationPlay.beep();
            this.sendMessage();
          })
          .catch((error) => {
            console.log("error api : ", error);
            if (error.message == "Insufficient quantity in stock") {
              this.modalInfo.active = true;
              this.remainingQty = error.remaining_quantity;
            }
          })
          .finally(() => {
            this.showLoaderCart = false;
            this.selectedQty = 1;
          });
      } else {
        cartItemService
          .create(JSON.parse(localStorage.getItem("cart")).id, payload)
          .then((res) => {
            this.itemDisplayLcd = product.id;
            this.cartObject = res.data;
            this.cart = res.data.cart_items;
            this.closePopup();
            notificationPlay.beep();
            this.sendMessage();
            this.$refs.variantRef.reset();
          })
          .catch((error) => {
            console.log("error api : ", error);
            if (error.message == "Insufficient quantity in stock") {
              this.modalInfo.active = true;
              this.remainingQty = error.remaining_quantity;
            }
          })
          .finally(() => {
            this.showLoaderCart = false;
            this.selectedQty = 1;
          });
      }
    },
    closePopup() {
      this.selectedItem = {};
      this.modal.active = false;
      this.modalVariant.active = false;
      this.modalQuantity.active = false;
      this.modalDiscount.active = false;
      this.modalCoupon.active = false;
      this.modalInfo.active = false;
    },
    closeQuantityPopup() {
      this.modalPrdQuantity.active = false;
    },
    closePopupInfo() {
      this.modalInfo.active = false;
    },
    openPopup() {
      this.showLoaderCart = false;

      if (
        this.selectedItem.has_ingredients == 0 &&
        this.selectedItem.preferences.length !== 0
      ) {
        this.modal.active = true;
        this.$refs.ingredRef.assignPreferences(this.selectedItem.preferences);

        return;
      }

      const isItemInCart = this.cart.some(
        (item) => item.product_id === this.selectedItem.id
      );

      if (
        this.selectedItem.has_ingredients == 0 &&
        !(
          this.selectedItem.variants_by_attribute_type &&
          this.selectedItem.variants_by_attribute_type.length != 0
        )
      ) {
        //productQT
        if (!isItemInCart) {
          this.selectProductItem();
        }
        this.confirmItem();
      } else {
        if (this.selectedItem.has_ingredients == 1) {
          // search if an ingredient is optional
          let allIncluded = true;
          let noPreference = true;
          for (const [key, value] of Object.entries(
            this.selectedItem.ingredients
          )) {
            for (const [subKey, subValue] of Object.entries(value)) {
              // check if have preferences
              if (
                subValue.ingredient.preferences &&
                subValue.ingredient.preferences.length != 0
              ) {
                noPreference = false;
                // console.log('fgfgfg , ', subValue.ingredient.preferences)
                this.includedPreferences = [
                  ...this.includedPreferences,
                  ...subValue.ingredient.preferences,
                ];
              }
              if (subValue.included_by_default == 0) {
                const itemProduct = subValue.ingredient;
                allIncluded = false;
                subValue.ingredient.qty = 0;
                this.notIncludedItems.push(itemProduct);
              } else {
                // if ingredient is included by default than add it
                const itemProduct = subValue.ingredient;
                itemProduct.qty = 1;
                this.includedItems.push(itemProduct);
                // delete subValue.ingredient;
              }
            }
          }
          if (allIncluded && noPreference) {
            this.confirmItem();

            if (!isItemInCart) {
              this.selectProductItem();
            }
          } else {
            this.modal.active = true;
            this.$refs.ingredRef.assignDefaultIngr(
              this.includedItems,
              this.notIncludedItems
            );
            this.$refs.ingredRef.assignPreferences(this.includedPreferences);
            this.includedItems = [];
            this.notIncludedItems = [];
            this.includedPreferences = [];
          }
        }
        // display variant or ingredient popup
        if (
          this.selectedItem.variants_by_attribute_type &&
          this.selectedItem.variants_by_attribute_type.length != 0
        ) {
          // this.modalVariant2.active = true;
          this.modalVariant.active = true;
        }
      }
    },
    selectProductItem() {
      const showPopup =
        this.posConfig.posConfig.show_qt_popup == 1 ? true : false;
      if (showPopup) {
        this.modalPrdQuantity.active = true;
      }
    },

    selectItem(item) {
      if (this.showLoaderCart != true) {
        this.showLoaderCart = true;
        if (JSON.parse(localStorage.getItem("cart"))) {
          cartService
            .show(JSON.parse(localStorage.getItem("cart")).id)
            .then((res) => {
              this.selectedItem = item;
              this.openPopup();
            })
            .catch((error) => {
              let payload = {
                user_id: JSON.parse(localStorage.getItem("user")).user.id,
                device_id: JSON.parse(localStorage.getItem("device")).id,
              };
              cartService
                .create(payload)
                .then((res) => {
                  localStorage.setItem("cart", JSON.stringify(res.data));
                  this.cartObject = res.data;
                  this.cart = [];
                  this.selectedItem = item;
                  this.openPopup();
                })
                .catch((error) => {
                  console.log("error api : ", error);
                });
            });
        } else {
          let payload = {
            user_id: JSON.parse(localStorage.getItem("user")).user.id,
            device_id: JSON.parse(localStorage.getItem("device")).id,
          };
          cartService
            .create(payload)
            .then((res) => {
              localStorage.setItem("cart", JSON.stringify(res.data));
              this.cartObject = res.data;
              this.selectedItem = item;
              this.openPopup();
            })
            .catch((error) => {
              console.log("error api : ", error);
            });
        }
      }
    },
    createNewCart() {
      this.showLoaderCart = true;
      let payload = {
        user_id: JSON.parse(localStorage.getItem("user")).user.id,
        device_id: JSON.parse(localStorage.getItem("device")).id,
      };
      cartService
        .create(payload)
        .then((res) => {
          localStorage.setItem("cart", JSON.stringify(res.data));
          this.cartObject = res.data;
          this.cart = [];
          this.showNotification(this.$t("Pos.HoldSuccess"));
        })
        .catch((error) => {
          console.log("error api : ", error);
          this.showErrorNotification(this.$t("Pos.ErrorTry"));
        })
        .finally(() => {
          this.showLoaderCart = false;
        });
    },
    selectFilter(item) {
      this.selectedFilter = item;
      this.getProductsByCategory(this.selectedFilter.id);
    },
    // startCountdown(param, item = {}) {
    //     if (param) {
    //         this.timer = setTimeout(() => {
    //             this.selectedItem = item;
    //             this.modalQuantity.active = true;
    //         }, 3000);
    //     } else {
    //         clearTimeout(this.timer);
    //         console.log("Countdown disabled.");
    //     }
    // },
    showQuantityPopup(item, itemCart) {
      this.selectedCartItem = itemCart;
      this.selectedItem = item;
      this.modalQuantity.active = true;
    },
    removeQuantity(product) {
      this.showLoaderCart = true;
      cartItemService
        .delete(this.cartObject.id, product.id)
        .then((res) => {
          this.cartObject = res.data;
          this.cart = res.data.cart_items;
          notificationPlay.delete();

          console.log("cart", this.cart);

          if (this.cart.length != 0) {
            this.dispatchEvent("showCart", this.cartObject, this.cart);
          } else {
            this.dispatchEvent("showAd", {}, []);
          }
        })
        .catch((error) => {
          console.log("error api : ", error);
        })
        .finally(() => {
          this.showLoaderCart = false;
        });
    },
    addQuantity(product) {
      const index = this.findCartItem(product);
      this.cart[index].qty += 1;
      notificationPlay.beep();
    },
    emptyCart() {
      if (localStorage.getItem("cart")) {
        cartService
          .delete(JSON.parse(localStorage.getItem("cart")).id)
          .then((res) => {
            this.cartObject = {};
            this.cart = [];
            notificationPlay.delete();
          })
          .catch((error) => {
            console.log("error api : ", error);
          })
          .finally(() => {
            localStorage.removeItem("cart");
            localStorage.removeItem("customer");
            localStorage.removeItem("table");
          });
      } else {
        this.cartObject = {};
        this.cart = [];
        localStorage.removeItem("customer");
        localStorage.removeItem("table");
      }
    },
    deleteCartVar() {
      this.cartObject = {};
      this.cart = [];
    },
    findCartIndex(product) {
      return this.cart.findIndex((p) => p.productId === product.id);
    },
    findCartItem(product) {
      return this.cart.findIndex((p) => p.productId === product.productId);
    },
    showReceipt() {
      this.modal.active = true;
    },
    hideReceipt() {
      this.modal.active = false;
    },
    filteredProducts() {
      const rg = this.keyword ? new RegExp(this.keyword, "gi") : null;
      this.products.filter((p) => !rg || p.name.match(rg));
    },
    showErrorNotification(message) {
      notification.error({
        message: message,
        duration: 3, // 3s
        top: "80px",
        style: {
          backgroundColor: "#FFBFC98A",
          color: "white",
        },
      });
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: "80px",
        style: {
          color: "white",
        },
      });
    },
    async confirmQty(qty) {
      this.selectedQty = parseFloat(qty);
      // this.showLoaderCart = true;
      await this.selectItem(this.selectedItem);
      this.closePopup();
    },
    async confirmPrdQty(qt) {
      this.selectedPrdQty = parseFloat(qt);
      await this.closeQuantityPopup();
      await this.confirmItem();
    },
    showDiscount() {
      this.modalDiscount.active = true;
    },
    showCoupon() {
      this.modalCoupon.active = true;
      this.$refs.couponRef.test();
    },
    confirmDiscount(amount, type) {
      this.closePopup();
      let payload = {
        user_id: JSON.parse(localStorage.getItem("user")).user.id,
        discount_type: type,
        discount_value:
          type == "amount"
            ? amount.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            : amount,
      };
      cartService
        .update(JSON.parse(localStorage.getItem("cart")).id, payload)
        .then((res) => {
          localStorage.setItem("cart", JSON.stringify(res.data));
          this.cartObject = res.data;
        })
        .catch((error) => {
          console.log("error api : ", error);
        })
        .finally(() => {
          //
        });
    },
    confirmCoupon(code) {
      this.closePopup();
      let payload = {
        user_id: JSON.parse(localStorage.getItem("user")).user.id,
        coupon_code: code,
      };
      cartService
        .update(JSON.parse(localStorage.getItem("cart")).id, payload)
        .then((res) => {
          if (res.data) {
            localStorage.setItem("cart", JSON.stringify(res.data));
            this.cartObject = res.data;
            this.showNotification("Coupon successful");
          } else {
            this.showErrorNotification(res.message);
          }
        })
        .catch((error) => {
          console.log("error api : ", error);
        })
        .finally(() => {
          //
        });
    },
    deleteDiscount(type = "discount") {
      this.closePopup();
      let payload = {
        user_id: JSON.parse(localStorage.getItem("user")).user.id,
      };
      if (type == "discount") {
        payload.discount_type = "percentage";
        payload.discount_value = 0;
      }
      cartService
        .update(JSON.parse(localStorage.getItem("cart")).id, payload)
        .then((res) => {
          if (res.data) {
            localStorage.setItem("cart", JSON.stringify(res.data));
            this.cartObject = res.data;
            this.showNotification(this.$t("Pos.CouponDeleted"));
          } else {
            this.showErrorNotification(res.message);
          }
        })
        .catch((error) => {
          console.log("error api : ", error);
        })
        .finally(() => {
          //
        });
    },
    confirmBarcode() {
      console.log("confirm bar code")
      if (this.barcode.length != 0) {
        setTimeout(() => {
          productService
            .getProductsFilter(
              "barcode",
              this.barcode,
              "&warehouse_id=" + this.posConfig.warehouse_id
            )
            .then((res) => {
              console.log("confirm bar code", res.data[0])

              this.selectItem(res.data[0]);
            })
            .catch((error) => {
              console.log("error api : get products", error);
            })
            .finally(() => {
              this.barcode = "";
            });
        }, 150);
      }
    },
    enableScanmode() {
      document.getElementById("scanProduct").focus();
    },
    connectSocket() {
      //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
      const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });';

      this.socketDisplay = new WebSocket("ws://localhost:8765");
      // this.ticketPrinted = true;

      this.socketDisplay.onopen = (event) => {
        this.socketDisplay.send(token); // send the token as soon as the connection is established
      };

      this.socketDisplay.onmessage = (event) => {
        if (event.data == "Authentication successful") {
          // this.sendMessage();
        }
      };

      this.socketDisplay.onerror = (error) => {
        console.error(`WebSocket Error: ${error}`);
      };

      this.socketDisplay.onclose = (event) => {
        console.log(`WebSocket connection closed: ${event}`);
      };
    },
    sendMessage(cart, cart_items) {
      this.dispatchEvent("showCart", cart, cart_items);
      if (this.socketDisplay.readyState === WebSocket.OPEN) {
        const message = {
          action: "display_item",
          data: this.itemDisplayLcd,
        };
        this.socketDisplay.send(JSON.stringify(message));
        this.loadingPrinting = false;
        // this.ticketPrinted = true;
      } else {
        console.error("Socket is not open. Can't send message");
      }
    },
    dispatchEvent(event, sale, saleDetails) {
      var channel = new BroadcastChannel("cart-info-channel");
      let payload = {
        event: event,
        sale: sale,
        saleDetails: saleDetails,
      };
      channel.postMessage(JSON.stringify(payload));
      channel.close();
    },
  },
};
</script>

<style scoped></style>